<template>
    <div class="NormalImg1_inner" :style="NormalImg1_inner_height">
      <img :src="this.imgUrl" alt="" >
    </div>
  </template>
  
  <script>
  export default {
    props:['imgUrl','imgHeight'],
    computed:{
      NormalImg1_inner_height(){
        return "height:"+this.imgHeight+"em;"
      }
    }
  }
  </script>
  
  <style>
  .NormalImg1_inner{
    margin: auto;
    margin-bottom: 1em;
    padding-left: 20%;
    padding-right: 20%;
    width: 100%;
    height: 30em;
  }
  .NormalImg1_inner img{
    /* background-color: red; */
    width: 60%;
    height: 100%;
  }
  </style>