<template>
  <div class="PEC_jumper">
    正在跳转......
  </div>
</template>

<script>
export default {
    props:["href"],
    created(){
        this.$router.push(this.href)
    },
    methods:{
    }
}
</script>

<style>

</style>