<template>
    <div class="PEPageCreator">
        <div class="pageCreator_creator">
            <div class="indexTitle">手机端自定义页面管理</div>
            <div id="pageCreator_createBox">
                <h3>插入新的页签</h3>
                <div class="pageCreator_createBox_item">
                    <el-input v-model="newPage.title" placeholder="页面名"></el-input>
                </div>
                <div class="pageCreator_createBox_item">
                    优先级
                    <el-input-number v-model="newPage.priority" :min="1" :max="99999"></el-input-number>
                </div>
                <div class="pageCreator_createBox_item">
                    <el-switch v-model="newPage.hidden" active-color="#13ce66" inactive-color="#a3a3a3" active-text="隐藏"
                        inactive-text="显示">
                    </el-switch>
                </div>
                <div class="pageCreator_createBox_item">
                    <el-switch v-model="newPage.isS2" active-color="#13ce66" inactive-color="#a3a3a3" active-text="二级页面"
                        inactive-text="一级页面">
                    </el-switch>
                </div>
                <div class="pageCreator_createBox_item" v-if="newPage.isS2">
                    选择父页面
                    <el-select v-model="newPage.parent" placeholder="请选择父页面">
                        <el-option v-for="item in myPages" :key="item.id" :label="item.title" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="pageCreator_createBox_item">
                    <el-button type="danger" @click="add">插入</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Bus from '@/utils/EventBus';
import axios from 'axios';
export default {
    data() {
        return {
            myPages: "",
            newPage: {
                title: "",
                parent: "",
                priority: "",
                hidden: false,
                child_string: "",
                isS2: false,
            },
        }
    },
    mounted() {
        this.getPages();
    },
    methods: {
        add() {
            if (this.newPage.isS2) this.addS2()
            else this.addS1()
            this.newPage = {
                title: "",
                parent: "",
                priority: "",
                hidden: false,
                child_string: "",
                isS2: false,
            }
        },
        addS1() {
            axios({
                method: 'post',
                url: this.$hdAddress + "/insertPEPageS1",
                data: this.newPage,
            }).then(response => {
                this.$notify({
                    title: '成功',
                    message: response.data,
                    type: 'success'
                });
                this.getPages()
                Bus.$emit('PEPageCreating', '')
            }).catch(error => {
                this.$notify.error({
                    title: '错误',
                    message: '添加失败\n' + error
                });
                console.error('Error:', error.response ? error.response.data : error);
            });
        },
        addS2() {
            axios({
                method: 'post',
                url: this.$hdAddress + "/insertPEPageS2",
                data: this.newPage,
            }).then(response => {
                this.$notify({
                    title: '成功',
                    message: response.data,
                    type: 'success'
                });
                this.getPages()
                Bus.$emit('PEPageCreating', '')
            }).catch(error => {
                this.$notify.error({
                    title: '错误',
                    message: '添加失败\n' + error
                });
                console.error('Error:', error.response ? error.response.data : error);
            });
        },
        getPages() {
            axios({
                method: 'get',
                url: this.$hdAddress + "/selectAllPEPageS1",
            })
                .then(response => {
                    this.myPages = response.data
                }).catch(error => {
                    this.$notify.error({
                        title: '错误',
                        message: '获取页面失败'
                    });
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
    },
}
</script>

<style></style>