<template>
    <el-carousel :interval="4000" type="card">
        <el-carousel-item v-for="item in MyIndex_box2_inner_items" :key="item.name">
            <div class="MyIndex_box2_inner_items">
                <div class="MyIndex_box2_inner_items_img">
                    <img :src="item.image_sl_pc" alt="" class="MyIndex_box2_inner_img">
                </div>
            <div class="MyIndex_box2_inner_items_msg">
                <span class="medium">{{ item.name }}</span>
                <br>
                <hr>
                <span class="base">{{ item.biaoqian }}</span>
                <br>
                <span class="base">{{ item.content }}</span>
                <hr>
            </div>
            </div>
        </el-carousel-item>
    </el-carousel>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            MyIndex_box2_inner_items: [],
        };
    },
    mounted(){
        this.getMsg()
    },
    methods: {
        getMsg(){
            axios({
                method: 'get',
                url: this.$hdAddress+'/selectMyIndexGameShower1',
            }).then(res => {
                // console.log(res)
                this.MyIndex_box2_inner_items=res.data
            })
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        }
    }
}
</script>


<style>
/* 设置轮播图容器的高度 */
.el-carousel .el-carousel__container {
    background-color: rgba(255, 255, 255, 0.3); /* 背景色白色，30%透明度 */
}

/* 轮播图项目的基本样式 */
.el-carousel__item {
    display: block;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    box-sizing: border-box;
    background-color: white;
}

/* 图片容器样式 */
.MyIndex_box2_inner_items_img {
    width: 100%;
    height: 25em; /* 图片区域高度调整为60% */
    display: block;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/* 图片样式 */
.MyIndex_box2_inner_img {
    width: 80%; /* 图片宽度自适应 */
    margin-left: 10%;
    height: 100%; /* 图片高度占满图片容器 */
    max-width: 100%; /* 图片最大宽度不超过容器宽度 */
}

.MyIndex_box2_inner_items_msg{
    width: 80%;
    margin: auto;
}













.medium {
    font-size: 1.75em; /* 大标题字体大小 */
    font-weight: bold; /* 字体加粗 */
}

.small {
    font-size: 1.25em; /* 子标题字体大小 */
    color: #666; /* 字体颜色稍淡 */
}
.base {
    font-size: 1em; /* 正文字体大小 */
    color: #666; /* 字体颜色与子标题一致 */
    display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
    -webkit-box-orient: vertical; /* 设置伸缩盒子的子元素排列方式为垂直 */
    -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
    overflow: hidden; /* 溢出内容隐藏 */
    text-overflow: ellipsis; /* 当对象内文本内容超出指定高度时，显示省略号来代表隐藏的文本 */
}
</style>