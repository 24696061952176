<template>
  <div id="GameDetail">
    <div class="PCGameDetail" v-if="!_isMobile">
      <TopImg2
        id="GameDetail_inner_TopImg"
        :imgUrl="msgs.image_sl_Pc"
      ></TopImg2>
      <div id="GameDetail_inner">
        <GameDetail_box1
          class="GameDetail_inner_inner"
          :msgs="this.msgs"
        ></GameDetail_box1>
        <GameDetail_box2
          class="GameDetail_inner_inner"
          :msgs="this.msgs"
        ></GameDetail_box2>
      </div>
    </div>
    <div class="PEGameDetail" v-if="_isMobile">
      <!-- <PEHeader></PEHeader> -->
      <div class="PEGameDetail_img">
        <img :src="msgs.image_sl_Pc" alt="" />
      </div>
      <div class="PEGameDetail_contianer">
        <div class="PEGameDetail_contianer_t1">
          <div class="PEGameDetail_contianer_t1_left">
            <img :src="msgs.icon" alt="" />
          </div>
          <div class="PEGameDetail_contianer_t1_right">
            <div class="PEGameDetail_contianer_t1_right_name">
              名称：{{ msgs.name }}
            </div>
            <div class="PEGameDetail_contianer_t1_right_biaoqian">
              标签：{{ msgs.biaoqian }}
            </div>
            <div class="PEGameDetail_contianer_t1_right_shangjia">
              上架时间：{{ msgs.shangjia }}
            </div>
          </div>
        </div>
        <div class="PEGameDetail_contianer_t2">
          <span style="font-size: 0.9em">游戏简介:</span>
          <span style="font-size: 0.8em; padding: 0.5em">{{
            msgs.content
          }}</span>
        </div>
        <div class="PEGameDetail_contianer_t3">
          <span>游戏截图： </span>
          <PEC_lunBo1
            banUseLocal="true"
            :imgsUrls="msgs.imgsList"
            height="15"
          ></PEC_lunBo1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/utils/EventBus";
import axios from "axios";

export default {
  data() {
    return {
      msgs: {},
    };
  },
  mounted() {
    this.getMsgs();
  },
  methods: {
    getMsgs() {
      axios({
        method: "get",
        params: {
          name: this.name,
        },
        url: this.$hdAddress + "/selectGameDetailByName",
      }).then((res) => {
        this.msgs = res.data[0];
        Bus.$emit("GameDetailSelected", this.msgs);

        document.title =
          this.msgs.name + 
          " " +
          this.msgs.biaoqian +
          " 游戏详情 游戏加盟代理 手游项目投资 风林手游";
      });
    },
  },
  computed: {
    _isMobile() {
      //判断是否为手机端
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    name() {
      // 使用正则表达式查找查询参数，如果匹配成功，解析查询参数并返回 'name' 参数的值
      const regex = /(?:\?|&)name=([^&]+)/; // 修改正则表达式以正确匹配 name 参数
      const match = window.location.href.match(regex);
      if (match) {
        return decodeURIComponent(match[1]); // 解码 URI 组件以避免编码问题
      }
      return null; // 如果没有找到 name 参数，返回 null 或者其他默认值
    },
  },
};
</script>

<style>
#GameDetail_inner {
  position: relative;
  z-index: 2;
  margin-top: -50em;
  background-color: #fffffff3;
  border-radius: 1em;
  width: 80%;
  left: 10%;
  display: block;
  height: auto;
  min-height: 60em;
}

#GameDetail_inner_inner {
  float: top;
  display: block;
}

.PEGameDetail {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  display: block;
}

.PEGameDetail_img {
  z-index: -2;
  width: 100%;
}

.PEGameDetail_img img {
  position: relative;
  z-index: 1;
  margin-top: 3em;
  width: 100%;
}

.PEGameDetail_contianer {
  width: 100%;
  position: relative;
  float: left;
  z-index: 2;
  margin-top: -2em;
  min-height: 10em;
  background-color: #fff;
}

.PEGameDetail_contianer_t1 {
  padding-top: 1em;
  margin: auto;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.PEGameDetail_contianer_t1_left {
  float: left;
  width: 30%;
  height: 7em;
}

.PEGameDetail_contianer_t1_left img {
  margin-top: 1em;
  margin-left: 2em;
  width: 5em; /* 图标宽度 */
  height: 5em; /* 图标高度 */
}

.PEGameDetail_contianer_t1_right {
  margin-top: 0.8em;
  margin-left: 2em;
  float: left;
  width: 60%;
  height: 7em;
}

.PEGameDetail_contianer_t1_right_name,
.PEGameDetail_contianer_t1_right_biaoqian,
.PEGameDetail_contianer_t1_right_shangjia {
  margin: 0.5em 0;
  font-size: 0.9em;
}

.PEGameDetail_contianer_t1_right_name {
  font-size: 1em;
}
.PEGameDetail_contianer_t1_right_biaoqian {
  color: #6a6a6a;
  font-size: 0.9em;
}
.PEGameDetail_contianer_t1_right_shangjia {
  color: #b0b0b0;
  font-size: 0.8em;
}

.PEGameDetail_contianer_t2 {
  margin: auto;
  width: 90%;
  font-size: 1.1em; /* 字体大小 */
  line-height: 1.5em; /* 行高 */
  margin-bottom: 1em; /* 与下文的间距 */
}

.PEGameDetail_contianer_t3 {
  margin: auto;
  width: 90%;
  margin-top: 1em; /* 与上文的间距 */
}

.PEC_lunBo1 {
  width: 100%; /* 轮播图宽度 */
  height: auto; /* 高度自适应 */
}

/* 媒体查询，适配不同屏幕尺寸 */
@media (min-width: 768px) {
  .PEGameDetail_contianer_t1 {
    justify-content: flex-start; /* 大屏幕下左侧图标和右侧内容并排显示 */
  }

  .PEGameDetail_contianer_t1_left img {
    width: 4em; /* 大图标尺寸 */
    height: 4em;
  }

  .PEGameDetail_contianer_t1_right_name,
  .PEGameDetail_contianer_t1_right_biaoqian,
  .PEGameDetail_contianer_t1_right_shangjia {
    font-size: 1.5em; /* 大字体大小 */
  }
}
</style>