<!-- 三分之一屏大图 -->
<template>
    <div class="TopImg_inner">
      <img :src="this.imgUrl" alt="">
    </div>
  </template>
  
  <script>
  export default {
    props:['imgUrl']
  }
  </script>
  
  <style>
  .TopImg_inner{
    width: 100%;
    height: 30em;
  }
  .TopImg_inner img{
    width: 100%;
    height: 100%;
  }
  
  </style>