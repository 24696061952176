<template>
    <footer id="PageFooter" :style="bgc">
        <div id="PageFooterLeft">

            <div class="PageFooterContent">
                友情链接: <a href="https://pro.14294.com/" target="_blank">风林官网</a>
            </div>
            <div class="PageFooterContent">
                公司地址: 四川省自贡市富顺县釜江大道西段775号三楼 风林网络科技有限公司
            </div>
            <div class="PageFooterContent">
                统一社会信用代码: 91510322MACAY3976N
            </div>
            <div class="PageFooterContent">
                组织机构代码: MACAY397-6
            </div>
        </div>
        <div id="PageFooterRight">

            <div class="PageFooterContent">
                <span>&nbsp;&nbsp;&nbsp;联系方式：</span>

                <div class="PageFooterContentItem">
                    <a href="#"><img class="contact-icon" src="../../public/images/QQ.jpg" alt="QQ"></a>
                    <span>QQ</span>
                </div>
                <div class="PageFooterContentItem">
                    <a href="#"><img class="contact-icon" src="../../public/images/微信.jpg" alt="微信"></a>
                    <span>微信</span>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    props:["bgc"],
}
</script>

<style>
#PageFooter {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    padding: 20px 0;
    color: white;
    width: 100%;
    margin-top: 5em;
    text-align: center; /* 确保所有内容在页脚内居中对齐 */
}

#PageFooterLeft,
#PageFooterRight {
    display: flex;
    flex-direction: column; /* 将内容垂直排列 */
    align-items: flex-start; /* 子元素靠左对齐 */
    padding: 0 20px;
}

.PageFooterContent {
    margin: 5px 0;
    font-size: 14px;
}

.PageFooterContent span {
    /* 移除auto，因为我们希望内容在页脚内居中对齐 */
}

.PageFooterContent a {
    margin: 0 5px;
    color: white;
    text-decoration: none;
}

.PageFooterContent a:hover {
    text-decoration: underline;
}

.PageFooterContentItem {
    display: inline-block;
    margin: 10px 5px; /* 调整间距 */
    text-align: center;
}

.contact-icon {
    width: 5em;
    height: 5em;
    margin-bottom: 5px;
}

span {
    display: block;
}
</style>