<template>
  <div class="NewsAndInformation">
    
    <TopImg v-if="!isPE" :imgUrl="FImgUrl1[0]"></TopImg>
    <PEC_img2 v-else :imgUrl="FImgUrl1[0]" height="8"></PEC_img2>


    <div class="indexTitle">新闻资讯</div>

    <!-- 新闻内容 -->
    <div v-if="myNews.size > 0">还没有内容哦</div>
    <div v-else>
      <div
        class="NewsAndInformation_item"
        v-for="item in myNews"
        :key="item.id"
      >
        <div
          class="NewsAndInformation_item_box"
          href="/NewsDetail"
          @click="jump('/NewsDetail?id=' + item.id + '&self=' + item.self)"
        >
          <div class="NewsAndInformation_item_title">
            {{ item.title }}
          </div>
          <div class="NewsAndInformation_item_description">
            {{ item.description }}
          </div>
        </div>
        <div class="NewsAndInformation_item_update_time">
          {{ item.update_time }}
        </div>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination
      :page-size="pageSize"
      :current-page="realPageNumber"
      layout="prev, pager, next"
      :total="newsAccount"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import axios from "axios";
export default {
  computed: {
    isPE() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      //如果是手机端访问，则看作是手机端
      if (flag) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      FImgUrl1: [],
      newsAccount: 0,
      myNews: [],
      realPageNumber: 1,
      pageSize: 8,
    };
  },
  mounted() {
    this.FImgUrl1 = this.$getImg("新闻资讯大图");
    this.init();
  },
  methods: {
    jump(url) {
      this.$router.push({ path: url }); // 使用 replace 避免后退到 MyInf
      this.$router.go(0);
    },
    init() {
      axios({
        method: "get",
        url: this.$hdAddress + "/selectNewsAccount",
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.newsAccount = res.data;
        this.getNews();
      });
    },
    getNews() {
      //获取新闻表
      axios({
        method: "post",
        url: this.$hdAddress + "/selectSimpleNewsByPage",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          size: this.pageSize,
          pageNumber: (this.realPageNumber - 1) * this.pageSize,
        },
      })
        .then((res) => {
          console.log(res.data);

          this.myNews = res.data;
        })
        .catch((error) => {
          this.$notify.error({
            title: "错误",
            message: "获取新闻请求失败",
          });
          console.error("Error:", error.response ? error.response.data : error);
        });
    },
    handleCurrentChange(newPage) {
      this.realPageNumber = newPage;
      this.getNews();
    },
  },
};
</script>

<style scoped>
.NewsAndInformation .PEC_img2{
    margin-top: 3em;
}
.NewsAndInformation {
  padding: 0;
  max-width: 100%;
  margin: 0;
}

/* 新闻项容器 */
.NewsAndInformation_item {
  display: block;
  margin-bottom: 1em;
  padding: 3em 5em; /* 上下内边距 */
  border-bottom: 1px solid #eee; /* 分隔线 */
  transition: all 1s;
}
.NewsAndInformation_item:hover {
  background-color: #efefef;
}

/* 新闻标题 */
.NewsAndInformation_item .NewsAndInformation_item_title {
  font-size: 1.5em; /* 使用em作为字体大小的相对单位 */
  color: #000000;
  margin-bottom: 0.5em;
  -webkit-line-clamp: 2; /* 限制为2行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}

/* 新闻描述 */
.NewsAndInformation_item .NewsAndInformation_item_description {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 0.5em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* 限制为3行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}

/* 更新时间 */
.NewsAndInformation_item .NewsAndInformation_item_update_time {
  font-size: 0.8em;
  color: #999;
}

/* 新闻链接样式 */
.NewsAndInformation_item .NewsAndInformation_item_box {
  display: block;
  text-decoration: none;
  cursor: pointer;
}

/* 分页样式 */
.el-pagination {
  margin-top: 1em;
  text-align: center;
}

.el-pagination button {
  padding: 0.5em 1em;
  margin: 0 0.2em;
}

.el-pagination .btn-prev,
.el-pagination .btn-next {
  padding: 0.5em 1em;
}

/* 适配小屏幕设备 */
@media (max-width: 768px) {
    .NewsAndInformation_item{
        padding: 2em;
    }
  .NewsAndInformation .indexTitle {
    font-size: 1em;
  }

  .NewsAndInformation_item .NewsAndInformation_item_title {
    font-size: 0.9em;
  }

  .NewsAndInformation_item .NewsAndInformation_item_description,
  .NewsAndInformation_item .NewsAndInformation_item_update_time {
    font-size: 0.8em;
  }
}
</style>