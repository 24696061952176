<template>
    <div class="ColleborationChanger">
        <div class="indexTitle">收集表管理</div>

        <div class="pageCreator_createBox_item">
            <el-button type="danger" @click="getCollaborationFormsCount(); getCollaborationForms()">刷新</el-button>
        </div>


        <el-table :data="collaborations" style="width: 100%">
            <el-table-column prop="name" label="名称" width="150">
                <template slot-scope="scope">
                    {{ scope.row.name }}
                </template>
            </el-table-column>
            <el-table-column prop="phone" label="电话" width="150">
                <template slot-scope="scope">
                    {{ scope.row.phone }}
                </template>
            </el-table-column>
            <el-table-column prop="type" label="客户种类" width="150">
                <template slot-scope="scope">
                    {{ scope.row.isCompany ? "游戏开发厂商" : scope.row.type }}
                </template>
            </el-table-column>
            <el-table-column prop="services" label="服务内容" width="200">
                <template slot-scope="scope">
                    {{ scope.row.isCompany ? "公司产品：" + scope.row.notes : scope.row.services }}
                </template>
            </el-table-column>
            <el-table-column prop="update_time" label="用户申请提交时间" width="200">
                <template slot-scope="scope">
                    {{ scope.row.update_time }}
                </template>
            </el-table-column>
            <el-table-column prop="instruction" label="备注（给管理员自己看的）" width="320">
                <template slot-scope="scope">
                    <textarea style="resize: vertical;width: 100%;" v-model="scope.row.instruction"></textarea>
                </template>
            </el-table-column>
            <el-table-column prop="replyed" label="客户状态" width="90">
                <template slot-scope="scope">
                    <div v-if="scope.row.replyed">
                        <el-tag type="success">已处理</el-tag>
                    </div>
                    <div v-else>
                        <el-tag type="danger">未处理</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                    <el-button @click="change(scope.row)" type="text" size="small">确认修改备注</el-button>
                    <el-button @click="scope.row.replyed = 1; change(scope.row)" type="text" size="small"
                        v-if="!scope.row.replyed">标记为已处理</el-button>
                    <el-button @click="scope.row.replyed = 0; change(scope.row)" type="text" size="small"
                        v-else>标记为未处理</el-button>
                    <el-button @click="del(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>



        <!-- 分页 -->
        <el-pagination :page-size="pageSize" :current-page="realPageNumber" layout="prev, pager, next"
            :total="collaborationFormsCount" @current-change="handleCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    mounted() {
        this.getCollaborationFormsCount()
        this.getCollaborationForms()
    },
    data() {
        return {
            collaborationFormsCount: 0,
            realPageNumber: 1,
            pageSize: 5,
            collaborations: [],
        }
    },
    methods: {
        change(form) {
            let token = localStorage.getItem("token")
            axios({
                method: 'post',
                url: this.$hdAddress + "/m/updateCollaborationForm",
                headers: {
                    token: token
                },
                data: form,
            }).then(() => {
                this.$notify({
                    title: '成功',
                    message: '状态修改成功',
                    type: 'success'
                });
                this.getCollaborationForms()
            }).catch(() => {
                this.$notify.error({
                    title: '错误',
                    message: '执行失败'
                });
            })
        },
        del(form) {
            axios({
                method: 'post',
                url: this.$hdAddress + "/m/deleteCollaborationFormById",
                params: {
                    id: form.id
                },
                headers: {
                    token: localStorage.getItem("token")
                },
            }).then(() => {
                this.$notify({
                    title: '成功',
                    message: '状态修改成功',
                    type: 'success'
                });
                this.getCollaborationFormsCount()
                this.getCollaborationForms()
            }).catch(() => {
                this.$notify.error({
                    title: '错误',
                    message: '执行失败'
                });
            })
        },
        handleCurrentChange(newPage) {
            this.realPageNumber = newPage;
            this.getCollaborationForms();
        },
        getCollaborationFormsCount() {
            axios({
                method: 'get',
                url: this.$hdAddress + "/m/selectCollaborationFormsCount",
                headers: {
                    token: localStorage.getItem("token")
                },
            })
                .then(response => {
                    this.collaborationFormsCount = response.data
                })
                .catch(error => {
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
        getCollaborationForms() {
            axios({
                method: 'post',
                url: this.$hdAddress + "/m/selectCollaborationFormByCollaborationFormListConfiger",
                data: {
                    "size": this.pageSize,
                    "pageNumber": (this.realPageNumber - 1) * this.pageSize
                },
                headers: {
                    token: localStorage.getItem("token")
                },
            })
                .then(response => {
                    this.collaborations = response.data
                })
                .catch(error => {
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
    }
}
</script>

<style></style>