<template>
  <div class="ImgChanger">
    <div class="indexTitle">图片管理</div>
    <div class="indexTitle_belong">
      选择图片所属页面
      <el-select v-model="choicedBelong" placeholder="请选择" @change="choicedBelongChanged">
        <el-option v-for="item in allBelongs" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </div>

    <div class="indexTitle_manager">


      <el-table :data="showImgs" style="width: 100%">
        <el-table-column prop="id" label="id" width="60">
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column prop="img_type" label="图片名" width="260">
          <template slot-scope="scope">
            {{ scope.row.img_type }}
          </template>
        </el-table-column>
        <el-table-column prop="url" label="图片" width="300">
          <template slot-scope="scope">
            <img style="width: 100%;" :src="$hdAddress + scope.row.url" alt="">
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            替换图片
            <input class="NormalBtn" type="file" @change="handleFileChange">
            <button @click="uploadImg(scope.row.id)">确定上传</button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      file: null,
      allBelongs: [],
      choicedBelong: "",
      showImgs: [],
    }
  },
  mounted() {
    this.getBelongs()
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0]; // 存储文件对象
    },
    uploadImg(id) {


      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('id', id || '');



      this.$notify.info({
        title: 'Loading',
        message: '正在上传'
      });
      axios({
        method: 'post',
        url: this.$hdAddress + "/m/updateImgById",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token")
        }
      })
        .then(response => {
          console.log('Response:', response.data);

          this.$notify({
            title: '成功',
            message: '更改成功',
            type: 'success'
          });

          // this.$router.go(0);
          this.getImgs()
        }).catch(error => {

          this.$notify.error({
            title: '错误',
            message: '执行失败'
          });
          console.error('Error:', error.response ? error.response.data : error);
        });

    },
    choicedBelongChanged(x) {
      this.choicedBelong = x
      this.getImgs()
    },
    getImgs() {
      axios({
        method: 'post',
        url: this.$hdAddress + "/selectImgByBelong",
        params: {
          belong: this.choicedBelong
        },
        headers: {
          token: localStorage.getItem("token")
        },
      })
        .then(response => {
          this.showImgs = response.data
        })
        .catch(error => {
          console.error('Error:', error.response ? error.response.data : error);
        });
    },
    getBelongs() {
      axios({
        method: 'get',
        url: this.$hdAddress + "/selectBelongs",
      })
        .then(response => {
          this.allBelongs = response.data
        })
        .catch(error => {
          console.error('Error:', error.response ? error.response.data : error);
        });
    },
  }
}
</script>

<style>
.ImgChanger {
  margin: auto;
  width: 55%;
}
</style>