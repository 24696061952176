<template>
    <div class="NewsChanger">
        <div class="indexTitle">新闻管理</div>
        <div class="NewsChanger_inner" style="min-height: 600px;">
            <el-table class="NewsChanger_inner_inner" :data="myNews" style="width: 100%">
                <el-table-column prop="title" label="标题" width="220">
                    <template slot-scope="scope">
                        <el-input placeholder="标题" v-model="scope.row.title" clearable>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="description" label="简介" width="320">
                    <template slot-scope="scope">
                        <el-input placeholder="简介" v-model="scope.row.description" clearable>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click="change(scope.row)" type="text" size="small">确认修改</el-button>
                        <el-button @click="getNewContent(scope.row)" type="text" size="small">修改新闻内容</el-button>
                        <el-button @click="del(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="update_time" label="更新时间" width="220">
                    <template slot-scope="scope">
                        {{ scope.row.update_time }}
                    </template>
                </el-table-column>
            </el-table>


            <!-- 分页 -->
            <el-pagination :page-size="pageSize" :current-page="realPageNumber" layout="prev, pager, next"
                :total="newsAccount" @current-change="handleCurrentChange">
            </el-pagination>

            <!-- 对话框 -->
            <el-dialog title="修改内容" :visible.sync="dialogVisible" width="60%">
                <div class="NewsChanger_inner_dialog">
                    标题<el-input placeholder="标题" v-model="nowNew.title" clearable>
                    </el-input>
                </div>
                <div class="NewsChanger_inner_dialog">
                    简介<el-input placeholder="简介" v-model="nowNew.description" clearable>
                    </el-input>
                </div>
                <div class="NewsChanger_inner_dialog">
                    内容：
                    <div id="editor"></div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="dialogVisible = false; changeWithEditor()">确 定</el-button>
                </span>
            </el-dialog>


        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Bus from '@/utils/EventBus';
import Editor from 'wangeditor';
export default {
    data() {
        return {
            myNews: [],
            newsAccount: 0,
            realPageNumber: 1,
            pageSize: 8,
            nowNew: {},//当前正在编辑的新闻
            dialogVisible: false,//对话框状态
            editor: null,
        }
    },
    beforeDestroy() {
        // 组件销毁时，销毁编辑器对象
        this.editor.destroy()
        this.editor = null
    },
    created() {
        Bus.$on('newCreating', () => {
            this.initNews()
        })
    },
    mounted() {
        this.initNews()
    },
    methods: {
        //更改内容（带富文本）
        changeWithEditor() {
            this.nowNew.content = this.editor.txt.html()
            axios({
                method: 'post',
                url: this.$hdAddress + "/m/updateNews",
                headers: {
                    token: localStorage.getItem("token")
                },
                data: this.nowNew,
            })
                .then(() => {
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success'
                    });
                    //发布更新通知
                    this.initNews()
                })
                .catch(error => {
                    this.$notify.error({
                        title: '错误',
                        message: '执行失败'
                    });
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
        //初始化editor对象
        initEditor(myNew) {
            this.$nextTick(() => {
                this.editor = new Editor(`#editor`)
                this.editor.config.uploadImgServer = this.$hdAddress + "/m/editorUploadImg"
                this.editor.config.uploadFileName = "file"
                this.editor.config.uploadImgHeaders = {
                    token: localStorage.getItem("token")
                }
                this.editor.create()
                this.editor.txt.html(myNew.content)
            })
        },
        getNewContent(row) {
            //指定数据源
            this.nowNew = row;
            //初始化富文本
            this.initEditor(row)
            try {
                if (this.editor.txt.html())//如果有值就刷新
                    this.editor.txt.html(row.content)
            }catch(e){
                console.log(e);
            }
            //打开编辑框
            this.dialogVisible = true
        },
        del(selectedNew) {
            axios({
                method: "post",
                url: this.$hdAddress + "/m/deleteNewsById",
                headers: {
                    token: localStorage.getItem("token")
                },
                params: {
                    id: selectedNew.id
                }
            }).then(() => {
                this.$notify({
                    title: '成功',
                    message: '删除成功',
                    type: 'success'
                });
                this.initNews()
            }).catch(() => {
                this.$notify.error({
                    title: '错误',
                    message: '执行失败'
                });
            })
        },
        change(selectedNew) {
            axios({
                method: "post",
                url: this.$hdAddress + "/m/updateNews",
                headers: {
                    token: localStorage.getItem("token")
                },
                data: selectedNew
            }).then(() => {
                this.$notify({
                    title: '成功',
                    message: '修改成功',
                    type: 'success'
                });
                this.initNews()
            }).catch(() => {
                this.$notify.error({
                    title: '错误',
                    message: '执行失败'
                });
            })
        },
        handleCurrentChange(newPage) {
            this.realPageNumber = newPage;
            this.getNews();
        },
        initNews() {
            axios({
                method: 'get',
                url: this.$hdAddress + "/selectNewsAccount",
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then(res => {
                this.newsAccount = res.data;
                this.getNews()
            })
        },
        getNews() {
            //获取新闻表
            axios({
                method: 'post',
                url: this.$hdAddress + "/selectAllNewsByPage",
                headers: {
                    token: localStorage.getItem("token")
                },
                data: {
                    "size": this.pageSize,
                    "pageNumber": (this.realPageNumber - 1) * this.pageSize
                },
            })
                .then(res => {
                    this.myNews = res.data
                })
                .catch(error => {
                    this.$notify.error({
                        title: '错误',
                        message: '获取新闻请求失败'
                    });
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
    }
}
</script>

<style></style>