<template>
    <div class="PEC_lunBo2">
        <!-- 添加 ref="carousel" 到 el-carousel 组件 -->
        <el-carousel ref="carousel" :autoplay="autoplay" :interval="4000" :height="this.height + 'em'">
            <el-carousel-item v-for="item in imgsUrls" :key="item">
                <img :src="$hdAddress + item" alt="">
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>

export default {
    props: ['imgsUrls', 'height'],
    data() {
        return {
            autoplay: true,
        }
    },
    mounted() {
        this.observeElement()
        this.slideBanner()
    },
    methods: {
        observeElement() {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.animateIn();
                    }
                });
            });
            observer.observe(this.$el);
        },
        animateIn() {
            // 触发CSS过渡效果
            let 渐变元素 = this.$el.querySelectorAll('img');
            // 随机出现时间
            let currentDelay = 500+1000 * Math.random();

            渐变元素.forEach((item) => {
                // 设置延迟时间，每次迭代增加0.4秒
                setTimeout(() => {
                    item.style.opacity = '1';
                    item.style.transform = 'translateY(0)';
                }, currentDelay);

                // 更新延迟时间，为下一次迭代准备
                currentDelay += 500+300*Math.random(); // 0.2秒转换为毫秒
            });
        },

        slideBanner() {
    var box = this.$el;
    var startPoint = 0;
    var stopPoint = 0;
    var threshold = 50; // 根据轮播图宽度调整这个值

    // 触摸开始时记录起始点
    box.addEventListener('touchstart', (e) => {
        this.autoplay = false; // 触摸时停止自动播放
        startPoint = e.changedTouches[0].clientX;
    });

    // 触摸移动时更新停止点
    box.addEventListener('touchmove', (e) => {
        stopPoint = e.changedTouches[0].clientX;
    });

    // 触摸结束时判断滑动方向
    box.addEventListener('touchend', () => {
        // 计算滑动距离
        var distance = stopPoint - startPoint;

        if (Math.abs(distance) > threshold) {
            // 如果滑动距离超过阈值，则执行翻页操作
            if (distance > 0) {
                this.$refs.carousel.prev();
            } else {
                this.$refs.carousel.next();
            }
        }

        // 重置自动播放
        this.autoplay = true;
    });
},
    }
}
</script>

<style>
.PEC_lunBo2 {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 1em;
}
.PEC_lunBo2 .el-carousel__item{
    padding: 0;
}
.PEC_lunBo2 .el-carousel__item img {
    opacity: 0;
    /* 开始时透明度为0 */
    transform: translateY(2em);
    /* 开始时向上移动 */
    transition: all 1s ease;
    /* 定义CSS过渡效果 */
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

.PEC_lunBo2 .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf00;
}

.PEC_lunBo2 .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce600;
}
</style>