<template>
    <div class="PEPageChanger">

        <div class="indexTitle">修改手机端自定义页面</div>
        <div class="PEPageChanger_createBox_item">
            <el-switch v-model="doS2" active-color="#13ce66" inactive-color="#a3a3a3" active-text="二级页面"
                inactive-text="一级页面" @change="S1AndS2changed()">
            </el-switch>
        </div>
        <div class="PEPageChanger_createBox_item" v-if="doS2">
            选择父页面
            <el-select v-model="S2Parent" placeholder="请选择父页面" @change="getPageListS2">
                <el-option v-for="item in pageListS1" :key="item.id" :label="item.title" :value="item.id">
                </el-option>
            </el-select>
        </div>

        <div class="PEPageChanger_createBox_item">
            <el-button type="danger" @click="getPageListS1()">刷新</el-button>
        </div>



        <el-table :data="selectedList" style="width: 100%">
            <el-table-column prop="priority" label="优先级" width="250">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.priority" :min="1" :max="99999"></el-input-number>
                </template>
            </el-table-column>

            <el-table-column prop="title" label="标题" width="320">
                <template slot-scope="scope">
                    <el-input placeholder="标题" v-model="scope.row.title" clearable>
                    </el-input>
                </template>
            </el-table-column>

            <el-table-column label="显示/隐藏" width="220">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.hidden" active-color="#13ce66" inactive-color="#a3a3a3"
                        active-text="隐藏" inactive-text="显示">
                    </el-switch>
                </template>
            </el-table-column>
            

<el-table-column prop="text" label="页面描述（字符数在120字符之内）" width="300">
  <template slot-scope="scope">
    <textarea
      style="resize: vertical; width: 100%"
      v-model="scope.row.description"
    ></textarea>
  </template>
</el-table-column>

<el-table-column prop="text" label="页面关键词（关键词之间使用英文逗号分隔）" width="300">
  <template slot-scope="scope">
    <textarea
      style="resize: vertical; width: 100%"
      v-model="scope.row.keywords"
    ></textarea>
  </template>
</el-table-column>

            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button @click="change(scope.row)" type="text" size="small">确认修改</el-button>
                    <el-button @click="del(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

    </div>
</template>

<script>
import axios from 'axios';
import Bus from '@/utils/EventBus';
export default {
    data() {
        return {
            doS2: false,
            pageListS1: [],
            selectedList: [],
            S2Parent: null,
        }
    },
    created() {
        Bus.$on('PEPageCreating', () => {
            this.getPageListS1()
        })
    },
    mounted() {
        this.getPageListS1()
    },
    methods: {
        del(row) {
            //如果是来到了S1的状态，就对S1操作，否则对S2操作
            if (!this.doS2)
                this.delS1(row)
            else this.delS2(row)
        },
        delS1(row) {
            axios({
                method: "post",
                url: this.$hdAddress + "/deletePEPageS1ById",
                params:{
                    id:row.id,
                },
            }).then(res=>{
                this.$notify({
                    title: '成功',
                    message: res.data,
                    type: 'success'
                });
                this.getPageListS1()
                Bus.$emit('PEPageCreating', '')
            }).catch(error => {
                this.$notify.error({
                    title: '错误',
                    message: '删除失败'
                });
                console.error('Error:', error.response ? error.response.data : error);
            });
        },
        delS2(row) {
            axios({
                method: "post",
                url: this.$hdAddress + "/deletePEPageS2ById",
                params:{
                    id:row.id,
                },
            }).then(res=>{
                this.$notify({
                    title: '成功',
                    message: res.data,
                    type: 'success'
                });
                this.getPageListS2()
                Bus.$emit('PEPageCreating', '')
            }).catch(error => {
                this.$notify.error({
                    title: '错误',
                    message: '删除失败'
                });
                console.error('Error:', error.response ? error.response.data : error);
            });
        },
        change(row) {
            //如果是来到了S1的状态，就对S1操作，否则对S2操作
            if (!this.doS2)
                this.changeS1(row)
            else this.changeS2(row)
        },
        changeS1(row) {
            axios({
                method: "post",
                url: this.$hdAddress + "/updatePEPageS1ById",
                data: row,
            }).then(res=>{
                this.$notify({
                    title: '成功',
                    message: res.data,
                    type: 'success'
                });
                this.getPageListS1()
                Bus.$emit('PEPageCreating', '')
            }).catch(error => {
                this.$notify.error({
                    title: '错误',
                    message: '修改失败'
                });
                console.error('Error:', error.response ? error.response.data : error);
            });
        },
        changeS2(row) {
            axios({
                method: "post",
                url: this.$hdAddress + "/updatePEPageS2ById",
                data: row,
            }).then(res=>{
                this.$notify({
                    title: '成功',
                    message: res.data,
                    type: 'success'
                });
                this.getPageListS2()
                Bus.$emit('PEPageCreating', '')
            }).catch(error => {
                this.$notify.error({
                    title: '错误',
                    message: '修改失败'
                });
                console.error('Error:', error.response ? error.response.data : error);
            });
        },
        S1AndS2changed() {
            //如果是来到了S1的状态，就获取S1的信息
            if (!this.doS2)
                this.getPageListS1()
        },
        getPageListS1() {
            this.S2Parent = null
            this.doS2 = false
            axios({
                method: "get",
                url: this.$hdAddress + "/selectAllPEPageS1",
            }).then(res => {
                this.pageListS1 = res.data
                this.selectedList = this.pageListS1
            }).catch(error => {
                this.$notify.error({
                    title: '错误',
                    message: '获取页面失败'
                });
                console.error('Error:', error.response ? error.response.data : error);
            });
        },
        getPageListS2() {
            this.doS2 = true
            axios({
                method: "post",
                url: this.$hdAddress + "/selectAllPEPageS2ByParent",
                params: {
                    parent: this.S2Parent,
                }
            }).then(res => {
                this.selectedList = res.data
            }).catch(error => {
                this.$notify.error({
                    title: '错误',
                    message: '获取页面失败'
                });
                console.error('Error:', error.response ? error.response.data : error);
            });
        },
    },
}
</script>

<style></style>