<template>
    <div id="OperationFlow">
      <!-- 业务流程 -->
      <!-- 顶部图片 -->
      <!-- <TopImg :imgUrl="FImgUrl"></TopImg> -->
  
      <div class="indexTitle">业务流程</div>
      <NormalImg1 imgHeight="42" :imgUrl="NormalImg1[0]"></NormalImg1>
  
      <TW1 imgHeight="30" imgWidth="30" :text="this.TW1Text1[0].text" :imgUrl="imgUrl2[0]"></TW1>
      <TW2 imgHeight="30" imgWidth="30" :text="this.TW1Text2[0].text" :imgUrl="imgUrl3[0]"></TW2>
      <TW1 imgHeight="30" imgWidth="30" :text="this.TW1Text3[0].text" :imgUrl="imgUrl4[0]"></TW1>
  
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        NormalImg1: [],
        imgUrl2: [],
        imgUrl3: [],
        imgUrl4: [],
        TW1Text1: [],
        TW1Text2:[],
        TW1Text3:[],
      }
    },
    mounted(){
      this.NormalImg1 = this.$getImg("代理加盟业务流程图片1")
      this.imgUrl2 = this.$getImg("代理加盟业务流程图片2")
      this.imgUrl3 = this.$getImg("代理加盟业务流程图片3")
      this.imgUrl4 = this.$getImg("代理加盟业务流程图片4")
      this.TW1Text1 = this.$getText("游戏代理业务流程文段1")
      this.TW1Text2 = this.$getText("游戏代理业务流程文段2")
      this.TW1Text3 = this.$getText("游戏代理业务流程文段3")
    }
  }
  </script>
  
  <style>
  #OperationFlow {
    width: 100%;
    display: block;
  }
  </style>