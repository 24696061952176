<template>
  <div class="AboutUs_box2_inner_container">
    <div class="AboutUs_box2_inner_left">
      <!-- 公司概况 -->
      <pre>
    <!-- 风林手游，作为四川风林网络科技有限公司的核心品牌，自2011年创立以来，经过多年的发展和积累，已经成为游戏行业的一股不可忽视的力量。公司在游戏代理、发行和推广代运营领域取得了显著成就。

    在游戏代理领域，风林手游与国内一线游戏厂商如腾讯、网易等建立了紧密的业务合作关系，获取了各类热门手游的代理权，确保合作伙伴能够代理到具有市场竞争力的游戏产品 。公司不仅提供游戏代理权获取，还提供全方位的游戏运营支持，包括游戏服务器的搭建与维护、游戏版本的更新与优化、游戏活动的策划与执行等 。

    风林手游还拥有灵活的代理合作模式，包括独家代理、联合运营和一级代理，以适应不同合作伙伴的需求 。此外，公司还为合作伙伴提供了全面的培训与支持体系，包括专业培训、技术支持和市场指导，帮助合作伙伴快速上手并提升运营水平 。

    在未来，风林手游将继续秉持创新精神，深化与合作伙伴的共赢合作，共同开创手游行业更加辉煌的明天。 -->
    <div v-html="this.text1[0].text" ></div>
      </pre>
    </div>
    <div class="AboutUs_box2_inner_right">
      <img class="AboutUs_box2_inner_image" :src="this.FImgUrl1[0]" alt="公司形象图">
    </div>
  </div>
</template>

<script>
export default {
  data(){
      return{
          FImgUrl1: [],
          text1:[],
      }
  },
  mounted(){
    this.FImgUrl1 = this.$getImg("关于我们公司概况图片1")
    this.text1 = this.$getText("关于我们公司概况文段1")
    
  },

}
</script>

<style>

pre {
  line-height: 1.5;
  color: #333;
    font-size: 1.5em; 
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
}
.AboutUs_box2_inner_container {
  padding: 0;
  padding-left: 10%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
}

.AboutUs_box2_inner_left {
  width: 60%;
  padding-right: 20px;
}

.AboutUs_box2_inner_right {
  width: 45%;
  text-align: center;
}

.AboutUs_box2_inner_image {
  max-width: 100%;
  height: auto;
  display: block; /* 确保图片不会超出容器宽度 */
  margin: 0 auto; /* 水平居中图片 */
}

</style>