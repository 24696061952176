<template>
  <div id="IndexCarouselContainer">
    <el-carousel indicator-position="outside">
      <el-carousel-item v-for="item in this.imgUrls" :key="item">
        <img class="el-carousel-item-img" :src="item" alt="">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrls: []
    };
  },
  mounted() {
    this.imgUrls = this.$getImg("首页轮播图")
  }
}
</script>

<style>
#IndexCarouselContainer {
  left: 0;
  top: 0;
  width: 100%;

}

.el-carousel-item-img {
  height: 100%;
  width: 100%;
}

.el-carousel__container {
  width: 100%;
  height: 38em;
}

.el-carousel__item h3 {
  /* color: #475669; */
  font-size: 18px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bfe5;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6e3;
}
</style>