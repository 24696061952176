<template>
    <div class="PromotionPlan">
        <!-- 循环渲染 -->
        <div class="PromotionPlan_inners" v-for="item in pageItems" :key="item.priority">
            <div class="indexTitle" v-if="item.type == 1">{{ item.title }}</div>
            <NormalText1 :text="item.text" v-if="item.type == 2"></NormalText1>
            <NormalImg1 :imgUrl="getAddress() + item.img_url" imgHeight="25" v-if="item.type == 3"></NormalImg1>
      <TW1 imgHeight="30" imgWidth="30" :text="item.text" :imgUrl="getAddress() + item.img_url"  v-if="item.type == 4"></TW1>
      <TW2 imgHeight="30" imgWidth="30" :text="item.text" :imgUrl="getAddress() + item.img_url"  v-if="item.type == 5"></TW2>
            <TopImg :imgUrl="getAddress() + item.img_url" v-if="item.type == 6"></TopImg>
        </div>
    </div>
</template>

<script>
import Bus from '@/utils/EventBus';
import axios from 'axios';
export default {
    data() {
        return {
            self: {},
            pageItems: [],
        }
    },
    mounted() {
        Bus.$emit('PromotionPlanChose', '')
        // console.log(this.pageItems);
        //查询当前页名称
        axios({
            method: "post",
            params: {
                name: this.way
            },
            url: this.$hdAddress + '/selectCustomPageByName',
        }).then(res => {
            this.self = res.data
            //更新页面信息
    document.title = this.self.name;
    document.querySelector('meta[name="description"]').setAttribute('content', this.self.description);
    document.querySelector('meta[name="keywords"]').setAttribute('content', this.self.keywords);
            //查询页面中所有组件
            axios({
                method: "post",
                params: {
                    affiliation_id: this.self.id
                },
                url: this.$hdAddress + '/selectAllCustomPageComponentsByAfId',
            }).then(res => {
                this.pageItems = res.data
                console.log(this.pageItems);
            })
        })

        //渲染当前页

    },
    computed: {
        way() {
            // 使用正则表达式查找查询参数，如果匹配成功，解析查询参数并返回 'way' 参数的值
            const regex = /(?:\?|&)way=([^&]+)/;
            const match = window.location.href.match(regex);
            if (match) {
                return decodeURIComponent(match[1]); // 解码 URI 组件以避免编码问题
            }
            return "安卓"; // 如果没有找到 way 参数，返回 null 或者其他默认值
        },
    },
    methods:{
        getAddress(){return this.$hdAddress}
    }
}
</script>

<style>
.PromotionPlan{
    margin: 0;
    padding: 0;
    position: relative;
}
</style>