<template>
    <div class="PEC_card1">
        <!-- 图文卡片（二分之一宽） -->
        <div class="PEC_card_img">
            <img :src="$hdAddress + imgsUrls[0]" alt="">
        </div>
        <div class="PEC_card_text">
            <span>{{ this.text }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['imgsUrls', 'text'],
    mounted() {
        this.observeElement();
    },
    methods: {
        observeElement() {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.animateIn();
                    }
                });
            });
            observer.observe(this.$el);
        },
        animateIn() {
            // 触发CSS过渡效果
            let Elements1 = Array.from(this.$el.querySelectorAll('img'));
            let Elements2 = Array.from(this.$el.querySelectorAll('span'));
            let 渐变元素 = Elements1.concat(Elements2); // 合并两个 NodeList 为一个数组
            // 随机出现时间
            let currentDelay = 500+1000 * Math.random();

            渐变元素.forEach((item) => {
                // 设置延迟时间，每次迭代增加0.4秒
                setTimeout(() => {
                    item.style.opacity = '1';
                    item.style.transform = 'translateY(0)';
                }, currentDelay);

                // 更新延迟时间，为下一次迭代准备
                currentDelay += 500+400 * Math.random(); // 0.2秒转换为毫秒
            });
        }
    }
}
</script>

<style>
.PEC_card1 {
    display: block;
    float: left;
    width: 80%;
    margin-left: 10%;
    margin-right: 0;
    margin-top: 1em;
    overflow: hidden;
    height: 10em;
    background-color: #f5f5f5;
    border-radius: 1em;
}


.PEC_card1 span{
    opacity: 0;
    transform: translateY(2em);
    transition: all 1s ease;
}

.PEC_card_img {
    width: 50%;
    height: 6em;
    margin: auto;
    margin-top: 2em;
}

.PEC_card_img img {
    width: 100%;
    opacity: 0;
    transform: translateY(2em);
    transition: all 1s ease;
}

.PEC_card_text {
    width: 100%;
    text-align: center;
    margin: auto;
}
</style>
