<template>
  <div class="ServicesOptionsChanger">
    <el-table
      class="indexTitle_belong"
      :data="ServiceOptions"
      style="width: 100%"
    >
      <el-table-column prop="id" label="id" width="120">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column prop="label" label="标签" width="700">
        <template slot-scope="scope">
          <input type="text" v-model="scope.row.label" />
        </template>
      </el-table-column>

      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="change(scope.row)" size="small"
            >确认修改</el-button
          >
          <el-button @click="del(scope.row)" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from "axios";
import Bus from "@/utils/EventBus";
export default {
  data() {
    return {
      ServiceOptions: [],
    };
  },
  created() {
    Bus.$on("ServicesOptionsCreating", () => {
      this.getServiceOptions();
    });
  },
  mounted() {
    this.getServiceOptions();
  },
  methods: {
    change(row) {
      axios({
        method: "post",
        url: this.$hdAddress + "/m/updateLabelById",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: row.id,
          label: row.label,
        },
      })
        .then(() => {
          this.$notify({
            title: "成功",
            message: "修改成功",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify.error({
            title: "错误",
            message: "执行失败",
          });
          console.error("Error:", error.response ? error.response.data : error);
        });
    },
    del(row) {
      axios({
        method: "post",
        url: this.$hdAddress + "/m/deleteLabelById",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: row.id,
        },
      })
        .then(() => {
          this.$notify({
            title: "成功",
            message: "删除成功",
            type: "success",
          });
          this.getServiceOptions();
        })
        .catch((error) => {
          this.$notify.error({
            title: "错误",
            message: "执行失败",
          });
          console.error("Error:", error.response ? error.response.data : error);
        });
    },
    getServiceOptions() {
      axios({
        method: "get",
        url: this.$hdAddress + "/selectLabel",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.ServiceOptions = response.data;
        })
        .catch((error) => {
          console.error("Error:", error.response ? error.response.data : error);
        });
    },
  },
};
</script>

<style>
</style>