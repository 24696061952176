<template>
    <div class="PEC_gameSmallCard">

        <div class="PEC_gameSmallCard_box">
            <div class="PEC_gameSmallCard_box_small_items" v-for="item in msgs" :key="item.name">
                <a :href="`${$qdAddress}/#/GameDetail?name=` + item.name">
                    <div class="PEC_gameSmallCard_box_small_items_img">
                        <img :src="item.icon" alt="">
                    </div>
                    <div class="PEC_gameSmallCard_box_small_items_title">
                        <span>{{ item.name }}</span>
                    </div>
                </a>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            msgs: []
        }
    },
    mounted() {
        this.getMsg()
        this.observeElement();
    },
    methods: {
        getMsg() {
            axios({
                method: 'get',
                url: this.$hdAddress + '/selectMyIndexGameShower2',
            }).then(res => {
                // this.msgs = res.data
                //去除掉10号以后的所有数据
                this.msgs = res.data.slice(0, 6);
            })
        },
        observeElement() {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.animateIn();
                    }
                });
            });
            observer.observe(this.$el);
        },
        animateIn() {
            // 触发CSS过渡效果
            let Elements1 = Array.from(this.$el.querySelectorAll('span'));
            let Elements2 = Array.from(this.$el.querySelectorAll('img'));
            let 渐变元素 = Elements1.concat(Elements2); // 合并两个 NodeList 为一个数组
            // 随机出现时间
            let currentDelay = 500+100 * Math.random();

            渐变元素.forEach((item) => {
                // 设置延迟时间，每次迭代增加0.4秒
                setTimeout(() => {
                    item.style.opacity = '1';
                    item.style.transform = 'translateY(0)';
                }, currentDelay);

                // 更新延迟时间，为下一次迭代准备
                currentDelay += 100+100 * Math.random(); // 0.2秒转换为毫秒
            });
        }
    }
}
</script>


<style>
.PEC_gameSmallCard {
    width: 100%;
    padding: 0;
    padding-top: 2rem;
    /* 使用相对单位 rem 作为边距，基于根元素的字体大小 */
    box-sizing: border-box;
    /* 确保 padding 包含在宽度内 */
}

.PEC_gameSmallCard_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /* 允许换行显示 */
    justify-content: space-between;
    /* 子元素之间保持间距 */
    padding: 0;
    /* 内部边距 */
}

.PEC_gameSmallCard_box_small_items {
    width: 100%;
    flex-basis: calc(48% - 1rem);
    /* 每个项目的基础宽度为48%减去间距，以容纳边距 */
    margin-bottom: 1rem;
    /* 项目底部边距 */
    box-sizing: border-box;
    /* 确保 padding 和 border 包含在宽度内 */
    border-radius: 0.5rem;
    /* 边框圆角 */
    overflow: hidden;
    /* 隐藏超出部分 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* 轻微的阴影效果 */
}

.PEC_gameSmallCard_box_small_items_img {
    width: 100%;
    height: 15vw;
    /* 使用视口宽度的15%作为图片高度，保持图片比例 */
    background-color: #f0f0f0;
    /* 轻微的背景色 */
}

.PEC_gameSmallCard_box_small_items_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    opacity: 0;
    transform: translateY(2em);
    transition: all 1s ease;
}

.PEC_gameSmallCard_box_small_items_title {
    padding: 0.5rem;
    text-align: center;
    font-size: 0.9rem;
}

.PEC_gameSmallCard_box_small_items_title span {
    opacity: 0;
    transform: translateY(2em);
    transition: all 1s ease;
}

/* 确保链接在所有状态下都显示正常 */
.PEC_gameSmallCard_box_small_items a {
    text-decoration: none;
    /* 移除下划线 */
    color: inherit;
    /* 继承父元素的颜色 */
    display: block;
    /* 使链接占据整个容器 */
}

/* 响应式调整：在较小的屏幕上，每个项目占据100%宽度 */
@media (max-width: 768px) {
    .PEC_gameSmallCard_box_small_items {
        flex-basis: 100%;
        margin-bottom: 0.5rem;
        /* 调整边距以适应更小的屏幕 */
    }
}
</style>