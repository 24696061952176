<template>
  <!-- 图文结合组件，左文右图 -->
  <div class="TW1">
    <div class="TW1_left">
      <pre>
        <div v-html="this.text" class="TW1_left_div"></div>
      </pre>
    </div>
    <div class="TW1_right">
      <!-- <img class="TW1_right_image" :style="TW1_right_image_style" src="images/ContactUs/ContactUs_box2_inner.png" alt="图片"> -->
      <img class="TW1_right_image" :style="TW1_right_image_style" :src="imgUrl" alt="图片">
    </div>
  </div>
</template>

<script>

export default {
  props: ['imgWidth', 'imgHeight', 'text', 'imgUrl'],
  // 组件逻辑s
  data() {
    return {
      // width:this.imgHeight,
      // height:this.imgHeight,
      // text:
      // "手游，作为四川风林网络科技有限公司的核心品牌\n"+
      // "手游，作为四川风林网络科技有限公司的核心品牌\n"+
      // "风林手游，作为四川风林网络科技有限公司的核心品牌，自2011年创立以来，经过多年的为四川风林网络科技有限公司的核心品牌，自2011年创立以来，经过多年的为四川风林网络科技有限公司的核心品牌，自2011年创立以来，经过多年的为四川风林网络科技有限公司的核心品牌，自2011年创立以来，经过多年的发展和积累，已经成为游戏行业的一股不可忽视的力量。公司在游戏代理、发行和推广代运营领域取得了显著成就。"
    }
  },
  computed: {
    TW1_right_image_style() {
      return "width:" + this.imgWidth + "em;" + "height:" + this.imgHeight + "em;"
    }
  }
}
</script>

<style>
.TW1_left_div {
  line-height: 1.5;
  color: #333;
  font-size: 1em;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  overflow: hidden;
}

.TW1 {
  width: 100%;
  padding: 0;
  display: flex;
  height: auto;
  overflow: hidden;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  margin-top: 3em;
  margin-bottom: 3em;
}

.TW1_left {
  float: none; /* 修改为 flex 布局，与 TW2 保持一致 */
  width: 30%; /* 保持一致 */
  margin: 0; /* 调整为 0，与 TW2 保持一致 */
  padding-left: 20%; /* 调整为 0，与 TW2 保持一致 */
  padding-right: 0; /* 调整为 20%，与 TW2 保持一致 */
}

.TW1_right {
  float: none; /* 修改为 flex 布局，与 TW2 保持一致 */
  width: 40%; /* 保持一致 */
  margin: 0; /* 调整为 0，与 TW2 保持一致 */
  margin-top: 0; /* 调整为 0，与 TW2 保持一致 */
  padding-left: 0; /* 调整为 0，与 TW2 保持一致 */
  padding-right: 20%; /* 调整为 0，与 TW2 保持一致 */
  text-align: center;
  overflow: hidden;
}

.TW1_right_image {
  padding-left: 0; /* 调整为 0，与 TW2 保持一致 */
  max-width: 100%;
  height: auto; /* 调整为 auto，与 TW2 保持一致 */
  display: block;
  margin: 0 auto; /* 调整为 auto，与 TW2 保持一致 */
}
</style>
