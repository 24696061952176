<template>
  <div class="PE">
    <!-- <PEHeader></PEHeader> -->
    
    <div class="PE_container clearfix" v-if="pageComponents.length > 0"
      >
      <!-- 循环渲染拿到的数据 -->
      <div class="PE_container_item" v-for="item in pageComponents" :key="item.id"
        :style="'width:'+kuanDu(item.type)+';'"
      >
          <PEC_text v-if="item && item.type == '纯文本'" :text="item.text"></PEC_text>
          <PEC_img1 v-if="item && item.type == '页头横图'" :imgUrl="$hdAddress + item.img_urls[0]"></PEC_img1>
          <PEC_img2 v-if="item && item.type == '高度可调图片（文本里写高度数字）'" :imgUrl="$hdAddress + item.img_urls[0]" :height="item.text"></PEC_img2>
          <PEC_title v-if="item && item.type == '标题'" :text="item.text"></PEC_title>
          <PEC_paratitle v-if="item && item.type == '副标题'" :text="item.text"></PEC_paratitle>
          <PEC_lunBo1 v-if="item && item.type == '卡片轮播图（文本里写高度数字）'" :imgsUrls="item.img_urls" :height="item.text"></PEC_lunBo1>
          <PEC_lunBo2 v-if="item && item.type == '滑动轮播图（文本里写高度数字）'" :imgsUrls="item.img_urls" :height="item.text"></PEC_lunBo2>
          <PEC_lunBo3 v-if="item && item.type == '图文轮播图（文本里写说明文本，每句之间以&分隔）'" :imgsUrls="item.img_urls" :text="item.text"></PEC_lunBo3>
          <PEC_card1 v-if="item && item.type == '图文卡片（二分之一宽）'" :imgsUrls="item.img_urls" :text="item.text"></PEC_card1>
          <PEC_card2 v-if="item && item.type == '图文卡片（三分之一宽）'" :imgsUrls="item.img_urls" :text="item.text"></PEC_card2>
          <PEC_gameSmallCard v-if="item && item.type == '游戏列表卡片'"></PEC_gameSmallCard>
          <PEC_jumper v-if="item && item.type == '跳转站内链接（文本里写路由）'" :href="item.text"></PEC_jumper>
          <PEC_Mform v-if="item && item.type == '留资表(代理加盟)'" formType="0"></PEC_Mform>
          <PEC_Mform v-if="item && item.type == '留资表(厂商入驻)'" formType="1"></PEC_Mform>
      </div>
    </div>
    
  </div>
</template>

<script>
import Bus from '@/utils/EventBus';
import axios from 'axios';

export default {
  components:{
  }, 
  created() {
    this.currentPage = { title: "首页" }
    this.getPageMsg()
    Bus.$on("PEChecked", (msg) => {
      this.currentPage = msg
      this.getPageMsg()
    document.querySelector('meta[name="description"]').setAttribute('content', msg.description);
    document.querySelector('meta[name="keywords"]').setAttribute('content', msg.keywords);
      
    })
    //如果没收到，那么由localStorage来兜底
    let aim = JSON.parse(localStorage.getItem("pageMsg"))
    if(aim!=""&&aim!=undefined){
      this.currentPage = aim
      this.getPageMsg()
    }
  },
  data() {
    return {
      currentPage: {},
      pageComponents: [],
    }
  },
  methods: {
    kuanDu(type){
      if(type == '图文卡片（二分之一宽）'){
        return "50%;"
      }
      if(type == '图文卡片（三分之一宽）'){
        return "33%;"
      }
      return "100%"
    },
    getPageMsg() {
      axios({
        method: "post",
        url: this.$hdAddress + '/selectPeCustomPageComponentsByParent',
        params: {
          parent: this.currentPage.title
        },
      }).then( res => {
        this.pageComponents = res.data
      })
    },
  }
}
</script>

<style>
.PE_container {
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: block;
  position: relative;
  padding-top: 3em;
  border-radius: 1px;
  width: 100%;
  height: auto;
}

.PE_container_item {
  /* width: 100%; */
  padding: 0;
  margin: 0;
  display: block;
  float: left;
}

.clearfix::after {
   content: " ";
   display: block;
   clear: both;
}

</style>