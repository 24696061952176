<template>
  <div class="PEC_paratitle">
    <!-- 标题组件 -->
    <h2 v-html="this.text"></h2>
  </div>
</template>

<script>
export default {
    props:['text'],
  mounted() {
    this.observeElement();
  },
  methods: {
    observeElement() {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.animateIn();
          }
        });
      });
      observer.observe(this.$el);
    },
    animateIn() {
            // 触发CSS过渡效果
            let 渐变元素 = this.$el.querySelectorAll('h2');
            // 随机出现时间
            let currentDelay = 500+1000 * Math.random();

            渐变元素.forEach((item) => {
                // 设置延迟时间，每次迭代增加0.4秒
                setTimeout(() => {
                    item.style.opacity = '1';
                    item.style.transform = 'translateY(0)';
                }, currentDelay);

                // 更新延迟时间，为下一次迭代准备
                currentDelay += 500+300*Math.random(); // 0.2秒转换为毫秒
            });
    }
  }
}
</script>

<style>
.PEC_paratitle h2 {
  white-space: pre-wrap;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  color: #333333a5;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.8); /* 文字发光效果 */
  transition: all 0.3s ease; /* 平滑过渡效果 */
  opacity: 0; /* 开始时透明度为0 */
  transform: translateY(2em); /* 开始时向上移动 */
  transition: all 1s ease; /* 定义CSS过渡效果 */
}
.PEC_paratitle {
  padding-left: 10%;
  padding-right: 10%;
  width: 100%-20%;
  display: flex; /* 使用Flexbox布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.PEC_paratitle h2::after{
  content: '';
  display: block;
  width: 3em;
  height: 0.1em;
  background-color: #464646;
  margin: 0 auto;
  animation: PE副标题变短 0.5s ease-in forwards;
}
@keyframes PE副标题变短 {
  from {
    width:3em;
  }
  to {
    width:0;
  }
}
</style>