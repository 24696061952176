<template>
  <div id="AboutUs_box1_inner">

    <img :src="FImgUrl1[0]" alt="">
  </div>
</template>

<script>
export default {

  mounted() {
    this.FImgUrl1 = this.$getImg("关于我们大图")
  },
  data(){
    return{
      FImgUrl1:[]
    }
  }
}
</script>

<style>
#AboutUs_box1_inner {
  width: 100%;
  height: 30em;
}

#AboutUs_box1_inner img {
  width: 100%;
  height: 100%;
}
</style>