<template>
  <div id="not-found-page" class="error-page">
    <div class="error-content">
      <div class="error-code">404</div>
      <div class="error-message">哎呀，您访问的页面不存在。</div>
      <div class="brand-info">
        <h2 class="brand-name">四川省风林网络有限公司</h2>
        <div class="brand-advantages">
          <p>加入我们，共创辉煌！</p>
          <ul>
            <li>强大的品牌支持</li>
            <li>成熟的经营模式</li>
            <li>专业的技术支持</li>
            <li>丰厚的利润分享</li>
          </ul>
        </div>
        <div class="action-buttons">
          <router-link to="/" class="home-link">返回首页</router-link>
          <a href="#/AboutUs" class="join-link">加盟咨询</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage'
}
</script>

<style scoped>
.error-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: #f44336; /* 红色背景 */
  color: #fff; /* 白色文字 */
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5); /* 添加阴影效果 */
}

.error-content {
  /* max-width: 600px; */
  margin: auto;
}

.error-code {
  font-size: 6em;
  color: #b71c1c; /* 深红色 */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* 添加深红色文字阴影 */
}

.error-message {
  font-size: 1.5em;
  color: #f44336; /* 标准红色 */
  margin-bottom: 20px;
}

.brand-name {
  font-size: 2.5em;
  color: #d32f2f; /* 较浅红色 */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); /* 较浅红色文字阴影 */
}

.brand-advantages p {
  font-size: 1.2em;
  color: #e53935; /* 较深红色 */
  margin-bottom: 0.5em;
}

.brand-advantages ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1.5em;
}

.brand-advantages li {
  margin: 0.8em;
  display: inline-block;
  font-size: 1em;
  color: #f44336; /* 标准红色 */
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2); /* 轻微白色背景 */
  margin-bottom: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* 轻微阴影 */
}

.action-buttons {
  margin-top: 20px;
}

.home-link, .join-link {
  display: inline-block;
  margin: 0 10px;
  padding: 10px 30px;
  background: #fff; /* 白色按钮 */
  color: #f44336; /* 红色文字 */
  border-radius: 30px;
  text-decoration: none;
  transition: background-color 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* 按钮阴影 */
}

.join-link {
  background: #d32f2f; /* 较浅红色按钮 */
  color: #fff; /* 白色文字 */
}

.home-link:hover, .join-link:hover {
  background: #e0e0e0; /* 鼠标悬浮时的灰色背景 */
  color: #f44336; /* 红色文字 */
}
</style>