<template>
  <div>
    <IndexCarousel></IndexCarousel>
    <div id="MyIndex_box1">
      <div id="MyIndex_box1_box1">
        <!-- 业务介绍卡片 -->
        <MyIndex_box1_box1_inner></MyIndex_box1_box1_inner>
      </div>
    </div>
    <div id="MyIndex_box2">
      <div class="indexTitle">最新上线</div>
      <!-- 最新上线轮播图 -->
      <MyIndex_box2_inner></MyIndex_box2_inner>
    </div>
    <br /><br /><br />
    <div class="indexTitle">合作流程</div>
    <div id="MyIndex_box3">
      <!-- 合作流程卡片 -->
      <MyIndex_box3_inner></MyIndex_box3_inner>
    </div>
    <div class="indexTitle">近期上架</div>
    <div id="MyIndex_box4">
      <MyIndex_box4_inner></MyIndex_box4_inner>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    //如果手机端误入电脑端，则跳转
    if (this.isPE) this.$router.push("/PE");
  },
  computed: {
    isPE() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      //如果是手机端访问，则看作是手机端
      if (flag) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.indexTitle {
  font-family: "黑体", sans-serif;
  /* 使用黑体字体 */
  font-size: 2em;
  /* 字体大小为2em */
  color: #333;
  /* 字体颜色 */
  text-align: center;
  /* 文本居中对齐 */
  margin: 1em 0;
  /* 标题与上下内容的间距为1em */
  padding-bottom: 0.5em;
  /* 标题下方内边距为0.5em */
  position: relative;
  /* 相对定位，为添加小短线做准备 */
}

.indexTitle::after {
  content: "";
  /* 伪元素内容为空 */
  display: block;
  /* 显示为块级元素 */
  width: 2em;
  /* 宽度为父元素宽度的50% */
  height: 0.3em;
  /* 高度为0.3em */
  background-color: #ff0000;
  /* 背景颜色为蓝色 */
  border-radius: 1em;
  /* 圆滑的边角 */
  margin: 0 auto;
  /* 水平居中 */
  margin-top: 0.5em;
  /* 与标题的间距 */
}

#MyIndex_box1 {
  /* background-color: red; */
  overflow: visible;
  height: 25em;
  width: 100%;
}

#MyIndex_box1_box1 {
  position: absolute;
  /* background-color: blue; */
  margin: auto;
  height: 35em;
  width: 70%;
  margin-top: -10em;
  margin-left: 15%;
  z-index: 3;
}

#MyIndex_box2 {
  /* background-color: yellow; */
  overflow: visible;
  height: 45em;
  width: 100%;
}

#MyIndex_box3 {
  /* background-color: red; */
  overflow: visible;
  height: 15em;
  width: 100%;
}

#MyIndex_box4 {
  overflow: visible;
  height: 30em;
  width: 100%;
}
</style>