<template>
    <div id="ContactUs">
      <div id="ContactUs_box1">
          <!-- 顶部图片 -->
        <!-- <ContactUs_box1_inner></ContactUs_box1_inner> -->
      </div>
      <div id="ContactUs_box2" class="ContactUs_box">
        <div class="indexTitle">联系我们</div>
        <ContactUs_box2_inner></ContactUs_box2_inner>
      </div>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  #ContactUs{
      width: 100%;
      /* display: block; */
  }
  .ContactUs_box{
      padding: 5em;
      padding-left: 10em;
      padding-right: 10em;
  }
  </style>