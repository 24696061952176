<template>
  <div id="MyIndex_box1_box1_inner">
    <img :src="this.imgUrls[0]" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrls: []
    };
  },
  mounted() {
    this.imgUrls = this.$getImg("首页图片1")
  }
}
</script>

<style>
#MyIndex_box1_box1_inner{
    overflow: hidden;
    border-radius: 0.5em;
    width: 100%;
    height: 100%;
}
#MyIndex_box1_box1_inner img{
    width: 100%;
    height: 100%;
}
</style>