<template>
    <div class="ComponentCreater">
        <div class="indexTitle">添加自定义组件</div>
        <div class="newComponent_affiliation_id">
            父页面
            <el-select v-model="newComponent.affiliation_id" placeholder="请选择父页面">
                <el-option v-for="item in myPages" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
        </div>


        <div class="newComponent_priority">
            优先级
            <el-input-number v-model="newComponent.priority" :min="1" :max="99999"></el-input-number>
            排序优先级，1为最低，随增加依次升高，越大的值代表会越靠前
        </div>
        <div class="newComponent_type">
            类型
            <el-select v-model="newComponent.type" placeholder="请选择">
                <el-option v-for="item in typesOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
        </div>
        <!-- 根据类型显示标题输入框 -->
        <div v-if="newComponent.type === '1'" class="newComponent_title">
            标题
            <el-input v-model="newComponent.title" placeholder="请输入内容"></el-input>
        </div>
        <!-- 根据类型显示文本输入框 -->
        <div v-if="newComponent.type === '2' || newComponent.type === '4' || newComponent.type === '5'"
            class="newComponent_text">
            文本
            <textarea v-model="inputText" @input="convertNewLines" placeholder="请输入内容"></textarea>
        </div>
        <!-- 根据类型显示文件上传 -->
        <div v-if="newComponent.type === '3' || newComponent.type === '4' || newComponent.type === '5' || newComponent.type === '6'"
            class="newComponent_file">
            图片
            <input class="NormalBtn" type="file" @change="handleFileChange">
        </div>
        <el-button type="danger" @click="createNewComponent">新增</el-button>
    </div>
</template>

<script>
import Bus from '@/utils/EventBus';
import axios from 'axios';
import { Select, Option } from 'element-ui';

export default {
    created() {
        Bus.$on('pageCreating', () => {
            this.getPages()
        })
    },
    mounted() {
        this.getPages()
    },
    components: {
        'el-select': Select,
        'el-option': Option,
    },
    data() {
        return {
            inputText: '',
            processedText: '',
            myPages: [],
            newComponent: {
                affiliation_id: '',
                //priority: 0,   // 设置优先级的初始值为0
                type: '',
                note: '',
                title: '',
                text: ''
            },
            typesOptions: [ // 定义类型选择框的选项
                { value: '1', label: '标题' },
                { value: '2', label: '纯文字' },
                { value: '3', label: '图片' },
                { value: '4', label: '左文右图' },
                { value: '5', label: '左图右文' },
                { value: '6', label: '页头大图' }
            ],
            file: null // 添加文件变量
        };
    },

    methods: {
        getPages() {
            axios({
                method: 'get',
                url: this.$hdAddress + "/selectAllCustomPages",
            })
                .then(response => {
                    this.myPages = response.data
                })
                .catch(error => {
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
        convertNewLines() {
            this.processedText = this.inputText.replace(/\n/g, '<br>');
            // 这里可能需要将 processedText 设置到新组件的 text 字段
            this.newComponent.text = this.processedText;
        },
        convertNewLines_behind() {
            // 这个逆向操作可能在将数据保存到数据库之前使用
            this.inputText = this.processedText.replace(/<br>/g, '\n');
        },
        handleFileChange(event) {
            this.file = event.target.files[0]; // 存储文件对象
        },
        createNewComponent() {
            if ((!this.file) && (this.newComponent.type == 3 || this.newComponent.type == 4 || this.newComponent.type == 5 || this.newComponent.type == 6)) {
                console.error('请先选择文件。');
                return;
            }
            if (!this.ifOk()) return;
            const formData = new FormData();
            formData.append('imageFile', this.file);
            formData.append('affiliation_id', this.newComponent.affiliation_id || '');
            formData.append('priority', this.newComponent.priority || '');
            formData.append('type', this.newComponent.type || '');
            formData.append('note', this.newComponent.note || '');
            formData.append('title', this.newComponent.title || '');
            formData.append('text', this.newComponent.text || '');

            axios({
                method: 'post',
                url: this.$hdAddress + "/m/insertCustomPageComponent",
                data: formData,
                headers: {
                    token:localStorage.getItem("token"),
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(response => {
                    console.log('Response:', response.data);

                    this.$notify({
                        title: '成功',
                        message: '新增成功',
                        type: 'success'
                    });

                    // this.$router.go(0);
                    //发布更新通知
                    Bus.$emit('componentCreating', '')
                }).catch(error => {
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
        //检查合法性
        ifOk() {

            // 检查是否选择了父组件
            if (!this.newComponent.affiliation_id) {
                this.$notify.error({
                    title: '错误',
                    message: '请选择父组件'
                });
                return false;
            }

            // 检查优先级是否选择
            if (!this.newComponent.priority) {
                this.$notify.error({
                    title: '错误',
                    message: '请选择优先级'
                });
                return false;
            }


            // 检查类型是否选择
            if (!this.newComponent.type) {
                this.$notify.error({
                    title: '错误',
                    message: '请选择类型'
                });
                return false;
            }

            // 根据类型检查其他必填项
            const fileRequiredTypes = ['3', '4', '5', '6'];
            if (fileRequiredTypes.includes(this.newComponent.type) && !this.file) {
                this.$notify.error({
                    title: '错误',
                    message: '请先上传文件'
                });
                return false;
            }

            // 标题类型，检查标题是否填写
            if (this.newComponent.type === '1' && !this.newComponent.title) {
                this.$notify.error({
                    title: '错误',
                    message: '请输入标题'
                });
                return false;
            }

            // 纯文字、左文右图、左图右文类型，检查文本是否填写
            if (['2', '4', '5'].includes(this.newComponent.type) && !this.newComponent.text) {
                this.$notify.error({
                    title: '错误',
                    message: '请输入文本内容'
                });
                return false;
            }
            return true;
        }
    }
}
</script>

<style>

/* 组件创建者容器 */
.ComponentCreater {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin: auto;
    width: 60%;
    position: relative;
    padding: 20px;
}

/* 选择器和输入框的容器样式 */
.ComponentCreater .newComponent_affiliation_id,
.ComponentCreater .newComponent_priority,
.ComponentCreater .newComponent_type,
.ComponentCreater .newComponent_title,
.ComponentCreater .newComponent_text,
.ComponentCreater .newComponent_file {
    margin-bottom: 1em; /* 相对间距 */
    position: relative;
}

/* 选择器和输入框的标签样式 */
.ComponentCreater .newComponent_affiliation_id label,
.ComponentCreater .newComponent_priority label,
.ComponentCreater .newComponent_type label,
.ComponentCreater .newComponent_title label {
    display: block;
    margin-bottom: 0.5em;
}

/* 按钮样式 */
.ComponentCreater .el-button {
    color: #fff;
    background-color: #ff4949;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 1em; /* 按钮与输入框的间距 */
}

/* 按钮悬浮效果 */
.ComponentCreater .el-button:hover {
    background-color: #e03131;
}

/* 文件上传按钮样式 */
.ComponentCreater .NormalBtn {
    margin-top: 0.5em;
    cursor: pointer;
}


</style>