//前后端服务器地址-全局变量
// Vue.prototype.$hdAddress = 'http://localhost:8002/ofweb_war_exploded';
// Vue.prototype.$qdAddress = 'http://localhost:8003';
Vue.prototype.$hdAddress = 'https://new1.14294.com';
Vue.prototype.$qdAddress = 'https://new.14294.com';


//全局函数

Vue.prototype.$getImg = function (img_type) {
  let rsp = []
  axios({
      method: 'post',
      url: this.$hdAddress + "/getImg",
      params: {
        img_type: img_type
      },
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    .then(res => {
      // 处理成功的响应
      res.data.forEach(item => {
        rsp.push(this.$hdAddress + item)
      });
    })
    .catch(() => {
      // 处理错误情况
    });
  return rsp
};
Vue.prototype.$getText = function (type) {
  let rsp = []
  axios({
      method: 'post',
      url: this.$hdAddress + "/selectTextsByType",
      params: {
        type: type
      },
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    .then(res => {
      // 处理成功的响应
      res.data.forEach(item => {
        rsp.push(item)
      });
    })
    .catch(() => {
      // 处理错误情况
    });
  return rsp
};


import Vue from 'vue'
import App from './App.vue'

//页面导入
import MyIndex from './views/MyIndex.vue';
import PE from './views/PE.vue';
import AboutUs from './views/AboutUs.vue';
import NewsDetail from './views/NewsDetail.vue';
import NewsAndInformation from './views/NewsAndInformation.vue';
import MManager from './views/manager/MManager.vue';
import PromotionPlan from './views/PromotionPlan.vue';
import SettledEnterprise from './views/SettledEnterprise.vue';
import GameAgent from './views/GameAgent.vue';
import GameDetail from './views/GameDetail.vue';
import GameList from './views/GameList.vue';
//引入404页
import PageNotFound from './views/PageNotFound.vue';
//引入管理员页
import MLogin from './views/manager/MLogin.vue';


import VueRouter from 'vue-router'
Vue.use(VueRouter)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//手机端管理员页面组件注册
import PEComponentCreator from './components/Manager/PEComponentCreator.vue';
Vue.component('PEComponentCreator', PEComponentCreator)
import PEComponentChanger from './components/Manager/PEComponentChanger.vue';
Vue.component('PEComponentChanger', PEComponentChanger)
import PEPageCreator from './components/Manager/PEPageCreator.vue';
Vue.component('PEPageCreator', PEPageCreator)
import PEPageChanger from './components/Manager/PEPageChanger.vue';
Vue.component('PEPageChanger', PEPageChanger)

//手机端组件注册
import PEHeader from './PEComponents/PEHeader.vue';
Vue.component('PEHeader', PEHeader)
import PEC_jumper from './PEComponents/PEC_jumper.vue';
Vue.component('PEC_jumper', PEC_jumper)
import PEC_Mform from './PEComponents/PEC_Mform.vue';
Vue.component('PEC_Mform', PEC_Mform)
import PE_gameListComponent from './PEComponents/PE_gameListComponent.vue';
Vue.component('PE_gameListComponent', PE_gameListComponent)
import PEC_gameSmallCard from './PEComponents/PEC_gameSmallCard.vue';
Vue.component('PEC_gameSmallCard', PEC_gameSmallCard)
import PEC_card2 from './PEComponents/PEC_card2.vue';
Vue.component('PEC_card2', PEC_card2)
import PEC_card1 from './PEComponents/PEC_card1.vue';
Vue.component('PEC_card1', PEC_card1)
import PEC_lunBo1 from './PEComponents/PEC_lunBo1.vue';
Vue.component('PEC_lunBo1', PEC_lunBo1)
import PEC_lunBo2 from './PEComponents/PEC_lunBo2.vue';
Vue.component('PEC_lunBo2', PEC_lunBo2)
import PEC_lunBo3 from './PEComponents/PEC_lunBo3.vue';
Vue.component('PEC_lunBo3', PEC_lunBo3)
import PEC_img2 from './PEComponents/PEC_img2.vue';
Vue.component('PEC_img2', PEC_img2)
import PEC_paratitle from './PEComponents/PEC_paratitle.vue';
Vue.component('PEC_paratitle', PEC_paratitle)
import PEC_text from './PEComponents/PEC_text.vue';
Vue.component('PEC_text', PEC_text)
import PEC_img1 from './PEComponents/PEC_img1.vue';
Vue.component('PEC_img1', PEC_img1)
import PEC_title from './PEComponents/PEC_title.vue';
Vue.component('PEC_title', PEC_title)

//电脑端组件注册
import PageHeader from './components/PageHeader.vue';
Vue.component('PageHeader', PageHeader)
import GameListComponent from './components/GameListComponent.vue';
Vue.component('GameListComponent', GameListComponent)
import ContactUs from './components/ContactUs.vue';
Vue.component('ContactUs', ContactUs)
import OperationFlow from './components/OperationFlow.vue';
Vue.component('OperationFlow', OperationFlow)
import MForm from './components/MForm.vue';
Vue.component('MForm', MForm)
import TW1 from './components/TW1.vue';
Vue.component('TW1', TW1)
import ServiceContent from './components/ServiceContent.vue';
Vue.component('ServiceContent', ServiceContent)
import TW2 from './components/TW2.vue';
Vue.component('TW2', TW2)
import TopImg from './components/TopImg.vue';
Vue.component('TopImg', TopImg)
import TopImg2 from './components/TopImg2.vue';
Vue.component('TopImg2', TopImg2)
import showBox from './components/showBox.vue';
Vue.component('showBox', showBox)
import NormalImg1 from './components/NormalImg1.vue';
Vue.component('NormalImg1', NormalImg1)
import NormalText1 from './components/NormalText1.vue';
Vue.component('NormalText1', NormalText1)
import MyIndex_box1_box1_inner from './components/MyIndex_box1_box1_inner.vue';
Vue.component('MyIndex_box1_box1_inner', MyIndex_box1_box1_inner)
import PageFooter from './components/PageFooter.vue';
Vue.component('PageFooter', PageFooter)
import IndexCarousel from './components/IndexCarousel.vue';
Vue.component('IndexCarousel', IndexCarousel)
import MyIndex_box2_inner from './components/MyIndex_box2_inner.vue';
Vue.component('MyIndex_box2_inner', MyIndex_box2_inner)
import MyIndex_box3_inner from './components/MyIndex_box3_inner.vue';
Vue.component('MyIndex_box3_inner', MyIndex_box3_inner)
import MyIndex_box4_inner from './components/MyIndex_box4_inner.vue';
Vue.component('MyIndex_box4_inner', MyIndex_box4_inner)



//管理页组件注册
import ServicesOptionsCreator from './components/Manager/ServicesOptionsCreator.vue';
Vue.component('ServicesOptionsCreator', ServicesOptionsCreator)
import ServicesOptionsChanger from './components/Manager/ServicesOptionsChanger.vue';
Vue.component('ServicesOptionsChanger', ServicesOptionsChanger)
import CustomerServer from './components/Manager/CustomerServer.vue';
Vue.component('CustomerServer', CustomerServer)
import NewsChanger from './components/Manager/NewsChanger.vue';
Vue.component('NewsChanger', NewsChanger)
import NewsCreator from './components/Manager/NewsCreator.vue';
Vue.component('NewsCreator', NewsCreator)
import TextChanger from './components/Manager/TextChanger.vue';
Vue.component('TextChanger', TextChanger)
import ImgChanger from './components/Manager/ImgChanger.vue';
Vue.component('ImgChanger', ImgChanger)
import CollaborationChanger from './components/Manager/CollaborationChanger.vue';
Vue.component('CollaborationChanger', CollaborationChanger)
import pageCreator from './components/Manager/pageCreator.vue';
Vue.component('pageCreator', pageCreator)
import ComponentCreater from './components/Manager/ComponentCreater.vue';
Vue.component('ComponentCreater', ComponentCreater)
import ComponentChanger from './components/Manager/ComponentChanger.vue';
Vue.component('ComponentChanger', ComponentChanger)
import PageDescriptionManager from './components/Manager/PageDescriptionManager.vue';
Vue.component('PageDescriptionManager', PageDescriptionManager)




//AboutUs页面组件注册
import AboutUs_box1_inner from './components/AboutUs/AboutUs_box1_inner.vue';
Vue.component('AboutUs_box1_inner', AboutUs_box1_inner)
import AboutUs_box2_inner from './components/AboutUs/AboutUs_box2_inner.vue';
Vue.component('AboutUs_box2_inner', AboutUs_box2_inner)
import AboutUs_box3_inner from './components/AboutUs/AboutUs_box3_inner.vue';
Vue.component('AboutUs_box3_inner', AboutUs_box3_inner)
import AboutUs_box4_inner from './components/AboutUs/AboutUs_box4_inner.vue';
Vue.component('AboutUs_box4_inner', AboutUs_box4_inner)
import AboutUs_box5_inner from './components/AboutUs/AboutUs_box5_inner.vue';
Vue.component('AboutUs_box5_inner', AboutUs_box5_inner)





//ContactUs页面组件注册
import ContactUs_box1_inner from './components/ContactUs/ContactUs_box1_inner.vue';
Vue.component('ContactUs_box1_inner', ContactUs_box1_inner)
import ContactUs_box2_inner from './components/ContactUs/ContactUs_box2_inner.vue';
Vue.component('ContactUs_box2_inner', ContactUs_box2_inner)
import ContactUs_box3_inner from './components/ContactUs/ContactUs_box3_inner.vue';
Vue.component('ContactUs_box3_inner', ContactUs_box3_inner)




//GameDetail页面组件注册
import GameDetail_box1 from './components/GameDetail/GameDetail_box1.vue';
Vue.component('GameDetail_box1', GameDetail_box1)
import GameDetail_box2 from './components/GameDetail/GameDetail_box2.vue';
import axios from 'axios';
Vue.component('GameDetail_box2', GameDetail_box2)





//路由配置
const router = new VueRouter({
  mode: 'history',
  routes: [{
      path: '/',
      component: MyIndex,
      meta: {
        title: '风林首页'
      },
    },
    {
      path: '/PE',
      component: PE,
      meta: {
        title: '风林手机端'
      },
    },
    {
      path: '/NewsDetail',
      component: NewsDetail,
      meta: {
        title: '新闻详情'
      },
    },
    {
      path: '/NewsAndInformation',
      component: NewsAndInformation,
      meta: {
        title: '新闻列表'
      },
    },
    {
      path: '/AboutUs',
      component: AboutUs,
      meta: {
        title: '关于我们'
      },
    },
    {
      path: '/SettledEnterprise',
      component: SettledEnterprise,
      meta: {
        title: '代理加盟'
      },
    },
    {
      path: '/GameAgent',
      component: GameAgent,
      meta: {
        title: '厂家入驻'
      },
    },
    {
      path: '/GameDetail',
      component: GameDetail,
      meta: {
        title: '游戏详情'
      },
    },
    {
      path: '/GameList',
      component: GameList,
      meta: {
        title: '游戏列表'
      },
    },
    {
      path: '/MManager',
      component: MManager,
      meta: {
        title: '后台管理'
      },
    },
    {
      path: '/PromotionPlan',
      component: PromotionPlan,
      meta: {
        title: '推广方案'
      },
    },
    // {
    //   path:'/ComponentCreater',
    //   component:ComponentCreater,
    //   meta:{
    //     title:'首页'
    //   },
    // },
    {
      path: '/PageNotFound',
      component: PageNotFound,
      meta: {
        title: '完蛋，页面弄丢了 404 No Found'
      },
    },
    {
      path: '/MLogin',
      component: MLogin,
      meta: {
        title: '管理页登录'
      },
    },
    {
      path: '*',
      redirect: '/PageNotFound'
    }
  ]
})

//路由守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '默认标题';
  axios({
    url: Vue.prototype.$hdAddress + "/selectPageDescriptionByPage_url",
    method: "post",
    params: {
      page_url: to.path /*这里要获取当前的页面路由*/
    }
  }).then(res => {
    if (res.data.title != null)
      document.title = res.data.title
    document.querySelector('meta[name="description"]').setAttribute('content', res.data.description);
    document.querySelector('meta[name="keywords"]').setAttribute('content', res.data.keywords);
  })
  next();
});




Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  mounted () {
    // document.dispatchEvent(new Event('custom-render-trigger'))
    
    document.dispatchEvent(new Event('render-event'));//加载完发布这个事件
 }
}).$mount('#app')




//站长统计
// 加入百度统计
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/' + to.fullPath])
    }
  }
  next()
})
