<template>
    <div class="PE_gameList">
      <div class="PE_gameList_small">
          <div v-if="msgs">
              <div class="PE_gameList_small_items" v-for="item in msgs" :key="item.name">
                      <a :href="`${$qdAddress}/GameDetail?name=`+item.name">
                      <div class="PE_gameList_small_items_img">
                          <img :src="item.icon" alt="">
                      </div>
                      <div class="PE_gameList_small_items_title">
                          {{ item.name }}
                      </div>
                  </a>
              </div>
          </div>
          </div>
    </div>
  </template>
  
  <script>
  export default {
  props:['msgs'],
  mounted(){
    this.observeElement();
  },
  watch:{
    msgs(){
        
    this.observeElement();
    }
  },
  methods:{
    observeElement() {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.animateIn();
                    }
                });
            });
            observer.observe(this.$el);
        },
        animateIn() {
            // 触发CSS过渡效果
            let 渐变元素 = this.$el.querySelectorAll('.PE_gameList_small_items');
            // 随机出现时间
            let currentDelay = 50 + 50 * Math.random();

            渐变元素.forEach((item) => {
                // 设置延迟时间，每次迭代增加0.4秒
                setTimeout(() => {
                    item.style.opacity = '1';
                    item.style.transform = 'translateY(0)';
                }, currentDelay);

                // 更新延迟时间，为下一次迭代准备
                currentDelay += 50 + 50 * Math.random(); // 0.2秒转换为毫秒
            });
        }
  }
  }
  </script>
  
  <style>
/* 移动端游戏列表容器样式 */
.PE_gameList {
    padding: 0;
    margin: 0;
    background-color: #f8f8f8;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* 移动端游戏列表项样式 */
.PE_gameList_small_items {
    float: left;
    width: calc(25% - 2em);
    margin: 1em;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.5s ease;
    opacity: 0;
    transform: translateY(-2em);
}

/* 移动端游戏列表项图片容器样式 */
.PE_gameList_small_items_img {
    width: 100%;
    height: 0;
    padding-bottom: 100%; /* 保持图片比例 */
    position: relative;
}

/* 移动端游戏列表项图片样式 */
.PE_gameList_small_items_img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* 移动端游戏列表项标题样式 */
.PE_gameList_small_items_title {
    display: block;
    padding: 5px;
    font-size: 1rem;
    text-align: center;
    color: #333;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


/* 响应式调整：当屏幕宽度小于100px时，每排显示一个游戏 */
@media (max-width: 100px) {
    .PE_gameList_small_items {
        width: 100%;
    }
}
  </style>