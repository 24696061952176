<template>
  <div class="form-container">




    <!-- 代理加盟 -->
    <div v-if="formType == 0">
      <div class="indexTitle">咨询合作</div>
      <form @submit.prevent="submitForm">
        <!-- 个人/团队选择 -->
        <div class="form-group">
          <label for="type">个人/团队：</label>
          <div class="selection-group">
            <span v-for="item in typeOptions" :key="item.label" class="selection-button"
              :class="{ 'active': userInf.type === item.label }" @click="toggleSelection(item.label)">
              {{ item.label }}
            </span>
          </div>
        </div>
        <!-- 姓名输入 -->
        <div class="form-group">
          <label for="name">姓名：</label>
          <input type="text" id="name" v-model="userInf.name" class="input-field" />
        </div>
        <!-- 电话输入框 -->
        <div class="form-group">
          <label for="phone">电话（必填项）：</label>
          <input type="text" id="phone" v-model="userInf.phone" class="input-field"
            :class="{ 'error': validatePhoneInstantly }" @blur="validatePhone" />
          <!-- 手机号错误提示信息 -->
          <div v-if="phoneError" class="error-message">{{ phoneError }}</div>
        </div>
        <!-- 服务选择 -->
        <div class="form-group">
          <label>选择您需要的服务：</label>
          <div class="services-group">
            <span v-for="service in servicesOptions" :key="service.label" class="service-button"
              :class="{ 'active': userInf.services.includes(service.label) }"
              @click="toggleServiceSelection(service.label)">
              {{ service.label }}
            </span>
          </div>
        </div>
        <!-- 提交按钮 -->
        <button type="submit" class="submit-button" :disabled="isFormDisabled">提交</button>
        <!-- 提示信息 -->
        <div class="info-text">留下联系方式后，官方商务会在24小时内主动联系您。</div>
      </form>
    </div>






    <!-- 厂商入驻 -->
    <div v-if="formType == 1">
      <div class="indexTitle">厂家/发行入驻</div>
      <form @submit.prevent="submitForm">
        <!-- 公司名称输入 -->
        <div class="form-group">
          <label for="name">公司名称：</label>
          <input type="text" id="name" v-model="userInf.name" class="input-field" />
        </div>
        <!-- 电话输入框 -->
        <div class="form-group">
          <label for="phone">电话（必填项）：</label>
          <input type="text" id="phone" v-model="userInf.phone" class="input-field"
            :class="{ 'error': validatePhoneInstantly }" @blur="validatePhone" />
          <!-- 手机号错误提示信息 -->
          <div v-if="phoneError" class="error-message">{{ phoneError }}</div>
        </div>
        <!-- 服务选择 -->
        <div class="form-group">
          <label for="name">所提供产品（提供部分即可）：</label>
          <input type="text" id="name" v-model="userInf.notes" class="input-field" />
        </div>
        <!-- 提交按钮 -->
        <button type="submit" class="submit-button" :disabled="isFormDisabled">提交</button>
        <!-- 提示信息 -->
        <div class="info-text">留下联系方式后，官方商务会在24小时内主动联系您。</div>
      </form>
    </div>














  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['formType'],
  data() {
    return {
      userInf: {
        type: '个人', // 默认选择个人
        name: '',
        phone: '',
        notes: '',
        services: [],
        replyed: '',
        update_time: '',
        id: '',
        instruction:'',
        isCompany:false
      },
      typeOptions: [
        { label: '个人' },
        { label: '团队' },
      ],
      servicesOptions: [
        { label: '未加载出选项' },
      ],
      phoneError: null,
      lastSubmitTime: null, // 记录上次提交的时间
      ableSubmit: true, // 不在冷却期
    };
  },
  computed: {
    validatePhoneInstantly() {
      return this.userInf.phone && this.userInf.phone.length !== 11;
    },
    // 表单是否可提交（考虑电话错误和冷却时间）
    isFormDisabled() {
      return this.phoneError || !this.ableSubmit;
    },
  },
  mounted() {
    this.selectLabel()
  },
  methods: {
    selectLabel() {
      axios({
        method: "get",
        url: this.$hdAddress + '/selectLabel'
      }).then(res => {
        this.servicesOptions = res.data
      }).catch(e => {
        console.log("可恶，请求失败了")
        console.log("错误信息" + e)
      })
    },
    insertCollaborationForm() {
      // this.userInf.services = this.userInf.services.join(',');
      this.userInf.isCompany=this.formType;
      // console.log(this.userInf);
      
      let msg = JSON.parse(JSON.stringify(this.userInf))
      msg.services = msg.services.join(',');
      axios({
        url: this.$hdAddress + '/insertCollaborationForm',
        method: 'post',
        data: msg,
        headers: {
          'Content-Type': 'application/json',// 设置请求头，告知服务器发送的是JSON数据
        }
      }).then(res => {
        if (res.data == 1)
          this.$notify({
            title: '提交成功',
            message: '我们将尽快联系您',
            type: 'success'
          });
        else {
          this.$notify.error({
            title: '提交失败',
            message: '表单内容异常'
          });
        }
      }).catch(e => {
        this.$notify.error({
          title: '提交失败',
          message: '请检查您的网络状态'
        });
        console.log(e);
      })
    },
    toggleSelection(type) {
      this.userInf.type = type;
    },
    toggleServiceSelection(service) {
      const index = this.userInf.services.indexOf(service);
      if (index > -1) {
        this.userInf.services.splice(index, 1);
      } else {
        this.userInf.services.push(service);
      }
    },

    // 验证电话号是否为11位数字
    validatePhone() {
      if (this.userInf.phone && this.userInf.phone.length !== 11) {
        this.phoneError = '电话号码必须为11位数字';
      } else {
        this.phoneError = '';
      }
    },
    // 提交表单方法
    submitForm() {
      this.validatePhone(); // 验证手机号
      if (this.phoneError || this.isFormDisabled) return;

      if (!this.userInf.phone) {
        this.phoneError = '电话号码是必填项';
        return;
      }

      // 重置错误信息并开始冷却
      this.phoneError = '';
      this.ableSubmit = false;
      setTimeout(() => {
        this.ableSubmit = true;
      }, 5000);

      // 提交表单逻辑
      // 这里添加提交表单的逻辑，例如发送到服务器等
      // console.log(JSON.stringify(this.userInf));

      this.insertCollaborationForm();
    },
  },
};
</script>




<style scoped>
.form-container {
  width: 90%;
  /* 宽度为父元素宽度的90% */
  margin: 5% auto;
  /* 垂直外边距为父元素高度的5%，水平自动 */
  padding: 2%;
  /* 内边距为父元素宽度的2% */
  padding-top: 0;
  margin-top: 0;
  background: #fff;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 2em;
  /* 标题字体大小，这里使用em单位，相对于根元素字体大小 */
  text-align: center;
  margin-bottom: 1em;
  /* 相对于自身字体大小的1倍 */
  color: #333;
}

form {
  margin-top: 0;
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-top: 0;
  margin-bottom: 2%;
  /* 表单项之间的间距，相对于父元素宽度的2% */
}

label {
  margin-top: 0;
  display: block;
  margin-bottom: 0.5em;
  /* 相对于自身字体大小的0.5倍 */
  font-weight: bold;
  font-size: 1em;
  /* 相对于自身字体大小的1倍 */
  color: #555;
}

.input-field {
  width: 100%;
  /* 输入框宽度占父元素的100% */
  padding: 1%;
  /* 输入框内边距为父元素宽度的1% */
  border: 1px solid #ddd;
  border-radius: 0.25em;
  font-size: 1em;
  /* 输入框字体大小，相对于自身字体大小的1倍 */
  transition: border-color 0.3s;
}

.input-field:focus {
  border-color: #777;
}

.error {
  border-color: #e74c3c;
}

.error-message {
  margin-top: 1%;
  /* 相对于父元素宽度的1% */
  font-size: 0.9em;
  /* 相对于自身字体大小的0.9倍 */
  color: #e74c3c;
}

.selection-group,
.services-group {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  /* 项目间隙为父元素宽度的2% */
  margin-top: 1%;
}

.selection-button,
.service-button {
  padding: 1% 2%;
  /* 按钮内边距为父元素宽度的1%和2% */
  border: 1px solid #ddd;
  border-radius: 0.25em;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 1em;
  /* 按钮字体大小，相对于自身字体大小的1倍 */
}

.selection-button.active,
.service-button.active {
  background-color: #e74c3c;
  color: #fff;
  border-color: #e74c3c;
}

.submit-button {
  padding: 2% 5%;
  /* 提交按钮内边距为父元素宽度的2%和5% */
  margin-top: 2%;
  /* 相对于父元素宽度的2% */
  border: none;
  border-radius: 0.25em;
  background-color: #e74c3c;
  color: #fff;
  font-size: 1.2em;
  /* 提交按钮字体大小，相对于自身字体大小的1.2倍 */
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #ccc;
  color: #777;
  cursor: not-allowed;
}

.info-text {
  text-align: center;
  font-size: 0.9em;
  /* 相对于自身字体大小的0.9倍 */
  color: #666;
  margin-top: 2%;
  /* 相对于父元素宽度的2% */
}

/* 响应式设计 */
@media (max-width: 768px) {
  .form-container {
    width: 100%;
    /* 宽度为视口宽度的100% */
    padding: 5%;
    /* 内边距为父元素宽度的5% */
  }

  .form-title {
    font-size: 1.5em;
    /* 相对于根元素字体大小的1.5倍 */
  }

  .selection-button,
  .service-button {
    padding: 1%;
    /* 按钮内边距为父元素宽度的1% */
  }

  .submit-button {
    padding: 2%;
    /* 提交按钮内边距为父元素宽度的2% */
  }
}


.services-group {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* 使元素均匀分布在容器中 */
  margin-top: 1%;
}


.services-group .service-button {
  flex-basis: calc(33.333% - 1.5%);
  /* 每个元素占三分之一减去间隔 */
  max-width: calc(33.333% - 1.5%);
  /* 最大宽度与flex-basis一致 */
  padding: 1% 2%;
  margin-bottom: 2%;
  /* 垂直方向的间隔 */
  box-sizing: border-box;
  /* 边框计算在宽度内 */
  border: 1px solid #ddd;
  border-radius: 0.25em;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 1em;
}

/* 其他样式保持不变 */
</style>