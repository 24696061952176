<template>
    <div class="PEComponentCreator">
        <div class="indexTitle">添加手机端自定义组件</div>
        <div class="newComponent_parent">
            父页面
            <el-select v-model="newComponent.parent" placeholder="请选择父页面">
                <el-option v-for="item in myPages" :key="item" :label="item" :value="item">
                </el-option>
            </el-select>
        </div>


        <div class="newComponent_priority">
            优先级
            <el-input-number v-model="newComponent.priority" :min="1" :max="99999"></el-input-number>
            排序优先级，1为最低，随增加依次升高，越大的值代表会越靠前
        </div>
        <div class="newComponent_type">
            类型
            <el-select v-model="newComponent.type" placeholder="请选择">
                <el-option v-for="item in typesOptions" :key="item" :label="item" :value="item">
                </el-option>
            </el-select>
        </div>
        <div v-if="newComponent.type === '纯文本'
            || newComponent.type === '副标题'
            || newComponent.type === '标题'
            || newComponent.type === '卡片轮播图（文本里写高度数字）'
            || newComponent.type === '滑动轮播图（文本里写高度数字）'
            || newComponent.type === '图文轮播图（文本里写说明文本，每句之间以&分隔）'
            || newComponent.type === '高度可调图片（文本里写高度数字）'
            || newComponent.type === '图文卡片（二分之一宽）'
            || newComponent.type === '图文卡片（三分之一宽）'
            || newComponent.type === '跳转站内链接（文本里写路由）'
        " class="newComponent_text">
            文本
            <textarea style="resize: vertical;width: 100%;" v-model="newComponent.NText"
                @input="newComponent.text = NToB(newComponent.NText);"></textarea>
        </div>
        <div v-if="newComponent.type === '页头横图'
            || newComponent.type === '高度可调图片（文本里写高度数字）'
            || newComponent.type === '滑动轮播图（文本里写高度数字）'
            || newComponent.type === '图文轮播图（文本里写说明文本，每句之间以&分隔）'
            || newComponent.type === '卡片轮播图（文本里写高度数字）'
            || newComponent.type === '图文卡片（二分之一宽）'
            || newComponent.type === '图文卡片（三分之一宽）'
        " class="newComponent_file">
            图片
            <input class="NormalBtn" type="file" @change="handleFileChange">
            <div class="image-preview">
                <div v-for="(image, index) in imagesPreview" :key="index" class="image-item">
                    <img :src="image" alt="Image preview" />
                    <el-button type="text" @click="removeImage(index)">移除</el-button>
                </div>
            </div>
        </div>
        <el-button type="danger" @click="createNewComponent">新增</el-button>
    </div>
</template>

<script>
import axios from 'axios';
import Bus from '@/utils/EventBus';
export default {
    created() {
        Bus.$on('PEPageCreating', () => {
            this.getPages()
        })
    },
    data() {
        return {
            NText: '',
            myPages: [],
            newComponent: {
                parent: '',
                priority: null,
                type: '',
                note: '',
                text: ''
            },
            typesOptions: [ // 定义类型选择框的选项
                "标题",
                "副标题",
                "纯文本",
                "页头横图",
                "高度可调图片（文本里写高度数字）",
                "卡片轮播图（文本里写高度数字）",
                "滑动轮播图（文本里写高度数字）",
                "图文轮播图（文本里写说明文本，每句之间以&分隔）",
                "图文卡片（二分之一宽）",
                "图文卡片（三分之一宽）",
                "游戏列表卡片",
                "跳转站内链接（文本里写路由）",
                "留资表(代理加盟)",
                "留资表(厂商入驻)",
            ],
            file: [], // 添加文件变量
            imagesPreview: [], // 用于存储图片预览的URL数组
        }
    },
    mounted() {
        this.getPages()
    },
    methods: {
        NToB(nText) {
            return nText.replace(/\n/g, '<br>');
        },
        BToN(bText) {
            return bText.replace(/<br>/g, '\n');
        },
        handleFileChange(event) {
            let files = event.target.files;
            Array.from(files).forEach(file => {
                // 读取文件并将其URL添加到预览数组
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.imagesPreview.push(e.target.result);
                };
                reader.readAsDataURL(file);
                this.file.push(file); // 同时添加到文件数组
            });
        },
        removeImage(index) {
            // 从预览数组中移除图片
            this.imagesPreview.splice(index, 1);
            // 从文件数组中移除对应的文件
            this.file.splice(index, 1);
        },
        createNewComponent() {
            if (!this.ifOk()) return;
            // 创建 FormData 对象
            let formData = new FormData();

            // 将 JSON 对象转换为字符串并添加到 FormData，使用不同的字段名称，例如 'component'
            formData.append('component', new Blob([JSON.stringify(this.newComponent)], { type: 'application/json' }));

            // 遍历文件数组并添加到 FormData，字段名称使用 'file'
            this.file.forEach((file) => {
                formData.append('file', file);
            });

            axios.post(this.$hdAddress + '/insertPeCustomPageComponent', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    this.$notify({
                        title: '成功',
                        message: response.data,
                        type: 'success'
                    });
                    Bus.$emit('PEComponentCreating', '')
                    this.newComponent = {
                        parent:this.newComponent.parent,
                priority: this.newComponent.priority
                    }
                    this.file = []
                    this.imagesPreview = []
                })
                .catch(error => {
                    this.$notify.error({
                        title: '错误',
                        message: error
                    });
                });
        },

        //检查合法性
        ifOk() {

            // 检查是否选择了父组件
            if (!this.newComponent.parent) {
                this.$notify.error({
                    title: '错误',
                    message: '请选择父组件'
                });
                return false;
            }

            // 检查优先级是否选择
            if (!this.newComponent.priority) {
                this.$notify.error({
                    title: '错误',
                    message: '请选择优先级'
                });
                return false;
            }


            // 检查类型是否选择
            if (!this.newComponent.type) {
                this.$notify.error({
                    title: '错误',
                    message: '请选择类型'
                });
                return false;
            }

            return true;
        },
        handleTypeChange() {

        },
        getPages() {
            axios({
                method: 'get',
                url: this.$hdAddress + "/getAllPEPageTitle",
            })
                .then(response => {
                    this.myPages = response.data
                })
                .catch(error => {
                    this.$notify.error({
                        title: '错误',
                        message: '获取页面失败'
                    });
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
    }
}
</script>

<style>
.image-preview {
    margin-top: 20px;
}

.image-item {
    display: inline-block;
    margin-right: 10px;
    position: relative;
}

.image-item img {
    width: 100px;
    /* 或其他合适的大小 */
    height: auto;
    display: block;
}
</style>