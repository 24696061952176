import { render, staticRenderFns } from "./PEHeader.vue?vue&type=template&id=606e5770"
import script from "./PEHeader.vue?vue&type=script&lang=js"
export * from "./PEHeader.vue?vue&type=script&lang=js"
import style0 from "./PEHeader.vue?vue&type=style&index=0&id=606e5770&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports