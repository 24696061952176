<template>
    <div id="GameList">
        <div class="GameList_PC" v-if="!_isMobile">
            <TopImg :imgUrl="FImgUrl1[0]"></TopImg>
            <div class="indexTitle">{{ this.pingTai }}游戏列表</div>
            <div class="GameList_tips">
                部分游戏列表，具体产品表以风林官网为准。获取完整游戏列表请联系商务申请合作。
            </div>
            <GameListComponent :msgs="this.msgs"></GameListComponent>
            <!-- 下方页码跳转 -->
            <div class="pageChooser">
                <div class="pageChooser_inner">
                    <button :disabled="isFirstPage" class="pageButton pageChooser_inner_inner"
                        @click="this.pageBefore">上一页</button>
                    <div class="pageNumber pageChooser_inner_inner">当前页码:{{ this.pageNumber }}</div>
                    <button :disabled="isEndPage" id="pageChooser_inner_inner_next"
                        class="pageButton pageChooser_inner_inner" @click="this.pageNext">下一页</button>
                </div>
            </div>
        </div>


        <!-- 手机端 -->
        <div class="GameList_PE" v-else>
            <PEC_img2 :imgUrl="FImgUrl1[0]" height="8"></PEC_img2>
            <div class="GameList_PE_title">{{ this.pingTai }}游戏列表</div>
            <div class="GameList_PE_tips">
                部分游戏列表，具体产品表以风林官网为准。获取完整游戏列表请联系商务申请合作。
            </div>
            <PE_gameListComponent :msgs="this.msgs"></PE_gameListComponent>
            <!-- 下方页码跳转 -->
            <div class="PE_pageChooser">
                <div class="PE_pageChooser_inner">
                    <button :disabled="isFirstPage" class="PE_pageButton PE_pageChooser_inner_inner"
                        @click="this.pageBefore">上一页</button>
                    <div class="PE_pageNumber PE_pageChooser_inner_inner">当前页码:{{ this.pageNumber }}</div>
                    <button :disabled="isEndPage" id="pageChooser_inner_inner_next"
                        class="PE_pageButton PE_pageChooser_inner_inner" @click="this.pageNext">下一页</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Bus from '@/utils/EventBus';
import axios from 'axios';
export default {
    data() {
        return {
            msgs: {},
            FImgUrl1: [],
        }
    },
    mounted() {
        this.FImgUrl1 = this.$getImg("游戏列表大图")
        this.getMsg()
        Bus.$emit('GameListChose', '')
        document.title="游戏列表 " + this.pingTai + "  -风林手游 手游加盟 游戏投资新平台"
    },
    methods: {
        getMsg() {
            if (this.pageNumber > 0)
                axios({
                    method: 'post',
                    url: this.$hdAddress + '/selectGameList',
                    headers: {
                        "Content-Type": "application/json", // 设置请求头，告知服务器发送的是JSON数据
                    },
                    data: {
                        pageNumber: this.pageNumber,
                        size: 63,
                        pingTai: this.pingTai
                    }
                }).then(res => {
                    // console.log(res)
                    this.msgs = res.data
                    if (this.msgs.length == 0)
                        this.pageBefore()
                }).catch(e => {
                    console.error(e)
                    this.pageInit()
                })
            else {
                this.pageInit()
            }
        },
        pageInit() {
            let url = this.$route.path + "?pageNumber=" + 1 + "&pingTai=安卓";
            this.$router.push({ path: url }); // 使用 replace 避免后退到 MyInf
            this.$router.go(0);
        },
        pageNext() {
            let nextPage = this.pageNumber + 1;
            this.jump(nextPage)
        },
        pageBefore() {
            if (this.pageNumber != 1) {
                let nextPage = this.pageNumber - 1;
                if (nextPage < 1)
                    nextPage = 1;
                this.jump(nextPage)
            }
        },
        jump(nextPage) {
            let url = this.$route.path + "?pageNumber=" + nextPage + "&pingTai=" + this.pingTai;
            this.$router.push({ path: url }); // 使用 replace 避免后退到 MyInf
            this.$router.go(0);
        },
    },
    computed: {
        _isMobile() {
            //判断是否为手机端
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        isFirstPage() {
            return this.pageNumber === 1;
        },
        isEndPage() {
            return this.msgs.length != 63;
        },
        pingTai() {
            // 使用正则表达式查找查询参数，如果匹配成功，解析查询参数并返回 'pingTai' 参数的值
            const regex = /(?:\?|&)pingTai=([^&]+)/;
            const match = window.location.href.match(regex);
            if (match) {
                return decodeURIComponent(match[1]); // 解码 URI 组件以避免编码问题
            }
            return "安卓"; // 如果没有找到 pingTai 参数，返回 null 或者其他默认值
        },
        pageNumber() {
            // 使用正则表达式查找查询参数，如果匹配成功，解析查询参数并返回 'pageNumber' 参数的值
            const regex = /(?:\?|&)pageNumber=([^&]+)/;
            const match = window.location.href.match(regex);
            if (match) {
                const pageNumber = parseInt(decodeURIComponent(match[1]), 10); // 解码并转换为整数
                if (!isNaN(pageNumber)) {
                    return pageNumber; // 返回有效的页码号
                }
            }
            return 1; // 如果没有找到或者页码号无效，返回默认页码号，例如1
        }
    },
}
</script>

<style>
.pageChooser {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -6em;
    /* 使用相对单位 */
    padding-bottom: 4em;
}

.pageChooser_inner {
    display: flex;
    gap: 0.5rem;
    /* 使用相对单位 */
}

.pageButton {
    padding: 0.5rem 1rem;
    /* 使用相对单位 */
    font-size: 1rem;
    cursor: pointer;
    background: #ff7e5f;
    color: white;
    transition: all 0.3s ease-in-out;
    /* 添加渐变动效 */
    border: none;
    /* 取消边框 */
    border-radius: 0.25rem;
    /* 使用相对单位 */
    margin: 0 0.25rem;
    /* 使用相对单位 */
}

.pageButton:disabled {
    background: #ccc;
    /* 禁用时的背景颜色 */
    cursor: not-allowed;
    /* 禁用时鼠标悬停时的光标样式 */
}

.pageButton:hover {
    background: #feb47b;
}

.pageNumber {
    padding: 0.5rem 1rem;
    /* 使用相对单位 */
    font-size: 1rem;
    text-align: center;
    background: none;
    color: #333;
    border: none;
    /* 取消边框 */
    margin: 0 0.25rem;
    /* 使用相对单位 */
}

/* 响应式设计 */
@media (max-width: 768px) {
    .pageChooser_inner {
        flex-direction: column;
        align-items: center;
    }

    .pageButton,
    .pageNumber {
        width: 80%;
        /* 使用百分比宽度 */
        margin-bottom: 0.5rem;
        /* 使用相对单位 */
    }
}

.GameList_tips {
    font-size: 0.9rem;
    /* 相对单位 */
    color: #666;
    /* 文字颜色 */
    text-align: center;
    /* 文本居中 */
    margin-top: 2rem;
    /* 与上方内容的间距，使用相对单位 */
    padding: 1rem;
    /* 内边距，使用相对单位 */
    background-color: #fff;
    /* 背景颜色 */
    border-radius: 0.5rem;
    /* 边框圆角，使用相对单位 */
}

.GameList_tips a {
    color: #ff7e5f;
    /* 链接颜色 */
    text-decoration: none;
    /* 去除下划线 */
    font-weight: bold;
    /* 加粗字体 */
    transition: color 0.3s ease;
    /* 颜色变化的过渡效果 */
}

.GameList_tips a:hover {
    color: #feb47b;
    /* 鼠标悬停时的链接颜色 */
}














/* 移动端专有的 GameList_PE 组件样式 */
/* 移动端游戏列表容器样式 */
.GameList_PE {
    display: block;
    width: 100%;
    padding: 0;
    padding-top: 3em;
    margin: 0;
    background-color: #f8f8f8;
    font-family: 'Arial', sans-serif;
}

/* 移动端游戏列表标题样式 */
.GameList_PE_title {
    font-size: 1.2rem;
    color: #333;
    text-align: center;
    margin-bottom: 10px;
}

/* 移动端游戏列表提示信息样式 */
.GameList_PE_tips {
    font-size: 0.9rem;
    color: #666;
    text-align: center;
    margin-bottom: 15px;
}

/* 移动端页码跳转容器样式 */
.PE_pageChooser {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    padding-bottom: 2em;
}

/* 移动端页码跳转内部容器样式 */
.PE_pageChooser_inner {
    display: flex;
    gap: 10px;
}

/* 移动端页码跳转按钮样式 */
.PE_pageButton {
    padding: 5px 10px;
    font-size: 0.9rem;
    color: #fff;
    background-color: #ff7300;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* 移动端页码跳转按钮禁用样式 */
.PE_pageButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* 移动端当前页码显示样式 */
.PE_pageNumber {
    font-size: 0.9rem;
    color: #333;
}
</style>