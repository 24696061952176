<template>
  <div>
    <!-- 导航 -->
    <el-tabs tab-position="left" style="height: auto;min-height: 600px;">
      <el-tab-pane label="用户表单管理">
        <!-- 用户表单管理 -->
        <CollaborationChanger></CollaborationChanger>
      </el-tab-pane>
      <el-tab-pane label="客服电话管理">
        <!-- 客服电话管理 -->
        <CustomerServer></CustomerServer>
      </el-tab-pane>
      <el-tab-pane label="页签标签管理">
        <!-- 页签标签管理 -->
        <PageDescriptionManager></PageDescriptionManager>
      </el-tab-pane>
      <el-tab-pane label="文字内容管理">
        <!-- 文字内容管理 -->
        <TextChanger></TextChanger>
      </el-tab-pane>
      <el-tab-pane label="图片管理">
        <!-- 图片管理 -->
        <ImgChanger></ImgChanger>
      </el-tab-pane>
      <el-tab-pane label="新闻管理">
        <!-- 新闻管理 -->
        <NewsCreator></NewsCreator>
        <NewsChanger></NewsChanger>
      </el-tab-pane>
      <el-tab-pane label="PE自定义页面">
        <!-- PE自定义页面 -->
        <PEPageCreator></PEPageCreator>
        <PEPageChanger></PEPageChanger>
      </el-tab-pane>
      <el-tab-pane label="PE自定义组件">
        <!-- PE自定义组件 -->
        <PEComponentCreator></PEComponentCreator>
        <PEComponentChanger></PEComponentChanger>
      </el-tab-pane>
      <el-tab-pane label="PC自定义页面">
        <!-- 自定义页面管理 -->
        <pageCreator></pageCreator>
      </el-tab-pane>
      <el-tab-pane label="PC自定义组件">
        <!-- 自定义组件 -->
        <ComponentCreater></ComponentCreater>
        <ComponentChanger></ComponentChanger>
      </el-tab-pane>
      <el-tab-pane label="合作表单选项卡">
        <!-- 合作表单选项卡 -->
        <ServicesOptionsCreator></ServicesOptionsCreator>
        <ServicesOptionsChanger></ServicesOptionsChanger>
      </el-tab-pane>
    </el-tabs>


    

  </div>
</template>

<script>
import axios from 'axios';


export default {
  name: 'AutoBrComponent',
  created() {
    this.ifToken()
  },
  methods: {
    ifToken() {
      let token = localStorage.getItem("token")
      axios({
        method: "get",
        url: this.$hdAddress + "/ifToken",
        headers: {
          token: token
        }
      }).then(res => {
        if (res.data) {
          this.$notify({
            title: '登陆成功',
            message: '欢迎回来',
            type: 'success'
          });
        } else {
          this.$router.push('/MLogin');
        }

      }).catch(() => {
        this.$notify.error({
          title: '服务器请求异常',
          message: '请联系技术人员进行修复，确认token时出现异常'
        });
      })
    },
  },
}
</script>

<style></style>