<template>
    <div id="MyIndex_box4_inner">

        <div id="MyIndex_box4_inner_small">
            <div class="MyIndex_box4_inner_small_items" v-for="item in msgs" :key="item.name">
                <a :href="`${$qdAddress}/#/GameDetail?name=` + item.name">
                    <div class="MyIndex_box4_inner_small_items_img">
                        <img :src="item.icon" alt="">
                    </div>
                    <div class="MyIndex_box4_inner_small_items_title">
                        {{ item.name }}
                    </div>
                </a>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            msgs: []
        }
    },
    mounted() {
        this.getMsg()
    },
    methods: {
        getMsg() {
            axios({
                method: 'get',
                url: this.$hdAddress + '/selectMyIndexGameShower2',
            }).then(res => {
                this.msgs = res.data
            })
        },
    }
}
</script>


<style>
#MyIndex_box4_inner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-left: 20%;
    padding-right: 20%;
}

#MyIndex_box4_inner_small {
    overflow: hidden;
    width: 60%;
    height: 100%;
    display: block;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.MyIndex_box4_inner_small_items {
    margin: 1em;
    float: left;
    margin-left: 2em;
    /* 间距 */
    margin-right: 2em;
    /* 间距 */
    box-sizing: border-box;
    /* 边框计算在宽度内 */
    width: 6em;
    height: 6em;
}

.MyIndex_box4_inner_small_items_img {
    width: 100%;
    overflow: hidden;
}

.MyIndex_box4_inner_small_items_img img {
    width: 100%;
    display: block;
    /* 去除图片底部空白 */
}

.MyIndex_box4_inner_small_items_title {
    text-align: center;
    font-size: 0.8rem;
    /* 字体大小，根据需要调整 */
    color: #333;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>