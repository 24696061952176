<template>
  <div id="MLogin">
    <div class="MLogin_inner">
      <div class="MLogin_title">风林后台</div>
      <div class="MLogin_account">
        <el-input v-model="account" placeholder="请输入账号"></el-input>
      </div>
      <div class="MLogin_pwd">
        <el-input
          placeholder="请输入密码"
          v-model="pwd"
          show-password
        ></el-input>
      </div>
      <div class="MLogin_login">
        <el-button type="danger" round @click="login()">登陆</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      account: "",
      pwd: "",
    };
  },
  methods: {
    login() {
      axios({
        method: "post",
        url: this.$hdAddress + "/login",
        data: {
          account: this.account,
          pwd: this.pwd,
        },
      })
        .then((res) => {
          localStorage.setItem("token", res.data);
          this.$router.push("/MManager");
        })
        .catch(() => {
          this.$notify.error({
            title: "登陆失败",
            message: "请检查您的账号和密码",
          });
        });
    },
  },
};
</script>

<style scoped>
#MLogin {
  width: 100%; /* 容器宽度为100% */
  margin: 2em auto; /* 垂直外边距为2em，水平自动 */
  padding: 1em; /* 内边距为1em */
  box-sizing: border-box; /* 边框计算在宽度内 */
  max-width: 30em; /* 最大宽度为30em */
}

.MLogin_title {
  font-size: 1.5em; /* 字体大小为1.5em */
  text-align: center; /* 文本居中 */
  margin-bottom: 1em; /* 底部外边距为1em */
}

.MLogin_account,
.MLogin_pwd {
  width: 15%;
  margin: auto;
  margin-bottom: 0.5em; /* 底部外边距为0.5em */
}

.MLogin_login {
  margin-top: 1em; /* 顶部外边距为1em */
  text-align: center; /* 文本居中 */
}

.MLogin_login .el-button {
  padding: 0.5em 2em; /* 垂直内边距为0.5em，水平内边距为2em */
  font-size: 1em; /* 字体大小为1em */
}
</style>