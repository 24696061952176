<template>
  <div id="ServicesOptionsCreator">
      <div class="indexTitle">新增选项卡</div>
      <div class="NewsCreator_inner" style="width: 20em; margin: auto;text-align: center;">
          <el-input placeholder="选项卡名称" v-model="newLabel" clearable style="width: 15em;">
          </el-input>
          <!-- <div id="editor"></div> -->
          <el-button @click="add()" size="small">上传</el-button>
      </div>
  </div>
</template>

<script>
import Bus from '@/utils/EventBus';
import axios from 'axios';
export default {
  data() {
    return {
      newLabel:"",
    };
  },
  methods: {
    add(){
        axios({
            method:"post",
            url:this.$hdAddress + "/m/insertLabel",
            headers:{
                token:localStorage.getItem("token"),
            },
            params:{
                label:this.newLabel
            }
        }).then(()=>{
          this.$notify({
            title: "成功",
            message: "修改成功",
            type: "success",
          });
          Bus.$emit("ServicesOptionsCreating")
        }).catch((error) => {
          this.$notify.error({
            title: "错误",
            message: "执行失败",
          });
          console.error("Error:", error.response ? error.response.data : error);
        });
    }
  },
};
</script>

<style>
</style>