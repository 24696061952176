<template>
  <div class="PEC_text">
    <!-- 纯文本组件 -->
    <pre v-html="text">
    </pre>
  </div>
</template>

<script>
export default {
  props: ['text'],
  mounted() {
    this.observeElement();
  },
  methods: {
    observeElement() {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.animateIn();
          }
        });
      });
      observer.observe(this.$el);
    },
    animateIn() {
      // 触发CSS过渡效果
            // 触发CSS过渡效果
            let 渐变元素 = this.$el.querySelectorAll('pre');
            // 随机出现时间
            let currentDelay = 500+1000 * Math.random();

            渐变元素.forEach((item) => {
                // 设置延迟时间，每次迭代增加0.4秒
                setTimeout(() => {
                    item.style.opacity = '1';
                    item.style.transform = 'translateY(0)';
                }, currentDelay);

                // 更新延迟时间，为下一次迭代准备
                currentDelay += 500+300*Math.random(); // 0.2秒转换为毫秒
            });
    }
  }
}
</script>

<style>
.PEC_text {
  padding-left: 8%;
  padding-right: 8%;
  width: 100%-16%;
  display: block;
  height: auto;
}

.PEC_text pre {
  font-size: 1.1rem;
  width: 100%;
  white-space: pre-wrap;
  /* 定义CSS过渡效果 */
  opacity: 0; /* 开始时透明度为0 */
  transform: translateY(2em); /* 开始时向上移动 */
  transition: all 1s ease; /* 定义CSS过渡效果 */
}
</style>