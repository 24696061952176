<template>
  <div class="PageDescriptionManager">
    <div class="indexTitle">页签标签管理</div>

    <el-table
      class="PageDescriptionManager_belong"
      :data="pages"
      style="width: 100%"
    >
      <el-table-column prop="type" label="路由" width="200">
        <template slot-scope="scope">
          {{ scope.row.page_url }}
        </template>
      </el-table-column>

      <el-table-column prop="text" label="页面标题" width="200">
        <template slot-scope="scope">
          <textarea
            style="resize: vertical; width: 100%"
            v-model="scope.row.title"
          ></textarea>
        </template>
      </el-table-column>

<el-table-column prop="text" label="页面描述（字符数在120字符之内）" width="500">
  <template slot-scope="scope">
    <textarea
      style="resize: vertical; width: 100%"
      v-model="scope.row.description"
    ></textarea>
  </template>
</el-table-column>

<el-table-column prop="text" label="页面关键词（关键词之间使用英文逗号分隔）" width="500">
  <template slot-scope="scope">
    <textarea
      style="resize: vertical; width: 100%"
      v-model="scope.row.keywords"
    ></textarea>
  </template>
</el-table-column>

      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="change(scope.row)" type="text" size="small"
            >确认修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pages: [],
    };
  },
  mounted() {
    this.getPages();
  },
  methods: {
    getPages() {
      axios({
        method: "get",
        url: this.$hdAddress + "/m/getAllPageDescriptions",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.pages = res.data;
          console.log(res.data);
        })
        .catch(() => {
          this.$notify.error({
            title: "错误",
            message: "页面描述管理页加载失败",
          });
        });
    },
    change(page) {
      axios({
        method: "post",
        url: this.$hdAddress + "/m/updatePageDescription",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: page,
      })
        .then(() => {
          this.$notify({
            title: "成功",
            message: "修改成功",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "错误",
            message: "修改失败",
          });
        });
    },
  },
};
</script>

<style>
</style>