import { render, staticRenderFns } from "./MyIndex_box2_inner.vue?vue&type=template&id=4a9ed133"
import script from "./MyIndex_box2_inner.vue?vue&type=script&lang=js"
export * from "./MyIndex_box2_inner.vue?vue&type=script&lang=js"
import style0 from "./MyIndex_box2_inner.vue?vue&type=style&index=0&id=4a9ed133&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports