<template>
  <div id="AboutUs">
    <div id="AboutUs_box1">
        <!-- 顶部图片 -->
      <AboutUs_box1_inner></AboutUs_box1_inner>
    </div>
    <div id="AboutUs_box2" class="AboutUs_box">
      <div class="indexTitle">公司概况</div>
      <AboutUs_box2_inner></AboutUs_box2_inner>
    </div>
    <div id="AboutUs_box3" class="AboutUs_box">
      <div class="indexTitle">发展历程</div>
      <AboutUs_box3_inner></AboutUs_box3_inner>
    </div>
    <div id="AboutUs_box4" class="AboutUs_box">
      <div class="indexTitle">公司业务</div>
      <AboutUs_box4_inner></AboutUs_box4_inner>
    </div>
    <div id="AboutUs_box5">
      <div class="indexTitle">运营产品展示</div>
      <AboutUs_box5_inner></AboutUs_box5_inner>
    </div>

    <!-- 关于我们 -->
    <ContactUs></ContactUs>
  </div>
</template>

<script>
export default {

}
</script>

<style>
#AboutUs{
    width: 100%;
    /* display: block; */
}
.AboutUs_box{
    padding: 5em;
    padding-left: 10em;
    padding-right: 10em;
}
</style>