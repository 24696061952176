var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PEComponentCreator"},[_c('div',{staticClass:"indexTitle"},[_vm._v("添加手机端自定义组件")]),_c('div',{staticClass:"newComponent_parent"},[_vm._v(" 父页面 "),_c('el-select',{attrs:{"placeholder":"请选择父页面"},model:{value:(_vm.newComponent.parent),callback:function ($$v) {_vm.$set(_vm.newComponent, "parent", $$v)},expression:"newComponent.parent"}},_vm._l((_vm.myPages),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})}),1)],1),_c('div',{staticClass:"newComponent_priority"},[_vm._v(" 优先级 "),_c('el-input-number',{attrs:{"min":1,"max":99999},model:{value:(_vm.newComponent.priority),callback:function ($$v) {_vm.$set(_vm.newComponent, "priority", $$v)},expression:"newComponent.priority"}}),_vm._v(" 排序优先级，1为最低，随增加依次升高，越大的值代表会越靠前 ")],1),_c('div',{staticClass:"newComponent_type"},[_vm._v(" 类型 "),_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.newComponent.type),callback:function ($$v) {_vm.$set(_vm.newComponent, "type", $$v)},expression:"newComponent.type"}},_vm._l((_vm.typesOptions),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})}),1)],1),(_vm.newComponent.type === '纯文本'
        || _vm.newComponent.type === '副标题'
        || _vm.newComponent.type === '标题'
        || _vm.newComponent.type === '卡片轮播图（文本里写高度数字）'
        || _vm.newComponent.type === '滑动轮播图（文本里写高度数字）'
        || _vm.newComponent.type === '图文轮播图（文本里写说明文本，每句之间以&分隔）'
        || _vm.newComponent.type === '高度可调图片（文本里写高度数字）'
        || _vm.newComponent.type === '图文卡片（二分之一宽）'
        || _vm.newComponent.type === '图文卡片（三分之一宽）'
        || _vm.newComponent.type === '跳转站内链接（文本里写路由）'
    )?_c('div',{staticClass:"newComponent_text"},[_vm._v(" 文本 "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newComponent.NText),expression:"newComponent.NText"}],staticStyle:{"resize":"vertical","width":"100%"},domProps:{"value":(_vm.newComponent.NText)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.newComponent, "NText", $event.target.value)},function($event){_vm.newComponent.text = _vm.NToB(_vm.newComponent.NText);}]}})]):_vm._e(),(_vm.newComponent.type === '页头横图'
        || _vm.newComponent.type === '高度可调图片（文本里写高度数字）'
        || _vm.newComponent.type === '滑动轮播图（文本里写高度数字）'
        || _vm.newComponent.type === '图文轮播图（文本里写说明文本，每句之间以&分隔）'
        || _vm.newComponent.type === '卡片轮播图（文本里写高度数字）'
        || _vm.newComponent.type === '图文卡片（二分之一宽）'
        || _vm.newComponent.type === '图文卡片（三分之一宽）'
    )?_c('div',{staticClass:"newComponent_file"},[_vm._v(" 图片 "),_c('input',{staticClass:"NormalBtn",attrs:{"type":"file"},on:{"change":_vm.handleFileChange}}),_c('div',{staticClass:"image-preview"},_vm._l((_vm.imagesPreview),function(image,index){return _c('div',{key:index,staticClass:"image-item"},[_c('img',{attrs:{"src":image,"alt":"Image preview"}}),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.removeImage(index)}}},[_vm._v("移除")])],1)}),0)]):_vm._e(),_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.createNewComponent}},[_vm._v("新增")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }