var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PEComponentChanger"},[_c('div',{staticClass:"indexTitle"},[_vm._v("修改手机端自定义组件")]),_c('div',{staticClass:"newComponent_parent"},[_vm._v(" 父页面 "),_c('el-select',{attrs:{"placeholder":"请选择父页面"},on:{"change":function($event){return _vm.getComponents()}},model:{value:(_vm.thisPage),callback:function ($$v) {_vm.thisPage=$$v},expression:"thisPage"}},_vm._l((_vm.myPages),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})}),1)],1),_c('div',{staticClass:"pageCreator_createBox_item"},[_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.getComponents()}}},[_vm._v("刷新")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.pageItems}},[_c('el-table-column',{attrs:{"prop":"priority","label":"优先级","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input-number',{attrs:{"min":1,"max":99999},model:{value:(scope.row.priority),callback:function ($$v) {_vm.$set(scope.row, "priority", $$v)},expression:"scope.row.priority"}})]}}])}),_c('el-table-column',{attrs:{"prop":"type","label":"类型","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.type)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"text","label":"文本","width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row.type === '纯文本'
                || scope.row.type === '副标题'
                || scope.row.type === '卡片轮播图（文本里写高度数字）'
                || scope.row.type === '滑动轮播图（文本里写高度数字）'
                || scope.row.type === '图文轮播图（文本里写说明文本，每句之间以&分隔）'
                || scope.row.type === '标题'
                || scope.row.type === '高度可调图片（文本里写高度数字）'
                || scope.row.type === '图文卡片（二分之一宽）'
                || scope.row.type === '图文卡片（三分之一宽）'
                || scope.row.type === '跳转站内链接（文本里写路由）'
            )?[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(scope.row.NText),expression:"scope.row.NText"}],staticStyle:{"resize":"vertical","width":"100%"},domProps:{"value":(scope.row.NText)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(scope.row, "NText", $event.target.value)},function($event){scope.row.text = _vm.NToB(scope.row.NText);}]}})]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"prop":"img_url","label":"图片","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row.type === '页头横图'
                || scope.row.type === '整页大图'
                || scope.row.type === '高度可调图片（文本里写高度数字）'
                || scope.row.type === '滑动轮播图（文本里写高度数字）'
                || scope.row.type === '卡片轮播图（文本里写高度数字）'
                || scope.row.type === '图文轮播图（文本里写说明文本，每句之间以&分隔）'
                || scope.row.type === '图文卡片（二分之一宽）'
                || scope.row.type === '图文卡片（三分之一宽）'
                )?_vm._l((scope.row.img_urls),function(item){return _c('div',{key:item,staticClass:"PEComponentChanger_imgs"},[_c('img',{staticStyle:{"width":"10em"},attrs:{"src":_vm.$hdAddress + item,"alt":""}})])}):undefined}}],null,true)}),_c('el-table-column',{attrs:{"prop":"note","label":"备注（给管理员自己看的）","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(scope.row.note),expression:"scope.row.note"}],staticStyle:{"resize":"vertical","width":"100%"},domProps:{"value":(scope.row.note)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(scope.row, "note", $event.target.value)}}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.change(scope.row)}}},[_vm._v("确认修改")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.del(scope.row)}}},[_vm._v("删除")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }