<template>
    <div class="GameDetail_box2_inner">
        <div class="indexTitle">游戏截图</div>
        <el-carousel :interval="4000" type="card" height="50em">
            <el-carousel-item v-for="item in msgs.imgsList" :key="item">
                <img :src="item" alt="">
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>

export default {
    props: ['msgs']
}
</script>

<style>
.GameDetail_box2_inner{
    display: block;
    width: 70%;
    margin: 0;
    padding: 0;
    padding-left: 15%;
    margin-top: 1em;
}
.GameDetail_box2_inner .el-carousel__item img {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

.GameDetail_box2_inner .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf00;
}

.GameDetail_box2_inner .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce600;
}
</style>