<template>
  <div class="AboutUs_box2_inner_container">
    <div class="AboutUs_box2_inner_left">
      <!-- 联系方式 -->
      <pre>
        <div v-html="this.text1[0].text" ></div>
      </pre>
    </div>
    <div class="AboutUs_box2_inner_right">
      <img class="AboutUs_box2_inner_image" :src="this.FImgUrl1[0]" alt="联系方式">
    </div>
  </div>
</template>

<script>
export default {
  data(){
      return{
          FImgUrl1: [],
          text1:[],
      }
  },
  mounted(){
    this.FImgUrl1 = this.$getImg("关于我们联系我们1")
    this.text1 = this.$getText("关于我们联系我们文段1")
    
  },

}
</script>

<style>

pre {
  line-height: 1.5;
  color: #333;
    font-size: 1.5em; 
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
}
.AboutUs_box2_inner_container {
  padding: 0;
  padding-left: 10%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
}

.AboutUs_box2_inner_left {
  width: 60%;
  padding-right: 20px;
}

.AboutUs_box2_inner_right {
  width: 45%;
  text-align: center;
}

.AboutUs_box2_inner_image {
  max-width: 100%;
  height: auto;
  display: block; /* 确保图片不会超出容器宽度 */
  margin: 0 auto; /* 水平居中图片 */
}

</style>