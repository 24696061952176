<template>
    <div class="PEComponentChanger">

        <div class="indexTitle">修改手机端自定义组件</div>
        <div class="newComponent_parent">
            父页面
            <el-select v-model="thisPage" placeholder="请选择父页面" @change="getComponents()">
                <el-option v-for="item in myPages" :key="item" :label="item" :value="item">
                </el-option>
            </el-select>
        </div>

        <div class="pageCreator_createBox_item">
            <el-button type="danger" @click="getComponents()">刷新</el-button>
        </div>



        <el-table :data="pageItems" style="width: 100%">
            <el-table-column prop="priority" label="优先级" width="250">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.priority" :min="1" :max="99999"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="类型" width="120">
                <template slot-scope="scope">
                    {{ scope.row.type }}
                </template>
            </el-table-column>
            <el-table-column prop="text" label="文本" width="400">
                <template slot-scope="scope" v-if="scope.row.type === '纯文本'
                    || scope.row.type === '副标题'
                    || scope.row.type === '卡片轮播图（文本里写高度数字）'
                    || scope.row.type === '滑动轮播图（文本里写高度数字）'
                    || scope.row.type === '图文轮播图（文本里写说明文本，每句之间以&分隔）'
                    || scope.row.type === '标题'
                    || scope.row.type === '高度可调图片（文本里写高度数字）'
                    || scope.row.type === '图文卡片（二分之一宽）'
                    || scope.row.type === '图文卡片（三分之一宽）'
                    || scope.row.type === '跳转站内链接（文本里写路由）'
                ">
                    <textarea style="resize: vertical;width: 100%;" v-model="scope.row.NText"
                        @input="scope.row.text = NToB(scope.row.NText);"></textarea>
                </template>
            </el-table-column>
            <el-table-column prop="img_url" label="图片" width="150">
                <template slot-scope="scope" v-if="scope.row.type === '页头横图'
                    || scope.row.type === '整页大图'
                    || scope.row.type === '高度可调图片（文本里写高度数字）'
                    || scope.row.type === '滑动轮播图（文本里写高度数字）'
                    || scope.row.type === '卡片轮播图（文本里写高度数字）'
                    || scope.row.type === '图文轮播图（文本里写说明文本，每句之间以&分隔）'
                    || scope.row.type === '图文卡片（二分之一宽）'
                    || scope.row.type === '图文卡片（三分之一宽）'
                    ">
                    <div class="PEComponentChanger_imgs" v-for="item in scope.row.img_urls" :key="item">
                        <img style="width: 10em;" :src="$hdAddress + item" alt="">
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="note" label="备注（给管理员自己看的）" width="200">
                <template slot-scope="scope">
                    <textarea style="resize: vertical;width: 100%;" v-model="scope.row.note"></textarea>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button @click="change(scope.row)" type="text" size="small">确认修改</el-button>
                    <el-button @click="del(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>


    </div>
</template>

<script>
import Bus from '@/utils/EventBus';
import axios from 'axios';
export default {
    created() {
        Bus.$on('PEPageCreating', () => {
            this.getPages()
            this.getComponents()
        })
        Bus.$on('PEComponentCreating', () => {
            this.getPages()
            this.getComponents()
        })
    },
    mounted() {
        this.getPages()
    },
    data() {
        return {
            myPages: [],
            thisPage: '',
            pageItems: [],
        }
    },
    methods: {
        change(row) {
            axios({
                method: 'post',
                url: this.$hdAddress + "/updatePeCustomPageComponent",
                data: row,
            })
                .then(response => {
                    this.$notify({
                        title: '成功',
                        message: response.data,
                        type: 'success'
                    });
                    this.getPages()
                    this.getComponents()
                })
                .catch(error => {
                    this.$notify.error({
                        title: '错误',
                        message: '修改失败\n' + error
                    });
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
        del(row) {
            axios({
                method: 'post',
                url: this.$hdAddress + "/deletePeCustomPageComponentById",
                data: row,
            })
                .then(response => {
                    this.$notify({
                        title: '成功',
                        message: response.data,
                        type: 'success'
                    });
                    this.getPages()
                    this.getComponents()
                })
                .catch(error => {
                    this.$notify.error({
                        title: '错误',
                        message: '删除失败\n' + error
                    });
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
        getPages() {
            axios({
                method: 'get',
                url: this.$hdAddress + "/getAllPEPageTitle",
            })
                .then(response => {
                    this.myPages = response.data
                })
                .catch(error => {
                    this.$notify.error({
                        title: '错误',
                        message: '获取页面失败'
                    });
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
        NToB(nText) {
            if (nText === null || nText === undefined) return '';
            return nText.replace(/\n/g, '<br>');
        },
        BToN(bText) {
            if (bText === null || bText === undefined) return '';
            return bText.replace(/<br>/g, '\n');
        },

        getComponents() {
            //获得页面下的组件
            axios({
                method: "post",
                params: {
                    parent: this.thisPage
                },
                headers: {
                    token: localStorage.getItem("token")
                },
                url: this.$hdAddress + '/selectPeCustomPageComponentsByParent',
            }).then(res => {
                console.log(res);
                
                this.pageItems = res.data
                // 为每一个条目添加一个用于显示给用户的\n文本
                if (res.data)
                    this.pageItems.forEach((value) => {
                        value.NText = this.BToN(value.text);
                    }, this.pageItems);
            })
        },

    },
}
</script>

<style></style>