<template>
  <div id="ServiceContent">
    <!-- 服务内容 -->

    <div class="indexTitle">我们提供</div>

    <showBox :msgObj="this.indexTitle_showBox_inner1"></showBox>
    <div class="indexTitle">您将获得</div>
    <showBox :msgObj="this.indexTitle_showBox_inner2"></showBox>
    <NormalImg1 :imgUrl="FImgUrl2[0]" imgHeight="20"></NormalImg1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indexTitle_showBox_inner1: [
        {
          imgUrl: "images/ServiceContent/一站式服务.png",
          title: "一站式服务",
          content: "风林网络科技提供全新稳定的手游联运系统服务"
        },
        {
          imgUrl: "images/ServiceContent/S级游戏渠道支持.png",
          title: "S级游戏渠道支持",
          content: "定时更新接入时下热门S级游戏，充值不停歇"
        },
        {
          imgUrl: "images/ServiceContent/运营培训服务支持.png",
          title: "运营培训服务支持",
          content: "小白不用怕，全方位培训服务，游戏平台操作讲解指导"
        },
        {
          imgUrl: "images/ServiceContent/各大推广渠道支持.png",
          title: "各大推广渠道支持",
          content: "定时更新接入推广渠道，定制详细推广方案"
        },
        {
          imgUrl: "images/ServiceContent/游戏运营方案支持.png",
          title: "游戏运营方案支持",
          content: "提供账号返利号扶持号，高分成比例，加速您的创业脚步"
        },
        {
          imgUrl: "images/ServiceContent/维护安全更新服务.png",
          title: "维护安全更新服务",
          content: "风林网络科技提供手游联运系统维护更新"
        }
      ],
      indexTitle_showBox_inner2: [
        {
          imgUrl: "images/ServiceContent/技术支持.png",
          title: "技术支持",
          content: "平台搭建 更新升级 功能定制 漏洞修复"
        },
        {
          imgUrl: "images/ServiceContent/运营服务.png",
          title: "运营服务",
          content: "系统讲解，运营指导，方案定制，广告渠道"
        },
        {
          imgUrl: "images/ServiceContent/培训服务.png",
          title: "培训服务",
          content: "系统使用 数据安全 接口制作 升级维护"
        },
        {
          imgUrl: "images/ServiceContent/专职团队.png",
          title: "专职团队",
          content: "精细化团队，从策划，设计，分析，推广到客服，各司其职，组织架构完善，流程畅通"
        },
        {
          imgUrl: "images/ServiceContent/深度合作.png",
          title: "深度合作",
          content: "帮客户打造独立游戏平台，与客户强强联手，职责尽所能"
        },
        {
          imgUrl: "images/ServiceContent/行业专家.png",
          title: "行业专家",
          content: "精英成员十几年互联网从事经验，在行业和客户之间拥有良好声誉"
        }
      ],
      FImgUrl1: "/images/ServiceContent/服务内容.png",
      FImgUrl2: [],
    }
  },
  mounted(){
    this.FImgUrl2 = this.$getImg("代理加盟您将获得图片1")
    console.log(this.FImgUrl2)
  }
}
</script>

<style>
#ServiceContent {
  width: 100%;
  display: block;
}
</style>