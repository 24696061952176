<template>
    <div id="NewsCreator">
        <div class="indexTitle">新增新闻</div>
        <div class="NewsCreator_inner">
            <el-input placeholder="标题" v-model="myNew.title" clearable>
            </el-input>
            <el-input placeholder="简介" v-model="myNew.description" clearable>
            </el-input>
            <!-- <div id="editor"></div> -->
            <el-button type="danger" @click="insertNews()">上传</el-button>
        </div>
    </div>
</template>

<script>
import Bus from '@/utils/EventBus';
import axios from 'axios';
// import Editor from 'wangeditor';
export default {
    data() {
        return {
            myNew: {
                id: 0,
                title: "",
                description: "",
                update_time: "",
                img_url: "",
                content: "",
            },
            editor: null,
        }
    },
    mounted() {
    },
    methods: {
        insertNews() {
            // this.myNew.content = this.editor.txt.html()
            axios({
                method: 'post',
                url: this.$hdAddress + "/m/insertNews",
                headers: {
                    token: localStorage.getItem("token")
                },
                data: this.myNew,
            })
                .then(() => {
                    this.$notify({
                        title: '成功',
                        message: '新增成功',
                        type: 'success'
                    });
                    //发布更新通知
                    Bus.$emit('newCreating', '')
                })
                .catch(error => {
                    this.$notify.error({
                        title: '错误',
                        message: '执行失败'
                    });
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
    }
}
</script>

<style></style>