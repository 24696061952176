<template>
  <div id="SettledEnterprise">
    <!-- 服务内容 -->
    <!-- 顶部图片 -->
    <TopImg :imgUrl="FImgUrl1[0]"></TopImg>

    <div class="indexTitle">厂商入驻</div>
    <NormalText1 :text="this.text1[0].text"></NormalText1>


    <!-- 悬浮按钮 -->
    <div class="floating-button" @click="isModalVisible = !isModalVisible">
      <button>▶</button>
    </div>

    <!-- 悬浮浮窗 -->
    <div class="floating-modal" v-if="isModalVisible">
      <div class="modal-content">
        <!-- 表单组件 -->
        <MForm formType=1></MForm>
      </div>
    </div>




  </div>
</template>

<script>
export default {
  mounted() {
    this.FImgUrl1 = this.$getImg("厂商入驻大图")
    this.text1 = this.$getText("厂商入驻文段1")
  },
  methods: {

  },
  data() {
    return {
      isModalVisible: true,
      FImgUrl1: "/images/SettledEnterprise/厂商入驻.png",
      text1: []
    }
  }
}
</script>

<style>
#ServiceContent {
  width: 100%;
  display: block;
}

/* 使用相对单位确保 #GameAgent 有足够的高度来包含悬浮元素 */
#GameAgent {
  min-height: 100vh;
  /* 视口高度的100% */
  position: relative;
}


  /* 使用相对单位调整悬浮按钮样式 */
  .floating-button {
    position: fixed;
    bottom: 3rem; /* 相对于根元素字体大小的2倍 */
    right: 8rem;
    z-index: 1002;
  }

.floating-button button {
  padding: 1em;
  /* 相对于当前元素的字体大小 */
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.floating-button button:hover {
  background-color: rgb(197, 1, 1);
}

/* 使用相对单位调整悬浮浮窗样式 */
.floating-modal {
  position: fixed;
  bottom: 8vh;
  /* 视口高度的8% */
  right: 2vw;
  /* 视口宽度的2% */
  max-width: 30%;
  /* 视口宽度的30% */
  border: 1px solid #ddd;
  background: white;
  padding: 1em;
  /* 相对于当前元素的字体大小 */
  border-radius: 1em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  display: flex;
  flex-direction: column;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
</style>