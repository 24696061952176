<template>
    <div class="PEC_Mform">
  
  <div class="PEC_Mform_switch" @click="()=>{show=!show}">▶</div>
  
  
      <!-- 代理加盟 -->
      <div class="PEC_Mform_box" v-if="formType == 0 && show">
        <div class="PEC_Mform_box_indexTitle">咨询合作</div>
        <form @submit.prevent="submitForm">
          <!-- 个人/团队选择 -->
          <div class="PEC_Mform_box_form-group">
            <label for="type">个人/团队：</label>
            <div class="PEC_Mform_box_selection-group">
              <span v-for="item in typeOptions" :key="item.label" class="PEC_Mform_box_selection-button"
                :class="{ 'active': userInf.type === item.label }" @click="toggleSelection(item.label)">
                {{ item.label }}
              </span>
            </div>
          </div>
          <!-- 姓名输入 -->
          <div class="PEC_Mform_box_form-group">
            <label for="name">姓名：</label>
            <input type="text" id="name" v-model="userInf.name" class="PEC_Mform_box_input-field" />
          </div>
          <!-- 电话输入框 -->
          <div class="PEC_Mform_box_form-group">
            <label for="phone">电话（必填项）：</label>
            <input type="text" id="phone" v-model="userInf.phone" class="PEC_Mform_box_input-field"
              :class="{ 'error': validatePhoneInstantly }" @blur="validatePhone" />
            <!-- 手机号错误提示信息 -->
            <div v-if="phoneError" class="PEC_Mform_box_error-message">{{ phoneError }}</div>
          </div>
          <!-- 服务选择 -->
          <div class="PEC_Mform_box_form-group">
            <label>选择您需要的服务：</label>
            <div class="PEC_Mform_box_services-group">
              <span v-for="service in servicesOptions" :key="service.label" class="PEC_Mform_box_service-button"
                :class="{ 'active': userInf.services.includes(service.label) }"
                @click="toggleServiceSelection(service.label)">
                {{ service.label }}
              </span>
            </div>
          </div>
          <!-- 提交按钮 -->
          <button type="submit" class="PEC_Mform_box_submit-button" :disabled="isFormDisabled">提交</button>
          <!-- 提示信息 -->
          <div class="PEC_Mform_box_info-text">留下联系方式后，官方商务会在24小时内主动联系您。</div>
        </form>
      </div>
  
  
  
  
  
  
      <!-- 厂商入驻 -->
      <div class="PEC_Mform_box" v-if="formType == 1 && show">
        <div class="PEC_Mform_box_indexTitle">厂家/发行入驻</div>
        <form @submit.prevent="submitForm">
          <!-- 公司名称输入 -->
          <div class="PEC_Mform_box_form-group">
            <label for="name">公司名称：</label>
            <input type="text" id="name" v-model="userInf.name" class="PEC_Mform_box_input-field" />
          </div>
          <!-- 电话输入框 -->
          <div class="PEC_Mform_box_form-group">
            <label for="phone">电话（必填项）：</label>
            <input type="text" id="phone" v-model="userInf.phone" class="PEC_Mform_box_input-field"
              :class="{ 'error': validatePhoneInstantly }" @blur="validatePhone" />
            <!-- 手机号错误提示信息 -->
            <div v-if="phoneError" class="PEC_Mform_box_error-message">{{ phoneError }}</div>
          </div>
          <!-- 服务选择 -->
          <div class="PEC_Mform_box_form-group">
            <label for="name">所提供产品（提供部分即可）：</label>
            <input type="text" id="name" v-model="userInf.notes" class="PEC_Mform_box_input-field" />
          </div>
          <!-- 提交按钮 -->
          <button type="submit" class="PEC_Mform_box_submit-button" :disabled="isFormDisabled">提交</button>
          <!-- 提示信息 -->
          <div class="PEC_Mform_box_info-text">留下联系方式后，官方商务会在24小时内主动联系您。</div>
        </form>
      </div>
  
  
  
  
  
  
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    props: ['formType'],
    data() {
      return {
        show:false,//是否展示
        userInf: {
          type: '个人', // 默认选择个人
          name: '',
          phone: '',
          notes: '',
          services: [],
          replyed: '',
          update_time: '',
          id: '',
          instruction:'',
          isCompany:false
        },
        typeOptions: [
          { label: '个人' },
          { label: '团队' },
        ],
        servicesOptions: [
          { label: '未加载出选项' },
        ],
        phoneError: null,
        lastSubmitTime: null, // 记录上次提交的时间
        ableSubmit: true, // 不在冷却期
      };
    },
    computed: {
      validatePhoneInstantly() {
        return this.userInf.phone && this.userInf.phone.length !== 11;
      },
      // 表单是否可提交（考虑电话错误和冷却时间）
      isFormDisabled() {
        return this.phoneError || !this.ableSubmit;
      },
    },
    mounted() {
      this.selectLabel()
    },
    methods: {
      selectLabel() {
        axios({
          method: "get",
          url: this.$hdAddress + '/selectLabel'
        }).then(res => {
          this.servicesOptions = res.data
        }).catch(e => {
          console.log("可恶，请求失败了")
          console.log("错误信息" + e)
        })
      },
      insertCollaborationForm() {
        this.userInf.isCompany=this.formType;
        
        let msg = JSON.parse(JSON.stringify(this.userInf))
        msg.services = msg.services.join(',');
        axios({
          url: this.$hdAddress + '/insertCollaborationForm',
          method: 'post',
          data: msg,
          headers: {
            'Content-Type': 'application/json',// 设置请求头，告知服务器发送的是JSON数据
          }
        }).then(res => {
          if (res.data == 1)
            this.$notify({
              title: '提交成功',
              message: '我们将尽快联系您',
              type: 'success'
            });
          else {
            this.$notify.error({
              title: '提交失败',
              message: '表单内容异常'
            });
          }
        }).catch(e => {
          this.$notify.error({
            title: '提交失败',
            message: '请检查您的网络状态'
          });
          console.log(e);
        })
      },
      toggleSelection(type) {
        this.userInf.type = type;
      },
      toggleServiceSelection(service) {
        const index = this.userInf.services.indexOf(service);
        if (index > -1) {
          this.userInf.services.splice(index, 1);
        } else {
          this.userInf.services.push(service);
        }
      },
  
      // 验证电话号是否为11位数字
      validatePhone() {
        if (this.userInf.phone && this.userInf.phone.length !== 11) {
          this.phoneError = '电话号码必须为11位数字';
        } else {
          this.phoneError = '';
        }
      },
      // 提交表单方法
      submitForm() {
        this.validatePhone(); // 验证手机号
        if (this.phoneError || this.isFormDisabled) return;
  
        if (!this.userInf.phone) {
          this.phoneError = '电话号码是必填项';
          return;
        }
  
        // 重置错误信息并开始冷却
        this.phoneError = '';
        this.ableSubmit = false;
        setTimeout(() => {
          this.ableSubmit = true;
        }, 5000);
  
        // 提交表单逻辑
        // 这里添加提交表单的逻辑，例如发送到服务器等
  
        this.insertCollaborationForm();
      },
    },
  };
  </script>
  

  <style>

/* 悬浮按钮样式 */
.PEC_Mform_switch {
  position: fixed; /* 固定定位 */
  bottom: 7em; /* 距离底部20px */
  right: 1.5em; /* 距离右侧20px */
  cursor: pointer;
  padding: 1.1em;
  height: 1.1em;
  width:1.1em;
  font-size: 1.1em;
  background: #e74c3c; /* 按钮背景颜色 */
  color: #fff; /* 文字颜色 */
  text-align: center;
  border-radius: 50%; /* 圆形按钮 */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* 阴影效果 */
  z-index: 1000; /* 确保按钮在最上层 */
}

/* 表单容器样式 */
.PEC_Mform_box {
  position: fixed; /* 固定定位 */
  top: 50%; /* 垂直居中 */
  left: 50%; /* 水平居中 */
  transform: translate(-50%, -50%); /* 偏移自身尺寸的50%以确保完全居中 */
  background: #fff; /* 背景颜色 */
  padding: 15px; /* 内边距 */
  border-radius: 8px; /* 边框圆角 */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* 阴影效果 */
  width: 80%; /* 宽度为视口宽度的90% */
  max-width: 400px; /* 最大宽度限制 */
  z-index: 500; /* 确保表单在按钮之上 */
}

.PEC_Mform_box_indexTitle {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1rem;
}

.PEC_Mform_box_form-group {
    font-size: 0.8em;
    margin: 0;
    padding: 0;
}

.PEC_Mform_box_form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #666;
}

.PEC_Mform_box_input-field {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-sizing: border-box;
  font-size: 1rem;
}

.PEC_Mform_box_input-field.error {
  border-color: #e74c3c;
}

.PEC_Mform_box_error-message {
  color: #e74c3c;
  font-size: 0.9rem;
  margin-top: 0.25rem;
}

/* 选项按钮组样式 */
.PEC_Mform_box_selection-group,
.PEC_Mform_box_services-group {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5%; /* 子元素之间的间隙 */
  justify-content: left; /* 均匀分布 */
  margin-top: 0.5rem;
}

.PEC_Mform_box_selection-button,
.PEC_Mform_box_service-button {
  overflow: hidden;       /* 隐藏超出的内容 */
  white-space: nowrap;     /* 不换行 */
  text-overflow: ellipsis; /* 显示省略号 */
  flex: 0 0 24%; /* 每个按钮占据30%的宽度 */
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 0.7rem;
  margin-bottom: 5%; /* 垂直间隙 */
}

.PEC_Mform_box_selection-button.active,
.PEC_Mform_box_service-button.active {
  background-color: #e74c3c;
  color: #fff;
  border-color: #e74c3c;
}

.PEC_Mform_box_submit-button {
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #e74c3c;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
}

.PEC_Mform_box_submit-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.PEC_Mform_box_info-text {
  text-align: center;
  font-size: 0.9rem;
  color: #666;
  margin-top: 0.5rem;
}

</style>