<template>
    <div class="PEC_lunBo1">
        <el-carousel :interval="4000" type="card" :height="this.height + 'em'">
            <el-carousel-item v-for="item in realImgsUrls" :key="item">
                <img :src="item" alt="aa">
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>

import Bus from '@/utils/EventBus';
export default {
    //banUseLocal代表是否禁用为图片地址自动添加
    props: ['imgsUrls', 'height', 'banUseLocal'],
    data() {
        return {
            realImgsUrls: [],
        }
    },
    mounted() {
        this.observeElement();
        this.lunBoInit()
    },
    methods: {
        lunBoInit(){
        this.realImgsUrls=[]//必须先初始化一遍，也不知道是为什么
        //如果没有禁用则添加本地地址
        if (this.banUseLocal === undefined || this.banUseLocal === false) {
            this.imgsUrls.forEach((item, index) => {
                this.realImgsUrls[index] = this.$hdAddress + item;
            });
        } else {
            this.realImgsUrls = this.imgsUrls;
            if(this.realImgsUrls===undefined){//如果没有数据，则听监听
                Bus.$on('GameDetailSelected',msg=>{
                    this.observeElement();
                    this.realImgsUrls=msg.imgsList
                })
            }
        }
        },
        observeElement() {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.animateIn();
                    }
                });
            });
            observer.observe(this.$el);
        },
        animateIn() {
            // 触发CSS过渡效果
            let 渐变元素 = this.$el.querySelectorAll('img');
            // 随机出现时间
            let currentDelay = 500 + 1000 * Math.random();

            渐变元素.forEach((item) => {
                // 设置延迟时间，每次迭代增加0.4秒
                setTimeout(() => {
                    item.style.opacity = '1';
                    item.style.transform = 'translateY(0)';
                }, currentDelay);

                // 更新延迟时间，为下一次迭代准备
                currentDelay += 500 + 300 * Math.random(); // 0.2秒转换为毫秒
            });
        }
    }
}
</script>

<style>
.PEC_lunBo1 {
    display: block;
    width: 90%;
    margin: 0;
    padding: 0;
    padding-left: 2.5%;
    margin-top: 1em;
}

.PEC_lunBo1 .el-carousel__item img {
    opacity: 0;
    /* 开始时透明度为0 */
    transform: translateY(2em);
    /* 开始时向上移动 */
    transition: all 1s ease;
    /* 定义CSS过渡效果 */
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

.PEC_lunBo1 .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf00;
}

.PEC_lunBo1 .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce600;
}
</style>