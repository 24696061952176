<template>
  <div class="NormalText1_inner">
    <pre>
      <div v-html="this.text"></div>
    </pre>
  </div>
</template>

<script>
export default {
  props: ['text'],
}
</script>

<style>
.NormalText1_inner {
  margin: auto;
  margin-bottom: 1em;
  padding-left: 20%;
  padding-right: 20%;
  width: 60%;
}
.NormalText1_inner div {
  line-height: 1.5;
  color: #333;
  font-size: 1em; 
  white-space: pre-wrap;
  overflow: auto; /* 根据需要调整 */
    line-height: 1.5;
    color: #333;
      font-size: 1em; 
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      overflow: hidden;
}
</style>
  