<template>
  <div class="TextChanger">
    <div class="indexTitle">文字内容管理</div>

    <div class="indexTitle_belong">
      选择文本所属页面
      <el-select
        v-model="choicedBelong"
        placeholder="请选择"
        @change="getTextByBelongs"
      >
        <el-option
          v-for="item in allBelongs"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>

    <el-table class="indexTitle_belong" :data="texts" style="width: 100%">
      <el-table-column prop="type" label="名称" width="120">
        <template slot-scope="scope">
          {{ scope.row.type }}
        </template>
      </el-table-column>

      <el-table-column prop="text" label="文本" width="700">
        <template slot-scope="scope">
          <textarea
            style="resize: vertical; width: 100%"
            v-model="scope.row.NText"
            @input="scope.row.text = NToB(scope.row.NText)"
          ></textarea>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="change(scope.row)" type="text" size="small"
            >确认修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data() {
    return {
      allBelongs: [],
      choicedBelong: "",
      texts: [],
    };
  },
  mounted() {
    this.getBelongs();
  },
  methods: {
    change(text) {
      axios({
        method: "post",
        url: this.$hdAddress + "/m/updateTextById",
        data: text,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then(() => {
          this.$notify({
            title: "成功",
            message: "新增成功",
            type: "success",
          });
          this.getBelongs();
        })
        .catch((error) => {
          this.$notify.error({
            title: "错误",
            message: "执行失败",
          });
          console.error("Error:", error.response ? error.response.data : error);
        });
    },
    NToB(nText) {
      return nText.replace(/\n/g, "<br>");
    },
    BToN(bText) {
      return bText.replace(/<br>/g, "\n");
    },
    getBelongs() {
      axios({
        method: "get",
        url: this.$hdAddress + "/selectTBelongs",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.allBelongs = response.data;
        })
        .catch((error) => {
          console.error("Error:", error.response ? error.response.data : error);
        });
    },
    getTextByBelongs() {
      axios({
        method: "post",
        url: this.$hdAddress + "/selectTextsByBelong",
        params: {
          belong: this.choicedBelong,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.texts = response.data;

          // 为每一个条目添加一个用于显示给用户的\n文本
          if (response.data)
            this.texts.forEach((value, index) => {
              if (index > -1)
                //仅仅是为了避免报错
                value.NText = this.BToN(value.text);
            }, this.texts);
        })
        .catch((error) => {
          console.error("Error:", error.response ? error.response.data : error);
        });
    },
  },
};
</script>

<style>
.indexTitle_belong {
  display: block;
  margin: auto;
  text-align: center;
  left: 25%;
}
</style>