<template>
    <div class="GameDetail_box1_inner">
        <div class="GameDetail_box1_inner_left">
            <div class="GameDetail_box1_inner_left_icon">
            <img :src="this.msgs.icon" alt="">
        </div>
        <div class="GameDetail_box1_inner_left_title">{{this.msgs.name}}</div>
        </div>
        <div class="GameDetail_box1_inner_right">
            <div class="GameDetail_box1_inner_right_biaoqian">
                游戏名称：{{this.msgs.name}}
            </div>
            <div class="GameDetail_box1_inner_right_biaoqian">
                平台类型：{{this.pingTai}}
            </div>
            <div class="GameDetail_box1_inner_right_biaoqian">
                标签：{{this.msgs.biaoqian}}
            </div>
            <!-- <div class="GameDetail_box1_inner_right_changshang">
                厂商：{{this.msgs.changshang}}
            </div> -->
            <div class="GameDetail_box1_inner_right_shangjia">
                上架时间：{{this.msgs.shangjia}}
            </div>
            <br>
            <div class="GameDetail_box1_inner_right_content">
                {{this.msgs.content}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['msgs'],
    computed:{
        pingTai(){
            if(this.msgs.system_type==1)
                if(this.msgs.system==1)
                    return "Android"
                else
                    return "Android、IOS"
            else if(this.msgs.system_type==2)
                return "h5游戏"
            else return"网页游戏"
        }
    }
}
</script>

<style>

.GameDetail_box1_inner {
    height: 20em;
  display: block;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  padding-top: 5em;
  top: 10em;
  border-radius: 0.5em;
}

.GameDetail_box1_inner_left {
    float: left;
  width: 30%;
  text-align: center;
}

.GameDetail_box1_inner_left_icon img {
  width: 100%;
  max-width: 10em;
  border-radius: 0.5em;
}

.GameDetail_box1_inner_left_title {
  font-size: 1.5em;
  color: #333;
  margin-top: 0.5em;
}

.GameDetail_box1_inner_right {
    padding-top: 1em;
    padding-right: 10%;
    width: 60%;
    float: left;
}

.GameDetail_box1_inner_right > div {
  font-size: 1em;
  color: #666;
  margin-bottom: 0.5em;
}

/* 小清新风格的颜色和布局调整 */
.GameDetail_box1_inner_right_changshang,
.GameDetail_box1_inner_right_shangjia,
.GameDetail_box1_inner_right_biaoqian {
  font-weight: bold;
}

.GameDetail_box1_inner_right_content {
  color: #333;
  font-size: 1.2em;
  margin-bottom: 1em;
}

@media (max-width: 768px) {
  .GameDetail_box1_inner {
    flex-direction: column;
  }

  .GameDetail_box1_inner_left {
    max-width: 100%;
  }

  .GameDetail_box1_inner_right {
    padding-left: 0;
  }
}
</style>