<template>
  <div>
    <div v-if="isPE" class="PE_NewsDetail">
      <div class="PE_NewsDetail_title">
        {{ this.myNew.title }}
      </div>
      <div class="PE_NewsDetail_update_time">
        {{ this.myNew.update_time }}
      </div>
      <div class="PE_NewsDetail_description">
        {{ this.myNew.description }}
      </div>
      <div class="PE_NewsDetail_content">
        <div class="PE_NewsDetail_content_html" v-html="myNew.content"></div>
      </div>
    </div>


    <div v-else class="NewsDetail">
      <div class="NewsDetail_title">
        {{ this.myNew.title }}
      </div>
      <div class="NewsDetail_update_time">
        {{ this.myNew.update_time }}
      </div>
      <div class="NewsDetail_description">
        {{ this.myNew.description }}
      </div>
      <div class="NewsDetail_content">
        <div class="PE_NewsDetail_content_html" v-html="myNew.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  watch: {
    myNew() {
      //去除图片的原本宽高
      this.$nextTick(() => {

        // 触发CSS过渡效果
        let imgs = this.$el.querySelectorAll('img');
        // 随机出现时间
        imgs.forEach((item) => {
          item.style.height = 'auto';
          item.style.width = '100%';
        });
      })
    }
  },
  data() {
    return {
      myNew: {},
    };
  },
  mounted() {
    this.selectNewById();
  },
  methods: {
    isPE() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      //如果是手机端访问，则看作是手机端
      if (flag) {
        return true;
      }
      return false;
    },
    //html转译
    parseHtml(html) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      // 使用 DOM 解析器来解码 HTML 实体
      const text = document.createTextNode(tempDiv.textContent || tempDiv.innerText || '');
      tempDiv.innerHTML = text.nodeValue;
      return tempDiv.innerHTML;
    },
    selectNewById() {
      axios({
        method: "post",
        data: {
          id: this.id,
          self: this.self
        },
        url: this.$hdAddress + "/selectNewById",
      }).then((res) => {
        this.myNew = res.data;
        this.myNew.content = this.parseHtml(this.myNew.content);

        //修改页面标题
        document.title = res.data.title + "  新闻资讯 -风林网络"
      });
    },
  },
  computed: {
    id() {
      // 使用正则表达式查找查询参数，如果匹配成功，解析查询参数并返回 'id' 参数的值
      const regex = /(?:\?|&)id=([^&]+)/; // 修改正则表达式以正确匹配 id 参数
      const match = window.location.href.match(regex);
      if (match) {
        return decodeURIComponent(match[1]); // 解码 URI 组件以避免编码问题
      }
      return null; // 如果没有找到参数，返回 null 或者其他默认值
    },
    self() {
      // 使用正则表达式查找查询参数，如果匹配成功，解析查询参数并返回 'id' 参数的值
      const regex = /(?:\?|&)self=([^&]+)/; // 修改正则表达式以正确匹配 id 参数
      const match = window.location.href.match(regex);
      if (match) {
        return decodeURIComponent(match[1]); // 解码 URI 组件以避免编码问题
      }
      return null; // 如果没有找到参数，返回 null 或者其他默认值
    },
  },
};
</script>

<style scoped>
.NewsDetail {
  margin: auto;
  width: 60%;
  min-height: 800px;
}

.NewsDetail_title {
  padding-top: 2em;
  text-align: center;
  font-size: 2em;
  color: #333;
  margin-bottom: 0.5em;
}

.NewsDetail_update_time {
  padding-top: 1em;
  text-align: center;
  font-size: 0.8em;
  color: #999;
  margin-bottom: 1em;
}

.NewsDetail_description {
  padding-top: 2em;
  font-size: 1em;
  color: #666;
  line-height: 1.5;
  margin-bottom: 1.5em;
}

.NewsDetail_content {
  padding-top: 2em;
  padding-bottom: 4em;
  line-height: 1.6;
  font-size: 1.2em;
  color: #333;
}

.NewsDetail_content div {
  font-size: 1em;
  /* 富文本内容的字体大小 */
}

.NewsDetail_content h1,
.NewsDetail_content h2,
.NewsDetail_content h3,
.NewsDetail_content h4,
.NewsDetail_content h5,
.NewsDetail_content h6 {
  color: #333;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.NewsDetail_content p {
  margin-bottom: 1em;
}

.NewsDetail_content a {
  color: #ff0d00;
  text-decoration: none;
}

.NewsDetail_content a:hover {
  text-decoration: underline;
}

.NewsDetail_content img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0.5em 0;
}

/* 适配小屏幕设备 */
@media (max-width: 768px) {
  .NewsDetail_title {
    font-size: 1.5em;
  }

  .NewsDetail_update_time,
  .NewsDetail_description {
    font-size: 0.75em;
  }
}













/* 移动端新闻详情页容器样式 */
.PE_NewsDetail {
  margin-top: 3em;
  padding: 5%;
  /* 内边距使用百分比 */
  font-family: 'Arial', sans-serif;
  /* 字体设置 */
  color: #333;
  /* 文本颜色 */
}

/* 移动端新闻标题样式 */
.PE_NewsDetail_title {
  text-align: center;
  font-size: 2rem;
  /* 字体大小使用相对单位 */
  margin-bottom: 2%;
  /* 外边距使用百分比 */
}

/* 移动端新闻更新时间样式 */
.PE_NewsDetail_update_time {
  text-align: center;
  font-size: 0.7rem;
  /* 字体大小使用相对单位 */
  color: #666;
  /* 文本颜色 */
  margin-bottom: 3%;
  /* 外边距使用百分比 */
}

/* 移动端新闻描述样式 */
.PE_NewsDetail_description {
  font-size: 0.7rem;
  /* 字体大小使用相对单位 */
  color: #444;
  /* 文本颜色 */
  margin-bottom: 5%;
  /* 外边距使用百分比 */
}

/* 移动端新闻内容样式 */
.PE_NewsDetail_content {
  font-size: 1rem;
  /* 字体大小使用相对单位 */
  line-height: 1.5;
  /* 行高 */
  margin-bottom: 5%;
  /* 外边距使用百分比 */
}

/* 移动端新闻内容中的图片样式 */
.PE_NewsDetail_content img {
  width: 100%;
  /* 图片宽度填满容器 */
  height: auto;
  display: block;
  /* 移除图片下方可能出现的间隙 */
  margin: 1em 0;
  /* 上下外边距使用em单位 */
}

/* 移动端新闻内容中的段落样式 */
.PE_NewsDetail_content p {
  margin-bottom: 1.5em;
  /* 段落之间的间距 */
}

/* 移动端新闻内容中的标题样式 */
.PE_NewsDetail_content h1,
.PE_NewsDetail_content h2,
.PE_NewsDetail_content h3 {
  font-size: 1.2rem;
  /* 字体大小使用相对单位 */
  margin-bottom: 0.5em;
  /* 外边距使用em单位 */
}

/* 移动端新闻内容中的列表样式 */
.PE_NewsDetail_content ul,
.PE_NewsDetail_content ol {
  padding-left: 2em;
  /* 列表的左内边距 */
  margin-bottom: 1.5em;
  /* 列表的外边距 */
}

/* 移动端新闻内容中的列表项样式 */
.PE_NewsDetail_content li {
  margin-bottom: 0.5em;
  /* 列表项的外边距 */
}

/* 响应式调整：当屏幕宽度小于600px时，进一步调整样式 */
@media (max-width: 600px) {
  .PE_NewsDetail_title {
    font-size: 1.2rem;
    /* 缩小标题字体大小 */
  }

  .PE_NewsDetail_description {
    font-size: 0.9rem;
    /* 缩小描述字体大小 */
  }

  .PE_NewsDetail_content {
    font-size: 0.9rem;
    /* 缩小内容字体大小 */
  }
}
</style>