<!-- 满屏大图 -->
<!-- 双倍高度 -->
<template>
    <div class="TopImg2_inner">
        <img :src="this.imgUrl" alt="">
    </div>
  </template>
  
  <script>
  export default {
    props:['imgUrl']
  }
  </script>
  
  <style>
  .TopImg2_inner{
    z-index: 1;
    width: 100%;
    height: 60em;
    overflow: visible;
    display: block;
    margin-bottom: 1em;
  }
  .TopImg2_inner img{
    width: 100%;
    height: 100%;
  }
  
  </style>