<template>
  <div id="AboutUs_box1_inner">
    
    <img src="../../../public/images/ContactUs/联系我们.png" alt="联系我们" title="联系我们">
  </div>
</template>

<script>
export default {

}
</script>

<style>
#AboutUs_box1_inner{
  width: 100%;
  height: 30em;
}
#AboutUs_box1_inner img{
  width: 100%;
  height: 100%;
}
</style>