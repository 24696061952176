<template>
  <div class="PEC_lunBo3">
    <div v-if="imgsUrls">
      <div
        class="PEC_lunBo3_item"
        v-for="(imgUrl, index) in imgsUrls"
        :key="index"
      >
        <img
          :style="{ opacity: nowIndex == index ? 1 : 0 }"
          class="PEC_lunBo3_item_img"
          :src="$hdAddress + imgUrl"
          alt=""
        />
        <pre
          v-html="texts[index]"
          class="PEC_lunBo3_item_text"
          v-show="nowIndex == index"
          :class="{ 'float-up-animation': nowIndex == index }"
        ></pre>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["imgsUrls", "text"],
  data() {
    return {
      nowIndex: 0,
      autoplay: true, // 控制自动播放状态
      intervalId: null, // 定时器ID，用于自动播放
    };
  },
  mounted() {
    this.slideBanner();
    this.startAutoplay(); // 启动自动播放
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId); // 清除定时器
    }
  },
  computed: {
    texts() {
      if (this.text) return this.text.split("&");
      else return [];
    },
  },
  methods: {
    slideBanner() {
      var box = this.$el;
      var startPoint = 0;
      var stopPoint = 0;
      var threshold = 50; // 根据轮播图宽度调整这个值

      // 触摸开始时记录起始点
      box.addEventListener("touchstart", (e) => {
        this.autoplay = false; // 触摸时停止自动播放
        this.stopAutoplay(); // 停止定时器
        startPoint = e.changedTouches[0].clientX;
      });
      // 触摸移动时更新停止点
      box.addEventListener("touchmove", (e) => {
        stopPoint = e.changedTouches[0].clientX;
      });

      // 触摸结束时判断滑动方向
      box.addEventListener("touchend", () => {
        // 计算滑动距离
        var distance = stopPoint - startPoint;

        if (Math.abs(distance) > threshold) {
          // 如果滑动距离超过阈值，则执行翻页操作
          if (distance > 0) {
            this.nowIndex =
              this.nowIndex == 0 ? this.imgsUrls.length - 1 : this.nowIndex - 1;
          } else {
            this.nowIndex =
              this.nowIndex == this.imgsUrls.length - 1 ? 0 : this.nowIndex + 1;
          }
        }
        this.autoplay = true; // 开启自动播放
        this.resumeAutoplay(); // 事件结束后恢复自动播放
      });
    },
    startAutoplay() {
      if (this.autoplay) {
        this.intervalId = setInterval(() => {
          if (this.nowIndex === this.imgsUrls.length - 1) {
            this.nowIndex = 0;
          } else {
            this.nowIndex += 1;
          }
        }, 3000); // 每3秒切换到下一张图片
      }
    },
    stopAutoplay() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    resumeAutoplay() {
      if (this.autoplay) {
        this.startAutoplay();
      }
    },
  },
};
</script>

<style>
.PEC_lunBo3 {
  height: 15em;
  width: 100%;
}

.PEC_lunBo3_item,
.PEC_lunBo3_item_img,
.PEC_lunBo3_item_text {
  transition: all 1s ease;
}

.PEC_lunBo3_item {
  position: absolute;
  width: 100%;
  height: 15em;
}

.PEC_lunBo3_item_img {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.PEC_lunBo3_item_text {
  color: rgb(255, 255, 255);
  position: relative;
  z-index: 2;
  bottom: 6em;
  font-family: KaiTi;
  font-size: 1.2em;
  opacity: 0;
  text-align: center;
}













/* 定义字体上浮动画的关键帧 */
@keyframes floatUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* 应用动画的类 */
.float-up-animation {
  animation: floatUp 2s ease forwards; /* 0.5秒完成动画，ease为时间函数，forwards保持动画结束后的状态 */
  opacity: 0; /* 初始状态不透明度为0 */
}
</style>