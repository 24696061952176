<template>
  <div id="AboutUs_box5_inner">
    <img :src="this.FImgUrl1[0]" alt="">
  </div>
</template>

<script>
export default {
  data(){
      return{
          FImgUrl1: [],
      }
  },
  mounted(){
    this.FImgUrl1 = this.$getImg("关于我们运营产品展示1")
    
  },

}
</script>


<style>

#AboutUs_box5_inner{
  width: 100%;
  height: 100%;
}
#AboutUs_box5_inner img{
  width: 100%;
  height: 100%;
}
</style>