<template>
    <div class="ComponentChanger">
        <div class="indexTitle">修改自定义组件</div>
        <div class="newComponent_affiliation_id">
            父页面
            <el-select v-model="thisPage.id" placeholder="请选择父页面" @change="handleTypeChange">
                <el-option v-for="item in myPages" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
        </div>

        <div class="pageCreator_createBox_item">
            <el-button type="danger" @click="getComponents(thisPage.id)">刷新</el-button>
        </div>

        <el-table :data="pageItems" style="width: 100%">
            <el-table-column prop="priority" label="优先级" width="250">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.priority" :min="1" :max="99999"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="类型" width="120">
                <template slot-scope="scope">
                    {{ getTypeDescription(scope.row.type) }}
                </template>
            </el-table-column>
            <el-table-column prop="title" label="标题" width="220">
                <template slot-scope="scope" v-if="scope.row.type == 1">
                    <input style="width: 100%;" type="text" v-model="scope.row.title">
                </template>
            </el-table-column>
            <el-table-column prop="text" label="文本" width="400">
                <template slot-scope="scope" v-if="scope.row.type == 2 || scope.row.type == 4 || scope.row.type == 5">
                    <textarea style="resize: vertical;width: 100%;" v-model="scope.row.NText"
                        @input="scope.row.text = NToB(scope.row.NText);"></textarea>
                </template>
            </el-table-column>
            <el-table-column prop="img_url" label="图片" width="300">
                <template slot-scope="scope"
                    v-if="scope.row.type == 3 || scope.row.type == 4 || scope.row.type == 5 || scope.row.type == 6">
                    <img style="width: 100%;" :src="$hdAddress + scope.row.img_url" alt="">
                    <div class="f1">
                        替换图片
                        <input class="NormalBtn" type="file" @change="handleFileChange">
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="note" label="备注（给管理员自己看的）" width="200">
                <template slot-scope="scope">
                    <textarea style="resize: vertical;width: 100%;" v-model="scope.row.note"></textarea>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button @click="change(scope.row)" type="text" size="small">确认修改</el-button>
                    <el-button @click="del(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>





    </div>
</template>

<script>
import Bus from '@/utils/EventBus';
import axios from 'axios';

export default {
    created() {
        Bus.$on('componentCreating', () => {
            this.getComponents(this.thisPage.id)
        })
        Bus.$on('pageCreating', () => {
            this.getPages()
        })
    },
    mounted() {
        this.getPages()
    },
    components: {
    },
    data() {
        return {
            thisPage:
            {
                id: null,
                name: null,
                priority: null,
                components_count: null,
                hidden: null,
                page_views: null,
                notes: null,
            },
            pageItems: [],
            myPages: [],
            file: null // 添加文件变量
        };
    },

    methods: {
        getPages() {
            axios({
                method: 'get',
                url: this.$hdAddress + "/selectAllCustomPages",
                headers:{
                    token:localStorage.getItem("token")
                },
            })
                .then(response => {
                    this.myPages = response.data
                    this.thisPage.id = this.myPages[0].id
                    this.handleTypeChange()
                })
                .catch(error => {
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
        handleFileChange(event) {
            this.file = event.target.files[0]; // 存储文件对象
        },
        del(row) {
            axios({
                method: "post",
                params: {
                    id: row.id
                },
                url: this.$hdAddress + '/m/deleteCustomPageComponentById',
                headers:{
                    token:localStorage.getItem("token")
                },
            }).then(res => {
                if (res.data)
                    this.$notify({
                        title: '成功',
                        message: '删除成功',
                        type: 'success'
                    });
                // this.$router.go(0)
                this.getComponents(this.thisPage.id);
            })
        },
        change(pageItem) {
            const formData = new FormData();
            formData.append('imageFile', this.file);
            formData.append('affiliation_id', pageItem.affiliation_id || '');
            formData.append('priority', pageItem.priority || '');
            formData.append('type', pageItem.type || '');
            formData.append('note', pageItem.note || '');
            formData.append('title', pageItem.title || '');
            formData.append('text', pageItem.text || '');
            formData.append('img_url', pageItem.img_url || '');
            formData.append('id', pageItem.id || '');



            this.$notify.info({
                title: 'Loading',
                message: '正在上传'
            });
            axios({
                method: 'post',
                url: this.$hdAddress + "/m/updateCustomPageComponent",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    token:localStorage.getItem("token")
                }
            })
                .then(response => {
                    console.log('Response:', response.data);

                    this.$notify({
                        title: '成功',
                        message: '新增成功',
                        type: 'success'
                    });

                    // this.$router.go(0);
                    this.getComponents(this.thisPage.id);
                }).catch(error => {

                    this.$notify.error({
                        title: '错误',
                        message: '执行失败'
                    });
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
        NToB(nText) {
            return nText.replace(/\n/g, '<br>');
        },
        BToN(bText) {
            return bText.replace(/<br>/g, '\n');
        },
        handleTypeChange() {
            this.getComponents(this.thisPage.id)
        },
        getComponents(affiliation_id) {
            axios({
                method: "post",
                params: {
                    affiliation_id: affiliation_id
                },
                headers:{
                    token:localStorage.getItem("token")
                },
                url: this.$hdAddress + '/selectAllCustomPageComponentsByAfId',
            }).then(res => {
                this.pageItems = res.data
                // 为每一个条目添加一个用于显示给用户的\n文本
                if (res.data)
                    this.pageItems.forEach((value) => {
                            value.NText = this.BToN(value.text);
                    }, this.pageItems);
            })
        },
        getTypeDescription(typeNumber) {
            const typeDescriptions = {
                1: '标题',
                2: '纯文字',
                3: '图片',
                4: '左文右图',
                5: '左图右文',
                6: '页头大图'
            };
            return typeDescriptions[typeNumber] || '未知类型';
        },
    }
}
</script>

<style>

/* 组件更改者容器 */
.ComponentChanger {
    margin: auto;
    width: 100%;
    position: fle;
    padding: 20px;
}

/* 新组件归属ID选择器 */
.ComponentChanger .newComponent_affiliation_id {
    margin-bottom: 1em; /* 相对间距 */
}

/* 表单项样式 */
.ComponentChanger .pageCreator_createBox_item {
    margin-bottom: 1em; /* 相对间距 */
    position: relative;
}

/* 按钮样式 */
.ComponentChanger .pageCreator_createBox_item button {
    color: #fff;
    background-color: #ff4949;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 1em; /* 按钮与输入框的间距 */
}

/* 按钮悬浮效果 */
.ComponentChanger .pageCreator_createBox_item button:hover {
    background-color: #e03131;
}

/* 表格样式 */
.ComponentChanger el-table {
    margin-top: 1em; /* 表格与按钮的间距 */
}

/* 表格列样式 */
.ComponentChanger el-table-column {
    padding: 8px 0;
}

/* 表格单元格样式 */
.ComponentChanger .cell {
    padding: 8px;
}

/* 表格操作按钮样式 */
.ComponentChanger .el-button--text {
    padding: 5px 10px;
    color: #ff4949;
}

/* 表格操作按钮悬浮效果 */
.ComponentChanger .el-button--text:hover {
    background-color: #ff4949;
    color: #fff;
}

/* 图片替换按钮样式 */
.ComponentChanger .f1 {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    /* text-align: center; */
    cursor: pointer;
}

/* 图片替换按钮悬浮效果 */
.ComponentChanger .f1:hover {
    background: rgba(0, 0, 0, 0.7);
}

/* 普通按钮样式 */
.ComponentChanger .NormalBtn {
    margin-left: 5px;
    cursor: pointer;
}

</style>