<template>
  <div class="CustomerServer">
    <div class="CustomerServerCreator">
        <h2>添加新的客服电话(用于接收客户来信)</h2>
            <el-input placeholder="电话" style="width: 20em;" v-model="NewCustomerServer.phone" clearable>
            </el-input>
        <el-button type="danger" @click="add">添加</el-button>
    </div>
    <div class="CustomerServerChanger">
        <el-table :data="CustomerServers" style="width: 100%">
      <el-table-column prop="id" label="id" width="150">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="电话" width="250">
        <template slot-scope="scope">
          <input type="text" v-model="scope.row.phone">
        </template>
      </el-table-column>

      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="change(scope.row)" type="text" size="small"
            >确认修改</el-button
          >
          <el-button @click="del(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      CustomerServers: [],
      NewCustomerServer: {},
    };
  },
  mounted() {
    this.selectAllCustomerServer();
  },
  methods: {
    selectAllCustomerServer() {
      axios({
        method: "get",
        url: this.$hdAddress + "/m/selectAllCustomerServer",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((res) => {
          this.CustomerServers = res.data;
        })
        .catch((error) => {
          this.$notify.error({
            title: "错误",
            message: "获取信息失败",
          });
          console.error("Error:", error.response ? error.response.data : error);
        });
    },
    change(row) {
      axios({
        method: "post",
        url: this.$hdAddress + "/m/updateCustomerServer",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: row,
      })
        .then(() => {
          this.$notify({
            title: "成功",
            message: "修改成功",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify.error({
            title: "错误",
            message: "修改失败",
          });
          console.error("Error:", error.response ? error.response.data : error);
        });
    },
    del(row) {
      axios({
        method: "post",
        url: this.$hdAddress + "/m/deleteCustomerServerById",
        headers: {
          token: localStorage.getItem("token"),
        },
        params:{id : row.id},
      })
        .then(() => {
          this.$notify({
            title: "成功",
            message: "删除成功",
            type: "success",
          });
          this.selectAllCustomerServer();
        })
        .catch((error) => {
          this.$notify.error({
            title: "错误",
            message: "删除失败",
          });
          console.error("Error:", error.response ? error.response.data : error);
        });
    },
    add(){
        axios({
        method: "post",
        url: this.$hdAddress + "/m/insertCustomerServer",
        headers: {
          token: localStorage.getItem("token"),
        },
        data:this.NewCustomerServer
      })
        .then(() => {
          this.$notify({
            title: "成功",
            message: "删除成功",
            type: "success",
          });
          this.selectAllCustomerServer();
          this.NewCustomerServer = {}
        })
        .catch((error) => {
          this.$notify.error({
            title: "错误",
            message: "删除失败",
          });
          console.error("Error:", error.response ? error.response.data : error);
        });
    }
  },
};
</script>

<style>


</style>