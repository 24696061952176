<!-- 高度可调图片 -->
<template>
  <div class="PEC_img2" :style="'height:'+this.height+'em;'">
    <img :src="this.imgUrl" alt="">
  </div>
</template>

<script>
export default {
  props:['imgUrl','height'],
  mounted() {
    this.observeElement();
  },
  methods: {
    observeElement() {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.animateIn();
          }
        });
      });
      observer.observe(this.$el);
    },
    animateIn() {
            // 触发CSS过渡效果
            let 渐变元素 = this.$el.querySelectorAll('img');
            // 随机出现时间
            let currentDelay = 500+1000 * Math.random();
            渐变元素.forEach((item) => {
                // 设置延迟时间，每次迭代增加0.4秒
                setTimeout(() => {
                    item.style.opacity = '1';
                    item.style.transform = 'translateY(0)';
                }, currentDelay);

                // 更新延迟时间，为下一次迭代准备
                currentDelay += 500+300*Math.random(); // 0.2秒转换为毫秒
            });
    }
  }
}
</script>

<style>
.PEC_img2{
  margin: 0;
  padding: 0;
  padding-top: 1px;
  padding-bottom: 1px;
  width: 100%;
  height: 20em;
}
.PEC_img2 img{
  opacity: 0; /* 开始时透明度为0 */
  transform: translateY(2em); /* 开始时向上移动 */
  transition: all 1s ease; /* 定义CSS过渡效果 */
  width: 100%;
  height: 100%;
}

</style>