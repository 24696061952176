<template>
    <div class="showBox">
      <div class="showBox_items" v-for="item in this.msgObj" :key="item.title">
          <div class="showBox_items_t1">
              <img :src="item.imgUrl" :alt="item.title">
          </div>
          <div class="showBox_items_t2">
              {{ item.title }}
          </div>
          <div class="showBox_items_t3">
              {{ item.content }}
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props:['msgObj'],
      data(){
          return{
              test:[]
          }
      }
  }
  </script>
  
  <style scoped>
  .showBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* 根据需要调整子元素的间距 */
    padding: 2%; /* 外边距 */
    padding-left: 20%;
    padding-right: 20%;
  }
  
  .showBox_items {
    border: 1px solid #ddd; /* 边框 */
    border-radius: 8px; /* 边框圆角 */
    margin-bottom: 2%; /* 子元素之间的间距 */
    overflow: hidden; /* 确保内容不会溢出 */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* 轻微的投影效果 */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* 过渡效果 */
    width: calc(33.333% - 2%); /* 假设三列布局，减去间距 */
  }
  
  .showBox_items:hover {
    transform: translateY(-0.5%); /* 鼠标悬停时上移 */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* 鼠标悬停时更深的投影 */
    transition: background-color 1s;
    background-color: #e3e3e3;
  }
  
  .showBox_items_t1 {
    margin: auto;
    text-align: center;
    padding: 1%;
  }
  
  .showBox_items_t1 img {
    width: 40%;
    height: auto;
    border-bottom: 1px solid #ddd; /* 图片底部边框 */
  }
  
  .showBox_items_t2,
  .showBox_items_t3 {
    margin: auto;
    text-align: center;
    padding: 1%;
  }
  
  .showBox_items_t2 {
    font-weight: bold; /* 标题加粗 */
  }
  
  .showBox_items_t3 {
    color: #666; /* 内容文本颜色 */
    font-size: 0.9rem; /* 内容字体大小 */
  }
  
  @media (max-width: 768px) {
    .showBox_items {
      width: calc(50% - 2%); /* 在小屏幕上改为两列布局 */
    }
  }
  
  @media (max-width: 480px) {
    .showBox_items {
      width: 100%; /* 在非常小的屏幕上改为单列布局 */
    }
  }
  </style>