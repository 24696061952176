<template>
    <div class="pageCreator">
        <div class="pageCreator_creator">
            <div class="indexTitle">自定义页管理</div>
            <div id="pageCreator_createBox">
                <h3>插入新的页签</h3>
                <div class="pageCreator_createBox_item">
                    <el-input v-model="newPage.name" placeholder="页面名"></el-input>
                </div>
                <div class="pageCreator_createBox_item">
                    优先级
                    <el-input-number v-model="newPage.priority" :min="1" :max="99999"></el-input-number>
                </div>
                <div class="pageCreator_createBox_item">
                    <el-switch v-model="newPage.hidden" active-color="#13ce66" inactive-color="#ff4949" active-text="隐藏"
                        inactive-text="显示">
                    </el-switch>
                </div>
                <div class="pageCreator_createBox_item">
                    <el-input v-model="newPage.notes" placeholder="备注"></el-input>
                </div>
                <div class="pageCreator_createBox_item">
                    <el-button type="danger" @click="add">插入</el-button>
                </div>
            </div>
        </div>





        <div class="pageCreator_manager">

            <!-- 页签管理 -->
            <h3>页签管理</h3>

            <div class="pageCreator_createBox_item">
                <el-button type="danger" @click="getPages()">刷新</el-button>
            </div>
            <el-table :data="myPages" style="width: 100%">
                <el-table-column prop="priority" label="优先级" width="250">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.priority" :min="1" :max="99999"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="标题" width="220">
                    <template slot-scope="scope">
                        <input style="width: 100%;" type="text" v-model="scope.row.name">
                    </template>
                </el-table-column>
                <el-table-column prop="hidden" label="是否隐藏" width="200">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.hidden" active-color="#13ce66" inactive-color="#ff4949"
                            active-text="隐藏" inactive-text="显示">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="description" label="页面描述" width="100">
                    <template slot-scope="scope">
                        <textarea style="resize: vertical;width: 100%;" v-model="scope.row.description"></textarea>
                    </template>
                </el-table-column>
                <el-table-column prop="keywords" label="页面关键词" width="100">
                    <template slot-scope="scope">
                        <textarea style="resize: vertical;width: 100%;" v-model="scope.row.keywords"></textarea>
                    </template>
                </el-table-column>
                <el-table-column prop="notes" label="备注（给管理员自己看的）" width="100">
                    <template slot-scope="scope">
                        <textarea style="resize: vertical;width: 100%;" v-model="scope.row.notes"></textarea>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click="change(scope.row)" type="text" size="small">确认修改</el-button>
                        <el-button @click="del(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>





    </div>
</template>

<script>
import Bus from '@/utils/EventBus';
import axios from 'axios';
export default {
    data() {
        return {
            myPages: [],
            newPage: {
                name: "",              //名称
                priority: 0,                   //优先级
                components_count: 0,           //组件数量
                hidden: false,                   //隐藏
                page_views: 0,                 //访问量
                notes: ""  ,             //备注
                description: "",               //描述
                keywords: "",               //关键词
            },
        }
    },
    mounted() {
        this.getPages()
    },
    methods: {
        del(page) {
            axios({
                method: "post",
                params: {
                    id: page.id
                },
                url: this.$hdAddress + '/m/deleteCustomPageById',
                headers:{
                    token:localStorage.getItem("token")
                },
            }).then(() => {
                this.$notify({
                    title: '成功',
                    message: '删除成功',
                    type: 'success'
                });
                //发布更新通知
                Bus.$emit('pageCreating', '')
                //管理页热更新
                this.getPages()
            })
                .catch(e => {
                    // 处理错误情况
                    console.log(e);
                    this.$notify.error({
                        title: '错误',
                        message: '删除失败'
                    });
                });
        },
        change(page) {
            axios({
                method: "post",
                data: page,
                url: this.$hdAddress + '/m/updateCustomPage',
                headers: {
                    token:localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                }
            }).then(() => {

                this.$notify({
                    title: '成功',
                    message: '更新成功',
                    type: 'success'
                });
                //发布更新通知
                Bus.$emit('pageCreating', '')
                //管理页热更新
                this.getPages()
            })
                .catch(e => {
                    // 处理错误情况
                    console.log(e);
                    this.$notify.error({
                        title: '错误',
                        message: '更新失败'
                    });
                });
        },
        add() {
            axios({
                method: 'post',
                url: this.$hdAddress + "/m/insertCustomPage",
                data: this.newPage,
                headers: {
                    token:localStorage.getItem("token"),
                    "Content-Type": "application/json",
                }
            })
                .then(() => {
                    // 处理成功的响应
                    this.$notify({
                        title: '成功',
                        message: '新增成功',
                        type: 'success'
                    });
                    //发布更新通知
                    Bus.$emit('pageCreating', '')
                    //刷新下方详情页
                    this.getPages()
                })
                .catch(e => {
                    // 处理错误情况
                    console.log(e);
                    this.$notify.error({
                        title: '错误',
                        message: '新增错误'
                    });
                });
        },

        getPages() {
            axios({
                method: 'get',
                url: this.$hdAddress + "/selectAllCustomPages",
                headers:{
                    token:localStorage.getItem("token")
                },
            })
                .then(response => {
                    this.myPages = response.data
                })
                .catch(error => {
                    console.error('Error:', error.response ? error.response.data : error);
                });
        },
    }
}
</script>

<style>

/* 页面创建者容器 */
.pageCreator {
    margin: auto;
    width: 80%;
    position: relative;
    padding: 20px;
}

/* 创建者和管理者区域 */
.pageCreator_creator, .pageCreator_manager {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* 标题样式 */
.pageCreator_creator h3 ,.pageCreator_manager h3{
    color: #ff4949;
    font-size: 1.5em; /* 相对大小 */
    margin-bottom: 0.5em;
}

/* 表单项样式 */
.pageCreator_createBox_item {
    margin-bottom: 1em; /* 相对间距 */
    position: relative;
}

/* 输入框和备注文本域样式 */
.pageCreator_createBox_item input,
.pageCreator_createBox_item textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* 边框计算在宽度内 */
}

/* 按钮样式 */
.pageCreator_createBox_item button {
    color: #fff;
    background-color: #ff4949;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 1em; /* 按钮与输入框的间距 */
}

/* 按钮悬浮效果 */
.pageCreator_createBox_item button:hover {
    background-color: #e03131;
}

/* 表格操作按钮样式 */
.pageCreator_createBox_item .el-button--text {
    padding: 5px 10px;
    color: #ff4949;
}

/* 表格操作按钮悬浮效果 */
.pageCreator_createBox_item .el-button--text:hover {
    background-color: #ff4949;
    color: #fff;
}


</style>