

<template>
  <div class="PEHeader" :style="{ height: headerHeight }">
    <div class="PEHeader_logo">
      <img src="../../public/images/logo.png" alt="Logo" />
    </div>

    <div class="PEHeader_logo_QQ" @click="logoClick('QQ')">
      <img src="../../public/images/logo_QQ.png" alt="Logo" />
    </div>
    <div class="PEHeader_logo_微信" @click="logoClick('微信')">
      <img src="../../public/images/logo_微信.png" alt="Logo" />
    </div>
    <div class="PEHeader_logo_电话" @click="logoClick('电话')">
      <img src="../../public/images/logo_电话.png" alt="Logo" />
    </div>

    <div class="PEHeader_header" :style="{ backgroundColor: headerColor }">
      <div class="PEHeader_header_changer">
        <div class="PEHeader_header_changer_btn" @click="headerChange()">
          <div
            class="PEHeader_header_changer_btn_item1 rotatable-line"
            :class="{ rotated1: PEHeader_show }"
          ></div>
          <div
            class="PEHeader_header_changer_btn_item2"
            :style="{ width: PEHeader_header_changer_btn_item2_style }"
          ></div>
          <div
            class="PEHeader_header_changer_btn_item3 rotatable-line"
            :class="{ rotated2: PEHeader_show }"
          ></div>
        </div>
      </div>
    </div>

    <el-dialog
      :title="this.PEHeader_logoImgTitle"
      :visible.sync="PEHeader_logoImgShow"
      width="80%"
    >
      <img
        style="width: 100%"
        :src="$hdAddress + this.PEHeader_logoImgUrl"
        alt=""
      />
    </el-dialog>

    <transition name="fade">
      <div
        class="PEHeader_body"
        v-if="msgS1.length != 0 && PEHeader_show"
        @click="headerChange()"
      >
        <div class="PEHeader_s1 enter-active" @click.stop>
          <div class="PEHeader_s1_item" v-for="item in msgS1" :key="item.id">
            <button
              @click="btnClicked(item)"
              class="PEHeader_body_btn enter-active"
            >
              {{ item.title }}
            </button>
          </div>
        </div>
        <div
          class="PEHeader_s2 enter-active"
          v-if="checkedS2Array.length != 0"
          @click.stop
        >
          <div>
            <div
              class="PEHeader_s2_item"
              v-for="item in checkedS2Array"
              :key="item.id"
            >
              <button
                @click="btnClicked(item)"
                class="PEHeader_body_btn enter-active"
              >
                {{ item.title }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Bus from "@/utils/EventBus";
import axios from "axios";
export default {
  data() {
    return {
      headerColor: "#363636",
      PEHeader_header_changer_btn_item2_style: "width: 0.75em",
      PEHeader_show: false,
      checkedS2Array: [],
      msgS1: [],
      msgS2: [],
      PEHeader_logoImgShow: false,
      PEHeader_logoImgTitle: "",
      PEHeader_logoImgUrl: "",
    };
  },
  computed: {
    headerHeight() {
      // 这个会导致淡出动画失效，但是不这么设置的话，用户的点击操作无法穿透蒙版
      return this.PEHeader_show ? "100%" : "3em";
    },
  },
  mounted() {
    this.headerInit();
  },
  methods: {
    logoClick(aim) {
      if (aim == "QQ") {
        this.PEHeader_logoImgShow = !this.PEHeader_logoImgShow;
        this.PEHeader_logoImgTitle = "QQ";
        axios({
          url: this.$hdAddress + "/getImg",
          method: "post",
          params: {
            img_type: "QQ",
          },
        }).then((res) => {
          this.PEHeader_logoImgUrl = res.data[0];
        });
      }
      if (aim == "微信") {
        this.PEHeader_logoImgShow = !this.PEHeader_logoImgShow;
        this.PEHeader_logoImgTitle = "微信";
        axios({
          url: this.$hdAddress + "/getImg",
          method: "post",
          params: {
            img_type: "微信",
          },
        }).then((res) => {
          this.PEHeader_logoImgUrl = res.data[0];
        });
      }
      if (aim == "电话") {
        window.location.href = `tel:18111049970`;
      }
    },
    headerChange() {
      //清空二级菜单
      this.checkedS2Array = [];
      this.PEHeader_show = !this.PEHeader_show;
      this.headerColor = this.PEHeader_show ? "#000000" : "#363636";
      //中间的横杠动画
      this.PEHeader_header_changer_btn_item2_style = this.PEHeader_show
        ? "0em"
        : "0.75em";
    },
    btnClicked(btnItem) {
      this.checkedS1 = btnItem;
      if (btnItem.child != null && btnItem.child.length > 0) {
        //如果有子页面，则填充临时数组并赋值给二级菜单项
        let temp = [];
        for (let index = 0; index < this.msgS2.length; index++) {
          if (this.msgS2[index].parent == btnItem.id)
            temp.push(this.msgS2[index]);
          this.checkedS2Array = temp;
        }
      } else {
        //如果没有则清空二级菜单
        this.checkedS2Array = [];
        //并且跳转
        this.jump(btnItem);
        //导航栏取消选中
        this.headerChange();
      }
    },
    //跳转，接收的值是一个选项对象
    jump(msg) {
      this.$router.push("/PE").catch(() => {});

      Bus.$emit("PEChecked", msg);
      //通过组件传值的方式大概率会出现内容丢失，那么设置一个localStorage变得非常有必要
      localStorage.setItem("pageMsg", JSON.stringify(msg));
    },
    headerInit() {
      this.getS2();
      this.getS1();
    },
    getS1() {
      axios({
        method: "get",
        url: this.$hdAddress + "/selectAllPEPageS1byHidden",
      }).then((res) => {
        this.msgS1 = res.data;
        //一切完毕以后，自动打开导航
        //这里修复了一个bug，当跳转到其他页面时，也会重新打开导航，所以得加一个拦截
        if (this.isPE)
          this.$nextTick(() => {
            this.headerChange();
          });
      });
    },
    getS2() {
      axios({
        method: "get",
        url: this.$hdAddress + "/selectAllPEPageS2",
      }).then((res) => {
        this.msgS2 = res.data;
      });
    },

    isPE() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      //如果是手机端访问，则看作是手机端
      if (flag) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX(-1em);
}

.PEHeader {
  /* transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); */
  overflow: hidden;
  z-index: 9999;
  margin: 0;
  padding: 0;
  position: fixed;
  width: 100%;
}

.PEHeader_logo {
  left: 1em;
  position: absolute;
  height: 2.7em;
}

.PEHeader_logo img {
  height: 100%;
}

.PEHeader_logo_QQ {
  right: 7em;
  position: absolute;
  height: 2.7em;
}

.PEHeader_logo_QQ img {
  height: 80%;
  margin-top: 0.5em;
}

.PEHeader_logo_微信 {
  right: 4em;
  position: absolute;
  height: 2.7em;
}

.PEHeader_logo_微信 img {
  height: 80%;
  margin-top: 0.5em;
}

.PEHeader_logo_电话 {
  right: 1em;
  position: absolute;
  height: 2.7em;
}

.PEHeader_logo_电话 img {
  height: 80%;
  margin-top: 0.5em;
}

.PEHeader_header {
  width: 100%;
  height: 3em;
  transition: all 0.5s;
}

.PEHeader_header_changer {
  width: 3em;
  height: 3em;
}

.PEHeader_header_changer_btn {
  position: relative;
  left: 4em;
  height: 3em;
  width: 3em;
  overflow: hidden;
}

.PEHeader_header_changer_btn_item1,
.PEHeader_header_changer_btn_item2,
.PEHeader_header_changer_btn_item3 {
  display: block;
  position: absolute;
  background-color: #fff;
  height: 0.1em;
  left: 0.75em;
  transition: all 0.5s;
  transform-origin: left;
}

.rotated1 {
  transform: rotate(45deg);
}

.rotated2 {
  transform: rotate(-45deg);
}

.PEHeader_header_changer_btn_item1 {
  width: 1.5em;
  top: 0.9em;
}

.PEHeader_header_changer_btn_item2 {
  width: 0.75em;
  top: 1.4em;
}

.PEHeader_header_changer_btn_item3 {
  width: 1.5em;
  top: 1.9em;
}

.PEHeader_body_btn {
  font-size: 1em;
  color: white;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  cursor: pointer;
  height: 3em;
  width: 100%;
}

.PEHeader_body {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  overflow: hidden;
  white-space: nowrap; /* 阻止换行 */
}

.PEHeader_s1,
.PEHeader_s2 {
  background-color: #363636e0;
  width: 40%;
  /* 避免出现白线 */
  margin-left: -1px;
  height: 100%;
  float: left;
}
.enter-active {
  animation: buttonFadeIn 0.5s ease-in-out;
}

@keyframes buttonFadeIn {
  from {
    transform: translateX(-1em);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.clear {
  clear: both;
}
</style>