<template>
  <div id="AboutUs_box3_inner">
      <img :src="this.FImgUrl1[0]" alt="">
  </div>
</template>

<script>
export default {
  
  
  data(){
      return{
          FImgUrl1: [],
          text1:[],
      }
  },
  mounted(){
    this.FImgUrl1 = this.$getImg("关于我们发展历程1")
    
  },
}
</script>

<style>

#AboutUs_box3_inner{
  width: 100%;
  height: 100%;
  padding-top: 0;
  padding-left: 15%;
  padding-right: 15%;
}
#AboutUs_box3_inner img{
  width: 70%;
  height: 100%;
}
</style>