<template>
    <div id="GameAgent">
      
      <TopImg :imgUrl="FImgUrl1[0]"></TopImg>
    

    <!-- 表单 -->
    <!-- <MForm type="jiaMeng"></MForm> -->


    <div class="indexTitle">游戏代理</div>
    <NormalText1 :text="text1[0].text"></NormalText1>
    <NormalImg1 :imgUrl="FImgUrl2[0]" :imgHeight="FImgUrl2_height"></NormalImg1>
    <NormalText1 :text="text2[0].text"></NormalText1>



      <!-- 服务内容 -->
    <ServiceContent></ServiceContent>
    <br>
    <br>
    <br>
      <!-- 合作流程 -->
    <OperationFlow></OperationFlow>

      
    <!-- 悬浮按钮 -->
    <div class="floating-button" @click="isModalVisible = !isModalVisible">
      <button>▶</button>
    </div>

    <!-- 悬浮浮窗 -->
    <div class="floating-modal" v-if="isModalVisible">
      <div class="modal-content">
        <!-- 表单组件 -->
        <MForm formType=0></MForm>
      </div>
    </div>


    </div>
  </template>
  
  <script>
  export default {
  data(){
      return{
        isModalVisible: true,
          FImgUrl1: [],
          text1:[],
          FImgUrl2: [],
          FImgUrl2_height:40,
          text2:[],
      }
  },
  mounted(){
    this.FImgUrl1 = this.$getImg("代理加盟大图")
    this.FImgUrl2 = this.$getImg("代理加盟游戏代理图片1")
    this.text1 = this.$getText("游戏代理文段1")
    this.text2 = this.$getText("游戏代理文段2")
    
    

    
  },
  methods:{
    change(){

    },
  }
  }
  </script>
  

  <style scoped>
  /* 使用相对单位确保 #GameAgent 有足够的高度来包含悬浮元素 */
  #GameAgent {
    min-height: 100vh; /* 视口高度的100% */
    position: relative;
  }
  
  /* 使用相对单位调整悬浮按钮样式 */
  .floating-button {
    position: fixed;
    bottom: 3rem; /* 相对于根元素字体大小的2倍 */
    right: 8rem;
    z-index: 1002;
  }
  
  .floating-button button {
    padding: 1em; /* 相对于当前元素的字体大小 */
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .floating-button button:hover {
    background-color: rgb(197, 1, 1);
  }
  
  /* 使用相对单位调整悬浮浮窗样式 */
  .floating-modal {
    position: fixed;
    bottom: 8vh; /* 视口高度的8% */
    right: 2vw; /* 视口宽度的2% */
    max-width: 30%; /* 视口宽度的30% */
    border: 1px solid #ddd;
    background: white;
    padding: 1em; /* 相对于当前元素的字体大小 */
    border-radius: 1em;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    display: flex;
    flex-direction: column;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  
  </style>